import styles from "./clearFieldButton.module.scss";
import { useField } from "formik";
import Tooltip from "shared/ui/Tooltip";
import { ClearButton } from "./ClearButton";
import { Placement } from "@floating-ui/react-dom";
import { classNames } from "shared/utils/helpers/classNames";

type ClearFieldButtonProps = {
  /**
   * Имя поля (для сброса значения)
   */
  name: string;
  /**
   * Является ли поле не активным
   */
  disabled: boolean;
  /**
   * Есть ли фокус на поле для отображения. Работает в связке со значением поля.
   * Если значения поля нет, то кнопка будет отображаться только если  на поле есть фокус.
   */
  isFocused: boolean;
  /**
   * Функция, отрабатывающая при клике на кнопку
   */
  onClick: () => void;
  /**
   * Стили, заданные в родителе
   */
  className?: string;
  /**
   * С какой стороны будет тултип на кнопке
   */
  clearBtnPlacement?: Placement;
};

export const ClearFieldButton = ({
  name,
  disabled,
  isFocused,
  onClick,
  className,
  clearBtnPlacement
}: ClearFieldButtonProps) => {
  const [field] = useField(name);

  if (disabled || (!isFocused && !field.value?.length)) return;

  return field.value?.length ? (
    <div className={classNames(styles.container, {}, [className])}>
      <Tooltip
        text="Очистить поле"
        color="accent-coral"
        borderColor="accent-coral"
        placement={clearBtnPlacement ? clearBtnPlacement : "right"}
        withoutFlip
        offsetCustom={{
          mainAxis: 50,
          crossAxis: 50
        }}
      >
        <ClearButton name={name} className={className} onClick={onClick} />
      </Tooltip>
    </div>
  ) : (
    <ClearButton name={name} className={className} onClick={onClick} />
  );
};
