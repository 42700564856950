import styles from "./staffOneEditFormCompanies.module.scss";
import { Field, useFormikContext } from "formik";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import Checkboxes from "shared/ui/Inputs/Checkboxes";

import { isEmpty, isEqual } from "lodash";
import { ICompany } from "stores/StaffModule/types/RecordsForEdit";
import { useParams } from "react-router-dom";
import { getKeys } from "shared/utils/helpers/getKeys";
import TruddogTabs from "./TruddogTabs";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import { classNames } from "shared/utils/helpers/classNames";
import { getEntries } from "shared/utils/helpers/getEntries";

type StaffOneEditFormCompaniesProps = {
  values: { companies: Record<string, ICompany>; company: string[] };
  openedListName: string | null;
  changeOpenedWindows: (arg: string | null) => void;
  disabled: boolean;
  required: boolean;
};

const StaffOneEditFormCompanies = ({
  values,
  openedListName,
  changeOpenedWindows,
  disabled,
  required
}: StaffOneEditFormCompaniesProps) => {
  const { staffOneEditStore } = useStores();
  const { setFieldValue } = useFormikContext();
  const { id } = useParams();

  const companiesTabs = getKeys(values["companies"]).map((company_id) => {
    return {
      id: company_id,
      title: staffOneEditStore.allCompanies[company_id]
    };
  });

  const selectedCompany = staffOneEditStore.selectedCompanyId[id];

  useEffect(() => {
    if (!values.company.includes(selectedCompany)) {
      staffOneEditStore.setSelectedCompanyId(
        id,
        getKeys(staffOneEditStore.selectedOneForEdit["company"])[0]
      );
    }
  }, [values.company.length]);

  useEffect(() => {
    if (
      values.company.length &&
      !isEqual(values.company, getKeys(values.companies))
    ) {
      const currentCompanies: Record<string, ICompany> = {};
      let selectedId = "";

      values.company.forEach((key) => {
        let match = false;

        const provSelectedCompanyId = () => {
          if (getKeys(currentCompanies)[0]) {
            selectedId = selectedCompany ?? getKeys(currentCompanies)[0];
          } else selectedId = key;
        };

        if (!isEmpty(values.companies)) {
          for (const item of getEntries(values.companies)) {
            if (key === item[0]) {
              currentCompanies[key] = item[1];
              match = false;
              break;
            } else {
              match = true;
            }
          }
        } else {
          match = true;
        }
        provSelectedCompanyId();

        if (match) {
          selectedId = key;
          staffOneEditStore.setNewCompanyData(
            selectedId,
            currentCompanies[selectedId]
          );
        }
      });

      setFieldValue("companies", currentCompanies);

      if (selectedId.length) {
        if (
          values.company.length <
          getKeys(staffOneEditStore.selectedOneForEdit["company"]).length
        ) {
          staffOneEditStore.setCurrentCompanyList(id, values["company"]);
        }
        staffOneEditStore.setSelectedCompanyId(id, selectedId);

        if (currentCompanies[selectedId]?.["employments"]) {
          staffOneEditStore.setSelectedTypeOfJob(
            id,
            Object.keys(currentCompanies[selectedId].employments)[0]
          );
        }
      }
    }
  }, [values.company]);

  return (
    <>
      <Field
        className={classNames("", { [styles.disabled]: disabled })}
        name="company"
        values={values.company}
        as={Checkboxes}
        label={staffOneEditStore.staffTableCols.company?.title}
        options={staffOneEditStore.allCompanies}
        isMenuOpened={openedListName === "companies"}
        setIsMenuOpened={() => {
          changeOpenedWindows("companies");
        }}
        disabled={disabled}
        required={required}
      />
      <ButtonsTabs
        tabs={companiesTabs}
        selectedTab={selectedCompany}
        changeActiveTab={(company_id) =>
          staffOneEditStore.setSelectedCompanyId(id, company_id, true)
        }
        className={{ container: styles.tabsContainer }}
      />

      <TruddogTabs values={values} />
    </>
  );
};

export default observer(StaffOneEditFormCompanies);
