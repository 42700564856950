// фукция получения верхнего отступа для thead таблицы
export const getTHeadTop = (
  savedFiltersHeight: number,
  hasSearchParams: boolean,
  isFirefox: boolean
): number => {
  // отступ thead равен сумме
  return (
    // высоты блока сохранённых фильтров (или 0)
    (savedFiltersHeight > 0 && savedFiltersHeight <= 40
      ? savedFiltersHeight + 12
      : savedFiltersHeight || 0) +
    // высоты FilterHeader
    59 +
    // высоты блока параметров поиска (или 0, если никакие настройки не открыты)
    (hasSearchParams ? 40 : 0) +
    // и +7px для Firefox из-за особенностей браузера
    (isFirefox ? 7 : 0)
  );
};
