import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Col } from "stores/utils/types/Col";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Salary } from "../types/Salary";
import { SelectModule } from "../types/Selects";
import { WithholdingEdu } from "../types/WithholdingEdu";
import dataJSON from "./staffOneSalary.json";
import { Company } from "../types/Company";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";
import { OperationType } from "stores/SalaryModule/salaryOperations/types/SalaryOperationType";
import { PremiumStatus } from "stores/SalaryModule/salaryPremium/types/SalaryStatusPremium";
import { getEntries } from "shared/utils/helpers/getEntries";

type BuildingType = {
  building_id: string;
  building_title: string;
  title: string;
  id: string;
  company_id: string;
  company_title: string;
};

type PaymentListItem = {
  uid: number;
  date_start: string;
  date_end: string;
  status: string;
  type: string;
  id: string;
  money: number;
  object: {
    id: string;
    title: string;
    company_id: string;
    company_title: string;
  };
};

type PaymentListItemForTable = {
  id: string;
  object: {
    id: string;
    title: string;
    company_id: string;
    company_title: string;
  };
  type: string;
  paymentlist: {
    uid: number;
    date_start: string;
    date_end: string;
    status: string;
    id: string;
    money: number;
  }[];
};

type PremiumType = {
  last_update: string;
  calculation_period: string;
  status: string;
  id: string;
  object: {
    id: string;
    title: string;
    company_id: string;
    company_title: string;
  };
  staff: Record<
    string,
    {
      sum_premium: number;
      fixed: number;
    }
  >;
};

type LastOperationType = {
  id: string;
  type: string;
  author: {
    id: string;
    title: string;
  };
  money: number;
  time_spending: string;
  object: {
    id: string;
    title: string;
  };
  paymentlist: string;
};

type Selects = { title: string; id: string };

type FinanceMetrics = {
  metrics: Record<
    string,
    {
      title: string;
      value: number | string;
      money: boolean;
      table_title: string;
    }
  >;
  color: string;
};
export default class StaffOneSalaryStore {
  error: Record<string, boolean> = {};
  isLoading: Record<string, boolean> = {};

  isLoadingHoldingEdu: Record<string, boolean> = {};
  isLoadingMetrics: Record<string, boolean> = {};
  errorsMessage: Record<string, Errors["message"]> = {};
  successMessage: Record<string, Errors["message"]> = {};
  onPage = 10;

  // список блоков с их состоянием (раскрыт/скрыт)
  conditionBlockList: Record<string, Record<string, boolean>> = {};

  // список метрик по финансам
  metricsList: Record<string, Record<string, FinanceMetrics>> = {};
  salary: Partial<Salary["table"]> = {};
  staffRetention: Record<string, Partial<WithholdingEdu>> = {};

  // поля для таблицы ведомостей
  paymentList: Record<string, Record<string, PaymentListItemForTable>> = {};
  currentTitlesForPaymentList: string[] = [];
  colsForPaymentList: Record<string, Col> = {};
  paymentListTypes: SelectModule = {};
  paymentListStatuses: SelectModule = {};
  selectedCompanyForPaymentList: Record<string, string> = {};
  selectedTypeForPaymentList: Record<string, string> = {};
  isLoadingForPaymentList: Record<string, boolean> = {};

  // поля для таблицы премий
  premium: Record<string, Record<string, PremiumType>> = {};
  selectedCompanyForPremium: Record<string, string> = {};
  selectedStatusForPremium: Record<string, string> = {};
  currentTitlesForPremium: string[] = [];
  colsForPremium: Record<string, Col> = {};
  isLoadingForPremium: Record<string, boolean> = {};

  // поля для таблицы операций
  lastOperationList: Record<string, LastOperationType[]> = {};
  lastOperationCols: Record<string, Col> = {};
  selectedTypeFromOperations: Record<string, string> = {};
  isLoadingForOperations: Record<string, boolean> = {};
  currentTitlesForOperations: string[] = [];

  // список таблиц, которые могут сворачиваться
  // при разработке добавлять в этом массив в том порядке, что и на макете
  tableList = ["withholding_edu", "paymentlist", "premium", "operations"];
  fields: {
    retentionPPE?: Record<string, Record<string, string | number>>;
    lastOperations?: Record<
      string,
      Record<string, string | number | Record<string, string>>
    >;
  } = {};

  isOpenModal = false;
  scrollToEdu = false;
  isOpenedAddOperationModal = false;

  // справочник типов операций
  operationTypesSelects: Record<string, OperationType> = {};
  // справочник компаний для окрашивания плашек с названием
  companiesDict: Record<string, Company> = {};

  // справочник статусов для премий
  premiumStatusSelects: Record<string, PremiumStatus> = {};

  // доступные операции для сотрудника
  availableOperationTypes: {
    [key: string]: { [key: string]: Selects };
  } = {};
  // список для поля Причина
  operationProperty: Record<string, Record<string, Selects>> = {};

  //список доступных объектов для сотрудников на опрееделенную дату
  buildingForStaff: Record<string, BuildingType> = {};

  // сумма для добавления вычета УЦ, приходит в методе salary/getWEduSettings
  money = 0;
  // количество смен для добавления вычета УЦ, приходит в методе salary/getWEduSettings
  ws_num = 0;

  nameOneOfStaff: Record<string, string> = {};

  getData = (uid: string) => {
    // скрываем все блоки по умолчанию
    if (!(uid in this.conditionBlockList)) {
      this.tableList.forEach((block) => {
        this.setConditionBlockList(uid, block, false);
      });
    }

    this.isLoading[uid] = true;
    Promise.all([
      isEmpty(this.operationTypesSelects) && this.getSelects(),
      isEmpty(this.companiesDict) && this.getCompaniesList(),
      isEmpty(this.paymentListStatuses) && this.getPaymentListStatuses(),
      isEmpty(this.paymentListTypes) && this.getPaymentListTypes(),
      this.rootStore.staffOneStore.setSelectedOneForInfo(uid),
      this.setData(),
      this.getSettings()
    ]).then(() => runInAction(() => (this.isLoading[uid] = false)));
  };

  setData = () => {
    runInAction(() => {
      this.salary = dataJSON["table"];
      this.fields["lastOperations"] = dataJSON["lastOperations"];
    });
  };

  setIsOpenModal = (val: boolean) => (this.isOpenModal = val);

  setNameOneOfStaff = (id: string, val: string) => {
    if (!isEmpty(this.nameOneOfStaff)) {
      this.nameOneOfStaff[id] = val;
    } else {
      this.nameOneOfStaff = {};
      this.nameOneOfStaff[id] = val;
    }
  };

  getFinanceMetrics = async (uid: string) => {
    this.isLoadingMetrics[uid] = true;

    try {
      const data: ApiResponse<{ [key: string]: FinanceMetrics }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getFinanceMetrics",
          params: { uid }
        });

      runInAction(
        () => (this.metricsList[uid] = data.code === 200 ? data.result : {})
      );
    } catch (error) {
      runInAction(() => (this.metricsList[uid] = {}));
    } finally {
      runInAction(() => (this.isLoadingMetrics[uid] = false));
    }
  };

  getWithholdingEdu = async (uid: string) => {
    this.isLoadingHoldingEdu[uid] = true;

    try {
      const data: {
        code: number;
        hash: string;
        ok: boolean;
        without_edu: boolean;
        widget: WithholdingEdu["widget"];
        statusList: WithholdingEdu["statusList"];
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWithholdingEduWidget",
        params: { uid }
      });

      runInAction(
        () =>
          (this.staffRetention[uid] = data.ok
            ? data
            : { without_edu: data.without_edu })
      );
    } catch (error) {
      runInAction(() => (this.error[uid] = true));
    } finally {
      runInAction(() => (this.isLoadingHoldingEdu[uid] = false));
    }
  };

  getPaymentList = async (uid: string) => {
    this.isLoadingForPaymentList[uid] = true;
    this.paymentList[uid] = {};
    try {
      const data: ApiResponse<{ [key: string]: PaymentListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getPaymentList",
          on_page: this.onPage,
          params: { uid }
        });

      runInAction(() => {
        if (!isEmpty(data.result)) {
          this.setSelectedTypeForPaymentList(uid, "all");
          this.setSelectedCompanyForPaymentList(uid, "all");

          // получаем массив уникальных значений в виде "объект_тип ведомости"
          const objectAndTypeArray = Array.from(
            new Set(
              getValues(data["result"]).map(
                (payment) => `${payment["object"]["id"]}_${payment["type"]}`
              )
            )
          );

          objectAndTypeArray.forEach((key) => {
            // выделяем отдельно объект и тип из строки вида "объект_тип ведомости"
            const [object, type] = key.split("_");
            // фильтруем массив значений data["records"], по объекту и по типу
            const filteredArray: PaymentListItem[] = getValues(
              data["result"]
            ).filter(
              (payment: PaymentListItem) =>
                payment["object"]["id"] === object && payment["type"] === type
            );
            // записываем в удобный для таблицы вид
            this.paymentList[uid][key] = {
              id: filteredArray[0]["id"],
              object: filteredArray[0]["object"],
              type: filteredArray[0]["type"],
              paymentlist: filteredArray
            };
          });
        } else {
          this.paymentList[uid] = {};
        }
        if (!isEmpty(data.show_fields)) {
          this.currentTitlesForPaymentList = [];
          getValues(data.show_fields).forEach((show_field) => {
            if (show_field === "object") return;
            if (show_field === "type")
              this.currentTitlesForPaymentList.push("object");
            this.currentTitlesForPaymentList.push(show_field);
          });
        } else {
          this.currentTitlesForPaymentList = [];
        }
        this.colsForPaymentList = !isEmpty(data.cols) ? data.cols : {};
      });
    } catch (error) {
      runInAction(() => {
        this.paymentList[uid] = {};
        this.currentTitlesForPaymentList = [];
        this.colsForPaymentList = {};
      });
    } finally {
      runInAction(() => (this.isLoadingForPaymentList[uid] = false));
    }
  };

  getPremium = async (uid: string) => {
    this.isLoadingForPremium[uid] = true;

    try {
      const data: ApiResponse<{ [key: string]: PremiumType }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getPremium",
          on_page: this.onPage,
          params: { uid }
        });

      runInAction(() => {
        if (!isEmpty(data.result)) {
          this.setSelectedStatusForPremium(uid, "all");
          this.setSelectedCompanyForPremium(uid, "all");
          this.premium[uid] = data.result;
        } else {
          this.premium[uid] = {};
        }
        this.currentTitlesForPremium = !isEmpty(data.show_fields)
          ? getValues(data.show_fields)
          : [];
        this.colsForPremium = !isEmpty(data.cols) ? data.cols : {};
      });
    } catch (error) {
      runInAction(() => {
        this.premium[uid] = {};
        this.currentTitlesForPremium = [];
        this.colsForPremium = {};
      });
    } finally {
      runInAction(() => (this.isLoadingForPremium[uid] = false));
    }
  };

  getLastOperations = async (uid: string) => {
    this.isLoadingForOperations[uid] = true;
    this.error[uid] = false;

    try {
      const data: ApiResponse<
        Record<string, { salary_list: Record<string, LastOperationType> }>
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getSalary",
        on_page: this.onPage,
        params: { uid }
      });

      runInAction(() => {
        if (!isEmpty(data.result)) {
          this.setSelectedTypeFromOperations(uid, "all");
          this.lastOperationList[uid] = getValues(data.result[uid].salary_list);
        } else {
          this.lastOperationList[uid] = [];
        }
        this.lastOperationCols = !isEmpty(data.cols) ? data.cols : {};
        this.currentTitlesForOperations = !isEmpty(data.show_fields)
          ? getValues(data.show_fields)
          : [];
      });
    } catch (error) {
      runInAction(() => {
        this.lastOperationList[uid] = [];
        this.lastOperationCols = {};
        this.currentTitlesForOperations = [];
      });
    } finally {
      runInAction(() => (this.isLoadingForOperations[uid] = false));
    }
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<{
        operation_type: Record<string, OperationType>;
        status_premium: Record<string, PremiumStatus>;
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getSelects"
      });

      runInAction(() => {
        if (!isEmpty(data.result)) {
          this.operationTypesSelects = data.result.operation_type;
          this.premiumStatusSelects = data.result.status_premium;
          getValues(this.operationTypesSelects)
            .filter((operation) => !isEmpty(operation.custom?.property))
            .forEach((operation) => {
              this.operationProperty[operation.id] = {};
              getEntries(operation.custom.property).forEach(
                ([key, property]) => {
                  this.operationProperty[operation.id][key] = {
                    title: property,
                    id: property
                  };
                }
              );
            });
        } else {
          this.operationTypesSelects = {};
        }
      });
    } catch (error) {
      runInAction(() => (this.error[this.rootStore.menuStore.tabId] = true));
    }
  };

  getPaymentListStatuses = async () => {
    try {
      const data: ApiResponse<SelectModule> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "paymentlist_status",
          method: "getList"
        });

      runInAction(() => {
        this.paymentListStatuses = !isEmpty(data["result"]) ? data.result : {};
      });
    } catch (error) {
      runInAction(() => (this.error[this.rootStore.menuStore.tabId] = true));
    }
  };

  getPaymentListTypes = async () => {
    try {
      const data: ApiResponse<SelectModule> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "paymentlist_types",
          method: "getList"
        });

      runInAction(
        () => (this.paymentListTypes = !isEmpty(data.result) ? data.result : {})
      );
    } catch (error) {
      runInAction(() => (this.error[this.rootStore.menuStore.tabId] = true));
    }
  };

  getOperationTypesForUid = async (uid: string) => {
    try {
      const data: ApiResponse<{ [key: string]: string }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getOperationTypesForUid",
          params: { uid }
        });

      runInAction(() => {
        if (!isEmpty(data.result)) {
          this.availableOperationTypes[uid] = {};
          getValues(data.result).forEach((operationID) => {
            this.availableOperationTypes[uid][operationID] = {
              title: this.operationTypesSelects[operationID].title,
              id: operationID
            };
          });
        } else this.availableOperationTypes[uid] = {};
      });
    } catch (error) {
      runInAction(() => (this.error[uid] = true));
    }
  };

  getBuildingForStaff = async (uid: string, date: string) => {
    try {
      const data: ApiResponse<Record<string, BuildingType> | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          method: "getStaffBuildings",
          params: { uid, date }
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.buildingForStaff = {};
          getEntries(data.result).forEach(([id, building]) => {
            const tempObjectBuilding = {} as BuildingType;
            getEntries(building).forEach(([key, value]) => {
              switch (key) {
                case "building_title":
                  tempObjectBuilding.title = value;
                  break;
                case "building_id":
                  tempObjectBuilding.id = value;
                  break;
                default:
                  tempObjectBuilding[key] = value;
              }
            });
            this.buildingForStaff[id] = tempObjectBuilding;
          });
        } else this.buildingForStaff = {};
      });
    } catch (error) {
      runInAction(() => (this.error[uid] = true));
    }
  };

  addOperation = async (
    values: Record<string, string | number>,
    uid: string
  ) => {
    const formData: Record<string, string | number | string[]> = {};

    getEntries(values).forEach(([key, value]) => {
      switch (key) {
        case "initiator":
          value ? (formData[key] = value) : delete values[key];
          break;
        case "money":
          formData[key] = +(value as string).replace(" ", "").replace(",", ".");
          break;
        default:
          formData[key] = value ?? "";
      }
    });

    try {
      const data: { ok: boolean; message: Errors["message"] } =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "salary",
          method: "addOperation",
          body: { ...formData, uid }
        });

      runInAction(() => {
        if (data["ok"]) {
          this.getLastOperations(uid);
          this.rootStore.staffOneStore.setRebootStaff(uid, true);
          this.errorsMessage[uid]
            ? this.clearErrorsMessage(uid)
            : (this.errorsMessage[uid] = {} as Errors["message"]);
          this.successMessage[uid] = data.message;
        } else {
          this.isLoadingForOperations[uid] = false;
          this.errorsMessage[uid] = data.message;
        }
      });
    } catch (error) {
      runInAction(() => (this.isLoadingForOperations[uid] = false));
    }
  };

  addHoldingEdu = async (
    values: Record<string, string | number>,
    uid: string
  ) => {
    const formData: Record<string, string | number | string[]> = {};

    getEntries(values).forEach(([key, value]) => {
      switch (value) {
        case null:
          formData[key] = "";
          break;
        default:
          formData[key] = value;
      }
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "addWEduRecord",
        body: { ...formData, uid }
      });

      runInAction(() => {
        if (data.result) {
          this.getWithholdingEdu(uid);
          this.rootStore.staffOneStore.setRebootStaff(uid, true);
          this.errorsMessage[uid]
            ? this.clearErrorsMessage(uid)
            : (this.errorsMessage[uid] = {} as Errors["message"]);
        } else {
          this.isLoadingHoldingEdu[uid] = false;
          this.errorsMessage[uid] = data.message;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[uid] = true;
        this.isLoadingHoldingEdu[uid] = false;
      });
    }
  };

  setWithoutEdu = async (uid: string, act: "set" | "unset") => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "setWithoutEdu",
        body: { uid, act }
      });

      if (data.result) {
        this.getWithholdingEdu(uid);
        this.rootStore.staffOneStore.setRebootStaff(uid, true);
      } else {
        runInAction(() => (this.isLoadingHoldingEdu[uid] = false));
      }
    } catch (error) {
      runInAction(() => {
        this.error[uid] = true;
        this.isLoadingHoldingEdu[uid] = false;
      });
    }
  };

  getSettings = async () => {
    try {
      const data: ApiResponse<boolean> & {
        default: {
          money: number;
          ws_num: number;
        };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWEduSettings"
      });

      runInAction(() => {
        if (data.result) {
          this.money = data.default.money;
          this.ws_num = data.default.ws_num;
        } else {
          this.error[this.rootStore.menuStore.tabId] = true;
        }
      });
    } catch (error) {
      runInAction(() => (this.error[this.rootStore.menuStore.tabId] = true));
    }
  };

  clearErrorsMessage = (id: string) => {
    delete this.errorsMessage[id];
    delete this.successMessage[id];
  };

  setScrollToEdu = (value: boolean) => (this.scrollToEdu = value);

  getCompaniesList = async () => {
    try {
      const data: ApiResponse<Record<string, Company> | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "company",
          currentClass: "company",
          method: "getList",
          select: ["id", "title", "color"]
        });

      runInAction(() => {
        if (data.result !== -1) {
          getValues(data.result).forEach(
            (company) => (this.companiesDict[company.id] = company)
          );
        } else {
          this.companiesDict = {};
        }
      });
    } catch (error) {
      runInAction(() => (this.error[this.rootStore.menuStore.tabId] = true));
    }
  };

  setSelectedTypeFromOperations = (uid: string, type: string) => {
    this.selectedTypeFromOperations[uid] = type;
  };

  setSelectedStatusForPremium = (uid: string, status: string) => {
    this.selectedStatusForPremium[uid] = status;
  };

  setSelectedCompanyForPaymentList = (uid: string, company: string) => {
    this.selectedCompanyForPaymentList[uid] = company;
  };

  setSelectedTypeForPaymentList = (uid: string, type: string) => {
    this.selectedTypeForPaymentList[uid] = type;
  };

  setSelectedCompanyForPremium = (uid: string, company: string) => {
    this.selectedCompanyForPremium[uid] = company;
  };

  setConditionBlockList = (uid: string, block: string, value: boolean) => {
    if (!(uid in this.conditionBlockList)) {
      this.conditionBlockList[uid] = {};
    }
    this.conditionBlockList[uid][block] = value;
  };

  setIsOpenedAddOperationModal = (value: boolean) => {
    this.isOpenedAddOperationModal = value;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this, {});
  }
}
