import styles from "./staffOneEditFormObjectVersionWindow.module.scss";
import Select from "shared/ui/Inputs/Select";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { useStores } from "stores/index";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import NumberFormat from "react-number-format";
import { Input } from "shared/ui/Inputs/Input";
import validation from "./validation";
import FormWindow from "shared/ui/FormWindow";
import { useState } from "react";
import icon1C from "shared/assets/images/mainIcons/icon1C.svg";
import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import { useParams } from "react-router-dom";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";
import { classNames } from "shared/utils/helpers/classNames";

type StaffOneEditFormObjectVersionWindowProps = {
  setOpenWindow: () => void;
  field: string;
  title: string;
};

const StaffOneEditFormObjectVersionWindow = ({
  field,
  setOpenWindow,
  title
}: StaffOneEditFormObjectVersionWindowProps) => {
  const { staffOneEditStore, userStore } = useStores();
  const { id } = useParams();
  const [openedListName, setOpenedListName] = useState("");

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName(null) : setOpenedListName(name);
  };

  return (
    <div>
      <Formik
        initialValues={{
          start_date: null,
          start_time: "12:00",
          value: "",
          history: getValues(
            staffOneEditStore.selectedOneForEdit["company"]?.[
              staffOneEditStore.selectedCompanyId[id]
            ]?.["employments"]?.[staffOneEditStore.selectedTypeOfJob[id]]?.[
              `${field}_history`
            ]
          ).sort((a, b) => {
            if (
              new Date(
                `${b["start_date"]} ${b["start_time"] || ""}`
              ).valueOf() !==
              new Date(`${a["start_date"]} ${a["start_time"] || ""}`).valueOf()
            ) {
              return (
                new Date(
                  `${b["start_date"]} ${b["start_time"] || ""}`
                ).valueOf() -
                new Date(
                  `${a["start_date"]} ${a["start_time"] || ""}`
                ).valueOf()
              );
            }
            return (
              new Date(b["time_create"]).valueOf() -
              new Date(a["time_create"]).valueOf()
            );
          })
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={() => null}
      >
        {({
          dirty,
          values,
          initialValues,
          isValid,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          const windowTitle = `${title} (${
            staffOneEditStore.allCompanies[
              staffOneEditStore.selectedCompanyId[id]
            ]
          }) ${
            staffOneEditStore.selectedTypeOfJob[id]
              ? `(${staffOneEditStore.selectedTypeOfJob[id]})`
              : ""
          }`;
          const handleSaveBtnOnClick = async () => {
            if (values["value"] && values["start_date"]) {
              await staffOneEditStore.addOVData(
                id,
                field,
                values.history
                  ? values.history[values.history?.length - 1]?.[
                      "staff_guid"
                    ] || ""
                  : "",
                values["start_date"],
                values["value"],
                field === "position" ? values["start_time"] : null
              );
            }
            values["history"]?.forEach((item) => {
              const initialItem = initialValues["history"].filter(
                (value) => value.id === item.id
              )?.[0];
              if (initialItem && initialItem.value !== item.value) {
                staffOneEditStore.updateOVData(
                  id,
                  item["id"],
                  item["value"] as string
                );
              }
            });
            initialValues["history"]?.forEach((item) => {
              if (
                !values["history"]
                  .map((element) => element["id"])
                  .includes(item["id"])
              ) {
                staffOneEditStore.deleteOVData(id, item["id"]);
              }
            });

            setOpenWindow();
          };
          const handleDeleteItem = (itemId: string) => {
            setFieldValue(
              "history",
              values["history"].filter((item) => item.id !== itemId)
            );
          };
          const getInputs = (
            name: string,
            value: string | number,
            disabled?: boolean
          ) => {
            const options = (
              field === "position"
                ? staffOneEditStore.staffTableParams[field]["variable"][
                    staffOneEditStore.selectedCompanyId[id]
                  ]
                : staffOneEditStore.staffTableParams[field]["variable"]
            ) as {
              [key: string]: { newname: string; title: string };
            };

            if (
              !isEmpty(staffOneEditStore.staffTableParams[field]?.["variable"])
            ) {
              return (
                <Select
                  name={name}
                  title={title}
                  isFloating
                  options={options}
                  valueName={field === "position" ? "id" : "newname"}
                  disabled={disabled}
                />
              );
            } else if (
              staffOneEditStore.staffTableCols[field]["type"] === "float"
            ) {
              return (
                <NumberFormat
                  name={name}
                  value={value}
                  label="Значение"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  customInput={Input}
                  disabled={disabled}
                />
              );
            } else {
              return (
                <Input
                  name={name}
                  label="Значение"
                  onChange={handleChange}
                  disabled={disabled}
                />
              );
            }
          };

          return (
            <FormWindow
              title={windowTitle}
              setOpenWindow={() => setOpenWindow()}
              saveBtnTitle="Сохранить"
              saveBtnOnClickFunc={handleSaveBtnOnClick}
              saveBtnDisabledValue={!dirty || !isValid}
              addBtnTitle="Отмена"
              isScroll
              fromOverlayWindow
            >
              <Form>
                <div className={styles.container}>
                  {getInputs("value", values.value)}
                  <DatePickerField
                    name="start_date"
                    title="Дата начала"
                    isCalendarOpened={openedListName === "start_date"}
                    setIsCalendarOpened={() =>
                      changeOpenedWindows("start_date")
                    }
                  />
                  {field === "position" ? (
                    <NumberFormat
                      format="##:##"
                      name="start_time"
                      value={values["start_time"]}
                      label="Время начала"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      customInput={Input}
                    />
                  ) : null}

                  <div className={styles.title}>История</div>
                  {values.history?.length ? (
                    <>
                      {getValues(values.history).map((item, i) => {
                        const isDisabled =
                          !staffOneEditStore.allData.is_tech_access &&
                          userStore.user_id !== item.user?.toString();
                        return (
                          <div key={item.id}>
                            <div className={styles.item}>
                              <div className={styles.dateTitle}>
                                Дата и время начала:
                              </div>
                              <div className={styles.date}>
                                {getFormattedDate(item.start_date)}
                              </div>
                              <div className={styles.date}>
                                {item.start_time}
                              </div>
                            </div>
                            <div className={styles.itemWithMargin}>
                              <div className={styles.dateTitle}>Создано:</div>
                              <div className={styles.date}>
                                {getFormattedDate(item.time_create)}
                              </div>
                              <div className={styles.name}>
                                {item.user_name === "crm_bot" ? (
                                  <img src={icon1C} alt="icon" />
                                ) : (
                                  item.user_name
                                )}
                              </div>
                            </div>
                            <div className={styles.inputsContainer}>
                              <div
                                className={classNames(styles.input, {
                                  [styles.inputMini]: !isDisabled
                                })}
                              >
                                {getInputs(
                                  `history.${i}.value`,
                                  item.value,
                                  isDisabled
                                )}
                              </div>
                              {!isDisabled ? (
                                <Button
                                  type="button"
                                  className={styles.basketBtn}
                                  theme={ButtonTheme.SECONDARY}
                                  size={ButtonSize.S}
                                  id={`StaffOneEditFormObjectVersionWindow_deleteButton_${item.id}`}
                                  onClick={() => handleDeleteItem(item.id)}
                                >
                                  <IconBasket className={styles.basketBtnImg} />
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className={styles.noHistory}>Нет данных</div>
                  )}
                </div>
              </Form>
            </FormWindow>
          );
        }}
      </Formik>
    </div>
  );
};

export default StaffOneEditFormObjectVersionWindow;
