import styles from "./salaryOperationsOneHistory.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import PageTitle from "shared/ui/PageTitle";
import { Table } from "react-bootstrap";

const SalaryOperationsOne = () => {
  const { id } = useParams();
  const { menuStore, salaryOperationOneStore } = useStores();

  useEffect(() => {
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary`) {
        menuStore.deleteWindow(key);
      }
    });

    salaryOperationOneStore.setSelectedOneOfSalaryOperation(id);

    menuStore.updateWindow({
      mainPath: `/salary/id=${id}`,
      path: `/salary/id=${id}/history`
    });

    menuStore.sendTabId(id);
    menuStore.setOpenedModule("salaryOne");
    menuStore.setOpenedSubmodule("salaryOneHistory");
  }, [id]);

  return (
    <LoadedComponent isLoading={salaryOperationOneStore.isLoading}>
      <>
        <PageTitle title="История" leftCol />

        {Object.values(salaryOperationOneStore.salaryOperationsHistory)
          .length &&
        Object.values(salaryOperationOneStore.salaryOperationsOneCols)
          .length ? (
          <Table className={styles.table}>
            <thead>
              <tr>
                {salaryOperationOneStore.historyTitles.length &&
                Object.values(salaryOperationOneStore.salaryOperationsOneCols)
                  .length
                  ? salaryOperationOneStore.historyTitles.map((title) => {
                      return (
                        <th key={title}>
                          {
                            salaryOperationOneStore.salaryOperationsOneCols[
                              title
                            ].title
                          }
                        </th>
                      );
                    })
                  : null}
              </tr>
            </thead>
            <tbody>
              {Object.values(
                salaryOperationOneStore.salaryOperationsHistory
              ).map((history) => {
                return (
                  <tr key={history.id}>
                    {salaryOperationOneStore.historyTitles.map((title) => {
                      return typeof history[title] !== "object" ? (
                        <td key={`${history.id}_${title}`}>{history[title]}</td>
                      ) : null;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div className={styles.withoutHistory}>У операции нет истории</div>
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(SalaryOperationsOne);
