import { makeAutoObservable, runInAction } from "mobx";
import { modulesList } from "../shared/utils/dictionaries/allModules";
import RootStore from "stores";

import { MutableRefObject } from "react";
import { IScrollBars } from "./utils/types/ScrollBars";
import { ApiResponse } from "stores/utils/types/ApiResponse";

type allWindowsProps = {
  [key: string]: {
    path: string;
    title: string;
    id?: string;
    parent_path?: string;
  };
};

export type modulesListType = {
  [key: string]: {
    id: string;
    title: string;
    dir: string;
    orderliness: number;
    front?: string | null;
    link?: string;
    now?: false;
    sections?: {
      [key: string]: {
        id: string;
        title: string;
        ordered: number;
        submodules?: {
          [key: string]: {
            id: string;
            title: string;
            dir: string;
            ordered: number;
          };
        };
      };
    };
  };
};

type topMenuButtonType = {
  title: string;
  type: string;
  content: {
    [key: string]: string;
  };
};

type topMenuListType = {
  corporate_portal: topMenuButtonType;
  info: topMenuButtonType;
  main_site: topMenuButtonType;
  notice: topMenuButtonType;
  user: topMenuButtonType;
  support: topMenuButtonType;
  content: {
    title: string;
    type: string;
    content: {
      [key: string]: {
        [key: string]: string;
      };
    };
  };
};

export default class MenuStore {
  allWindows: allWindowsProps = {};
  selectedWindow = "all";
  allModules = true;
  openedModule = "";
  openedSubmodule = "";
  error = false;
  isFirstScrollRender = false;

  modulesList: modulesListType = {};
  topMenuList: Partial<topMenuListType> = {};

  tabId = "";

  isMenuShown = true;

  scroll: Partial<{ [key: string]: number }> = {};
  isScroll = false;
  isScrollBottom = false;
  scrollSavedPosition: Partial<{ [key: string]: number }> = {};

  scrollbarRef: Partial<MutableRefObject<IScrollBars>> = {};

  staffWidgetList: string[] = [];

  closedModules = [];
  closedSubModules: Record<string, string[]>[] = [
    { aregister: ["reference"] },
    { staff: ["staffVacation"] }
  ];

  closeAll = () => {
    this.setOpenedSubmodule("");
    this.setAllModules(true);
    this.deleteWindow("all");
    this.setSelectedWindow("all");

    this.rootStore.filterStore.resetAll();

    this.rootStore.staffListStore.resetAll();
    this.rootStore.staffVacationStore.resetAll();

    this.rootStore.polatiAppStore.resetAll();

    this.rootStore.friendInviteStore.resetAll();

    this.rootStore.buildingListStore.resetAll();
    this.rootStore.aregisterListStore.resetAll();

    this.rootStore.salaryOperationsListStore.resetAll();

    this.rootStore.projectsListStore.resetAll();

    this.rootStore.salaryPaymentlistAllStore.resetAll();
  };

  setIsScroll = (value: boolean) => {
    this.isScroll = value;
  };

  setIsScrollBottom = (value: boolean) => {
    this.isScrollBottom = value;
  };

  toggleIsMenuShown = (value: boolean) => {
    this.isMenuShown = value;
  };

  setAllModules = (state: boolean) => {
    this.allModules = state;
  };

  setOpenedModule = (value: string) => {
    this.openedModule = value;
    this.allModules = false;
  };

  setOpenedSubmodule = (value: string) => {
    this.openedSubmodule = value;
  };

  sendTabId = (value: string) => {
    this.tabId = value;
  };

  addWindow = (path: string, title: string) => {
    this.allWindows[path] = { path: path, title: title };
    this.allWindows = { ...this.allWindows };
    this.setSelectedWindow(path);
  };

  addTabWindow = (path: string, title: string) => {
    this.allWindows[path] = { path: path, title: title };
    this.allWindows = { ...this.allWindows };
    this.setSelectedWindow(path);
  };

  addParentPath = (parentPath: string, path: string) => {
    this.allWindows[path]["parent_path"] = parentPath;
  };

  updateTabWindow = ({
    mainPath,
    path,
    title
  }: {
    mainPath: string;
    path?: string;
    title?: string;
  }) => {
    if (this.allWindows[mainPath]) {
      this.allWindows[mainPath] = {
        path: path ? path : this.allWindows[mainPath]["path"],
        title: title ? title : this.allWindows[mainPath]["title"],
        parent_path: this.allWindows[mainPath]?.["parent_path"] || ""
      };
      this.allWindows = { ...this.allWindows };
    }
  };

  updateWindow = ({
    mainPath,
    path,
    title
  }: {
    mainPath: string;
    path?: string;
    title?: string;
  }) => {
    this.allWindows[mainPath] = {
      path: path ? path : this.allWindows[mainPath]["path"],
      title: title ? title : this.allWindows[mainPath]["title"],
      parent_path: this.allWindows[mainPath]?.["parent_path"] || ""
    };
    this.allWindows = { ...this.allWindows };
    this.setSelectedWindow(path);
  };

  deleteWindow = (mainPath: string) => {
    if (mainPath !== "all") {
      // при закрытии вкладки нужно удалить ошибки этой вкладки в apiStore
      this.rootStore.apiStore.deleteErrorPage(
        `${this.tabId || "main"}_${this.openedModule || "main"}_${
          this.openedSubmodule || null
        }`
      );
      // затем удалить вкладку
      delete this.allWindows[mainPath];
      // и перезаписать объект this.allWindows для корректной работы
      this.allWindows = { ...this.allWindows };
    } else {
      // если закрываем все вкладки -- удаляем все ошибки из apiStore
      this.rootStore.apiStore.deleteErrorPage("all");
      // а затем удаляем все открытые вкладки
      this.allWindows = {};
    }
  };

  setSelectedWindow = (value: string) => {
    this.selectedWindow = value;
  };

  setScroll = (e) => {
    this.scroll = e;
  };

  setMarginLeft = (value: number) => {
    this.scroll.scrollLeft = value;
  };

  setScrollPosition = (value: Partial<{ [key: string]: number }>) => {
    this.scrollSavedPosition = value;
  };

  // метод, который нужно применять в useEffect для компонентов таблиц вместе с методами setOpenedModule, setOpenedSubmodule и addWindow
  // позволяет вернуть скролл к той позиции, с которой был совершен переход из таблицы на страницу сотрудника/объекта/заявки
  setSavedScroll = () => {
    if (
      this.scrollSavedPosition.scrollLeft ||
      this.scrollSavedPosition.scrollTop
    ) {
      this.scrollbarRef.current?.view?.scroll({
        top: this.scrollSavedPosition.scrollTop,
        left: this.scrollSavedPosition.scrollLeft
      });
      this.setScrollPosition({});
    }
  };

  setScrollValue = (value: Partial<MutableRefObject<IScrollBars>>) => {
    this.scrollbarRef = value;
  };

  getAllModulesList = async () => {
    try {
      const data: ApiResponse<modulesListType | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "core",
          currentClass: "components",
          method: "getList"
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          Object.entries(data["result"]).forEach(([key, value]) => {
            if (value["front"]) {
              if (key in modulesList) {
                this.modulesList[key] = modulesList[key];
              } else {
                this.closedModules.push(key);
                this.modulesList[key] = data["result"][key];
              }
            } else {
              this.modulesList[key] = data["result"][key];
            }
          });
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTopMenu = async () => {
    try {
      const data: ApiResponse<topMenuListType | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "core",
          method: "getTopMenu"
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.topMenuList = {
            // hardcode
            support: {
              title: "Помощь",
              type: "link",
              content: {
                link: "https://t.me/crm_help_bot"
              }
            },
            ...data.result
          };
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getStaffWidgetsList = async () => {
    try {
      const data: { widgets: { [key: string]: string } } =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getWidgetsList"
        });

      runInAction(() => {
        if (data["widgets"]) {
          this.staffWidgetList = Object.values(data["widgets"]);
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setIsFirstScrollRender = (val: boolean) => (this.isFirstScrollRender = val);

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
