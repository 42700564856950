import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { NavType } from "stores/utils/types/NavType";
import { Col } from "stores/utils/types/Col";
import { SelectModule } from "stores/StaffModule/types/Selects";
import { BuildingEventType } from "stores/StaffModule/types/BuildingEventType";
import { FilterType } from "stores/utils/types/FilterType";
import { Param } from "stores/utils/types/Param";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { cloneDeep } from "lodash";

type SafetyWorkListItem = {
  id: string;
  type: string;
  kind: string;
  tn: number;
  uid: string;
  name: string;
  surname: string;
  patronymic: string;
  date_start: string;
  date_end: string;
  status_certificate: "Ok" | "Не действителен";
  company: string;
  title_company: string;
  file?: File;
  events_type: {
    [key: string]: Record<
      "type_event" | "id_building" | "title_building" | "title_company",
      string
    >;
  };
};

type SafetyListData = {
  code: number;
  hash: string;
  nav: NavType;
  records: Record<string, SafetyWorkListItem>;
  show_fields: Record<string, string>;
  cols: Record<string, Col>;
};

type StaffListItem = {
  tn: number;
  fio: string;
  id: string;
};

export default class SafetyWorkListStore {
  error = false;
  errorMessage = "";
  isLoading = false;
  isLoadingForFilters = false;
  isLoadingForOneCert = false;
  errorMessageForOneCert: Partial<Errors["message"]> = {};
  // переменная, которая отвечает будет ли крутиться лоадер при использовании метода getSafetyWorkList
  // так как при редактировании одного сертификата, таблица должна обновляться без лоадера
  withoutLoading = false;

  currentTitles: string[] = [];
  currentFieldsForOneCert: string[] = [];
  requiredFieldsForEdit: string[] = [];
  selectedCert = "";

  safetyWorkList: SafetyWorkListItem[] = [];
  safetyWorkCols: Record<string, Col> = {};
  safetyWorkColsParam: Record<string, Col> = {};
  safetyWorkParams: Record<string, Param> = {};
  safetyWorkSelects: Record<string, SelectModule> = {};
  safetyWorkOneCert: Partial<SafetyWorkListItem> = {};
  initialValuesForOneCertEdit: Record<string, string | File> = {};
  dateRangeFields: Record<string, string[]> = {};

  safetyWorkListOrder = "";
  safetyWorkListOrdered = "";
  filterParams: { [key: string]: string[] | string | number } = {};
  queryParams: { [key: string]: string } = {};
  // объект, в котором хранится не сохраненный фильтр
  filtersChanged: { [key: string]: FilterType } = {};

  onPage = 100;
  page = 1;
  maxPage = 0;
  prevPage = 1;
  foundCounter = 0;
  selectedFilter = "";
  downloadedFilter = "";

  buildingEventsType: { [key: string]: BuildingEventType } = {};

  fieldsWithoutFilter = ["tn", "status_certificate", "time_create", "id"];

  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";
  isLoadingForStaffList = false;

  // поля для работы со выпадающим списком Объект в фильтре
  pageBuilding = 1;
  onPageBuilding = 30;
  maxPageBuilding = 0;
  prevPageBuilding = 1;
  searchValueBuilding = "";
  isLoadingForBuildingList = false;

  // список в виде пары id - title
  // нужен для вывода title в компоненте FilterMainCols и FilterMainWindow
  dictForFilter: Record<string, string> = {};

  filters: Record<string, FilterType> = {};

  // предустановленные фильтры хардкодим на фронте
  generalFilters: Record<string, FilterType> = {
    filter_default: {
      cols: [],
      default: 1,
      filter: {},
      general: 1,
      id: "filter_default",
      order: "time_create",
      ordered: "DESC",
      title: "Весь список"
    },
    cert_expires_30: {
      cols: [],
      default: 0,
      filter: { cert_expires: 30 },
      general: 1,
      id: "cert_expires_30",
      order: "time_create",
      ordered: "DESC",
      title: "Осталось 30 дней"
    },
    cert_expires_20: {
      cols: [],
      default: 0,
      filter: { cert_expires: 20 },
      general: 1,
      id: "cert_expires_20",
      order: "time_create",
      ordered: "DESC",
      title: "Осталось 20 дней"
    },
    cert_expires_10: {
      cols: [],
      default: 0,
      filter: { cert_expires: 10 },
      general: 1,
      id: "cert_expires_10",
      order: "time_create",
      ordered: "DESC",
      title: "Осталось 10 дней"
    }
  };

  getData = () => {
    !Object.keys(this.safetyWorkParams).length && this.getTableParams();
    this.getSafetyWorkList();
  };

  newParams = (): { [key: string]: string } => {
    const params: { [key: string]: string } = {};
    if (this.safetyWorkListOrder) {
      params["order"] = this.safetyWorkListOrder;
    }
    if (this.safetyWorkListOrdered) {
      params["ordered"] = this.safetyWorkListOrdered;
    }

    return params;
  };

  getNewFilterParams = (filter: FilterType) => {
    this.filterParams = {};
    Object.entries(filter.filter).forEach(([key, value]) => {
      if (
        (typeof value === "string" && value) ||
        (Array.isArray(value) && value.length) ||
        typeof value === "number"
      ) {
        this.filterParams[`filter[${key}]`] = value;
      } else if (typeof value === "object" && !Array.isArray(value)) {
        this.filterParams[`filter[${key}]`] = Object.values(value);
      }
    });
  };

  getSafetyWorkListWithFilter = (filter: FilterType) => {
    this.getNewFilterParams(filter);
    if (filter.default) {
      this.setSafetyWorkListOrder("");
      this.setSafetyWorkListOrdered("");
    }

    this.setPage(1);
    this.setDownloadedFilter(filter["id"]);
    this.getSafetyWorkList();
  };

  getFilters = async (action?: string, id?: string) => {
    try {
      const data: ApiResponse<never>
        & {savedFilter: { [key: string]: { filterdata: FilterType } };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "filters",
        method: "getFilter",
        params: { module: "safetywork", prefix: "certs" }
      });

      runInAction(() => {
        // проверяем на наличие новых фильтров, если сохраненные в сторе фильтры равны количеству приходящих с бэка плюс четыре
        // (потому что четыре фильтра мы прописываем хардкодом на фронте) или же передан action на добавление/редактирование/удаление
        // и код двухсотый, то обновляем поле this.filters
        if (
          data["code"] === 200 &&
          (Object.keys(this.filters).length !==
            Object.keys(data["savedFilter"]).length + 4 ||
            action)
        ) {
          this.filters = {};
          this.filters = cloneDeep(this.generalFilters);
          Object.entries(data["savedFilter"]).forEach(([key, filter]) => {
            this.filters[key] = filter["filterdata"];
            this.filters[key]["id"] = key;
            this.filters[key]["filter"] = filter["filterdata"]?.["filter"]
              ? filter["filterdata"]?.["filter"]
              : {};

            Object.entries(this.dateRangeFields).forEach(
              ([keyRange, valueRange]) => {
                if (
                  valueRange.every(
                    (field) => field in this.filters[key]["filter"]
                  )
                ) {
                  this.filters[key]["filter"][keyRange] = "";
                }
              }
            );
          });

          Object.values(this.filters).forEach((filter) => {
            if (action) {
              switch (action) {
                case "add":
                  this.setSelectedFilter("new");
                  break;
                case "delete":
                  if (filter.default) {
                    this.setSelectedFilter(filter.id);
                  }
                  break;
                case "edit":
                  if (id === filter.id) {
                    this.setSelectedFilter(filter.id);
                  }
                  break;
              }
            }
          });
          Object.values(this.filters).forEach((filter) => {
            if (
              !this.downloadedFilter &&
              filter.default &&
              !Object.keys(this.filterParams).length
            ) {
              this.setDownloadedFilter(filter.id);
            }
          });
          this.filtersChanged = cloneDeep(this.filters);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  setFilters = async (action: string, id: string, values: FilterType) => {
    const newParams: { [key: string]: string | number } | { cols?: string[] } =
      {};
    runInAction(() => {
      this.isLoadingForFilters = true;
    });

    Object.entries(values).forEach(([key, value]) => {
      if (!key.includes("isIntervalField")) {
        switch (key) {
          case "filter":
            value
              ? Object.entries(value).forEach(
                  ([keyField, valueField]: [
                    string,
                    string | number | { [key: string]: string }
                  ]) => {
                    switch (typeof valueField) {
                      case "object":
                        newParams[`filter[${keyField}]`] =
                          Object.values(valueField);
                        break;
                      default:
                        if (valueField || valueField === 0)
                          newParams[`filter[${keyField}]`] = valueField;
                        break;
                    }
                  }
                )
              : "";
            break;
          case "id":
            break;
          case "cols":
            break;
          default:
            newParams[key] = values[key];
            break;
        }
      }
    });

    const newSelectedFilter = this.filters?.[id]?.default
      ? Object.values(this.filters).filter((filter) => filter.general)[0]["id"]
      : Object.values(this.filters).filter((filter) => filter.default)[0]?.[
          "id"
        ] || Object.keys(this.filters).filter((filter) => filter !== id)[0];

    switch (action) {
      case "add":
        newParams["saveFilter"] = values.title;

        break;
      case "edit":
        newParams["updateFilter"] = id;
        this.setSafetyWorkListOrder(values.order);
        this.setSafetyWorkListOrdered(values.ordered);
        this.setSelectedFilter(id);
        this.setDownloadedFilter(id);
        this.getNewFilterParams(values);
        break;
      case "delete":
        newParams["removeFilter"] = id;

        this.setSelectedFilter(newSelectedFilter);
        this.setDownloadedFilter(newSelectedFilter);

        if (this.selectedFilter) {
          this.getNewFilterParams(this.filters[this.selectedFilter]);
        }

        break;
    }

    try {
      const data: ApiResponse<string> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "filters",
        method: "setFilter",
        params: { module: "safetywork", prefix: "certs", ...newParams }
      });

      runInAction(() => {
        if (data["result"]) {
          this.getSafetyWorkList(action, id);
        } else {
          this.isLoadingForFilters = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  setFiltersChanged = (
    currentFilter: string,
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => {
    if (
      this.filtersChanged[currentFilter] &&
      Object.keys(this.filtersChanged[currentFilter]).length
    ) {
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
      this.filtersChanged[currentFilter][key] = value;
    } else {
      this.filtersChanged[currentFilter] = {
        id: "new",
        title: "",
        default: 0,
        general: 0,
        filter: {},
        cols: [],
        order: "time_create",
        ordered: "DESC"
      };
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
    }
    this.filtersChanged[currentFilter][key] = value;
  };

  getSafetyWorkList = async (action?: string, id?: string) => {
    if (!this.withoutLoading) {
      this.isLoading = true;
    }
    this.errorMessage = "";
    this.page = 1;

    try {
      const data: SafetyListData = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "safetywork",
        method: "getSafetywork",
        on_page: this.onPage * this.prevPage,
        page: this.page,
        params: {
          ...this.newParams(),
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (Object.values(data["cols"]).length) {
          this.safetyWorkCols = data["cols"];
          Object.entries(data["cols"]).forEach(([key, value]) => {
            if (!this.fieldsWithoutFilter.includes(key)) {
              this.safetyWorkColsParam[key] = value;
            }
            if ("range" in value) {
              this.dateRangeFields[key] = Object.keys(value["range"]);
            }
          });
          // хардкод, бэк такого поля не отдает, но фильтроваться по нему можно
          this.safetyWorkColsParam["cert_expires"] = {
            newname: "cert_expires",
            title: "Осталось дней"
          };
          this.getSelects();
          this.getCompaniesList();
          this.getBuildingEventType();
          this.getStaffList();
          this.getBuildingList();
        }

        this.getFilters(action, id);

        if (Object.values(data["records"]).length) {
          this.safetyWorkList = Object.values(data.records);
        } else {
          this.safetyWorkList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        if (Object.values(data["show_fields"]).length) {
          this.currentTitles = Object.values(data["show_fields"]);
        } else {
          this.currentTitles = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }

        this.page = this.prevPage;
        this.maxPage = data["nav"]["max_page"];
        this.foundCounter = data["nav"]["count"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        if (!this.withoutLoading) {
          this.isLoading = false;
        }
        this.isLoadingForFilters = false;
        this.withoutLoading = false;
      });
    }
  };

  getMoreSafetyWorkList = async () => {
    this.errorMessage = "";
    this.isLoading = true;
    try {
      const data: SafetyListData = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "safetywork",
        method: "getSafetywork",
        on_page: this.onPage,
        page: this.page,
        params: {
          ...this.newParams(),
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (Object.values(data.records).length) {
          this.safetyWorkList.push(...Object.values(data.records));
          this.rootStore.menuStore.isScrollBottom = false;
        } else {
          this.safetyWorkList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: {
        select: Record<string, SelectModule>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "safetywork",
        method: "getSelects"
      });

      runInAction(() => {
        if (Object.values(data.select).length) {
          this.safetyWorkSelects = data["select"];
          Object.entries(data["select"]).forEach(([key, value]) => {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            if (
              key in this.safetyWorkColsParam &&
              this.safetyWorkParams[key]["isvariable"] === "on"
            ) {
              Object.values(value).forEach((item) => {
                directory[item.id ? item.id : item.title] = {
                  newname: item.id ? item.id : item.title,
                  title: item.title
                };
              });
              this.safetyWorkColsParam[key] = {
                ...this.safetyWorkColsParam[key],
                directory: directory
              };
            }
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCompaniesList = async () => {
    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "company",
        currentClass: "company",
        method: "getList",
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if (
            "company" in this.safetyWorkParams &&
            this.safetyWorkParams["company"]["isvariable"] === "on"
          ) {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            Object.values(data["result"]).forEach((value) => {
              directory[value.id] = {
                newname: value.id,
                title: value.title
              };
            });
            this.safetyWorkColsParam["company"] = {
              ...this.safetyWorkColsParam["company"],
              directory: directory
            };
          }
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  dataPreparationStaff = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        if (typeof value === "string" || typeof value === "number") {
          switch (key) {
            case "id":
              tempOneOfStaffObj["newname"] = value as string;
              break;
            case "tn":
              break;
            case "fio":
              tempOneOfStaffObj["title"] = `ТН${oneOfStaff["tn"]} ${value}`;
              break;
            default:
              break;
          }
        }
      });
      return tempOneOfStaffObj;
    });
  };

  getStaffList = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<Record<string, StaffListItem>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          Object.values(data["result"]).forEach((value) => {
            this.dictForFilter[value["id"]] = value["fio"];
          });
          if (
            "uid" in this.safetyWorkParams &&
            this.safetyWorkParams["uid"]["isvariable"] === "on"
          ) {
            this.safetyWorkColsParam["uid"] = {
              ...this.safetyWorkColsParam["uid"],
              directory: this.dataPreparationStaff(
                Object.values(data["result"])
              )
            };
          }
        } else {
          this.error = true;
          this.safetyWorkColsParam["uid"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.maxPageStaff = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<{ [key: string]: StaffListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          if (
            "uid" in this.safetyWorkParams &&
            this.safetyWorkParams["uid"]["isvariable"] === "on"
          ) {
            const directory = (
              this.safetyWorkColsParam["uid"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparationStaff(Object.values(data["result"])));
            this.safetyWorkColsParam["uid"] = {
              ...this.safetyWorkColsParam["uid"],
              directory: directory
            };
          }
        } else {
          this.safetyWorkColsParam["uid"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.prevPageStaff = this.pageStaff;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  // метод для создания списков в выпадающем окне фильтров
  dataPreparation = (data: { id: string; title: string }[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        switch (key) {
          case "id":
            tempOneOfStaffObj["newname"] = value;
            break;

          default:
            tempOneOfStaffObj[key] = value;
        }
      });
      return tempOneOfStaffObj;
    });
  };

  // метод для получения объектов в выпадающем окне фильтра
  getBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("building" in this.safetyWorkColsParam) {
            this.safetyWorkColsParam["building"] = {
              ...this.safetyWorkColsParam["building"],
              directory: this.dataPreparation(Object.values(data["result"]))
            };
          }
        } else {
          this.safetyWorkColsParam["building"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.maxPageBuilding = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.safetyWorkColsParam["building"]["directory"] = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingForBuildingList = false;
      });
    }
  };

  // метод для получения объектов в выпадающем окне фильтра при скролле списка (пагинация)
  getMoreBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("building" in this.safetyWorkColsParam) {
            const directory = (
              this.safetyWorkColsParam["building"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.dataPreparation(
                Object.values(data["result"]).filter(
                  (building) => !(building["id"] in this.dictForFilter)
                )
              )
            );

            this.safetyWorkColsParam["building"] = {
              ...this.safetyWorkColsParam["building"],
              directory: directory
            };
          }
        } else {
          this.safetyWorkColsParam["building"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.prevPageBuilding = this.pageBuilding;
      });
    } catch (error) {
      runInAction(() => {
        this.safetyWorkColsParam["building"]["directory"] = [];
      });
    } finally {
      runInAction(() => (this.isLoadingForBuildingList = false));
    }
  };

  // метод для получения объектов по их id из сохраненных фильтров и последующее их добавление в справочник
  getBuildingDictForSavedFilter = async (valueFromSavedFilter?: string[]) => {
    const formDataFromSavedFilter = {
      "filter[where][id][value]": valueFromSavedFilter,
      "filter[where][id][logic]": "OR",
      "filter[where][id][comparison]": "IN"
    };
    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        params: formDataFromSavedFilter,
        select: ["id", "title"]
      });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).forEach((value) => {
            this.dictForFilter[value["id"]] = value["title"];
          });
          if ("building" in this.safetyWorkColsParam) {
            const directory = (
              this.safetyWorkColsParam["building"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparation(Object.values(data["result"])));

            this.safetyWorkColsParam["building"] = {
              ...this.safetyWorkColsParam["building"],
              directory: directory
            };
          }
        } else {
          this.dictForFilter = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.dictForFilter = {};
      });
    }
  };

  getTableParams = async () => {
    this.currentFieldsForOneCert = [];
    try {
      const data: ApiResponse<Record<string, Param>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          currentClass: "safety_certs",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.safetyWorkParams = data["result"];
          Object.entries(this.safetyWorkParams).forEach(([key, value]) => {
            if (value["editable"] === "on")
              this.currentFieldsForOneCert.push(key);
            if (value["required"]) {
              this.requiredFieldsForEdit.push(key);
            }
          });
          this.currentFieldsForOneCert.push("file");
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getBuildingEventType = async () => {
    try {
      const data: ApiResponse<{ [key: string]: BuildingEventType } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building_events_type",
          method: "getList"
        });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).forEach((type) => {
            this.buildingEventsType[type.id] = type;
          });

          if ("events_type" in this.safetyWorkColsParam) {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            Object.values(data["result"]).forEach((type) => {
              directory[type.id] = {
                newname: type.id,
                title: type.title
              };
            });
            this.safetyWorkColsParam["events_type"] = {
              ...this.safetyWorkColsParam["events_type"],
              directory: directory
            };
          }
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getOneCert = async (id: string) => {
    this.isLoadingForOneCert = true;
    this.errorMessageForOneCert = {};
    try {
      const data: ApiResponse<SafetyWorkListItem> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          method: "getOneSafetywork",
          params: {
            id
          }
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.safetyWorkOneCert = data["result"];

          this.currentFieldsForOneCert.forEach((field) => {
            this.initialValuesForOneCertEdit[field] =
              this.safetyWorkOneCert[field];
          });
        } else {
          this.safetyWorkOneCert = {};
          this.errorMessageForOneCert = data["message"];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingForOneCert = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForOneCert = false;
      });
    }
  };

  updateCertificate = async (
    id: string,
    values: Record<string, string | File | number>
  ) => {
    const formData: Record<string, string | File | number> = {};
    Object.entries(values).forEach(([key, value]) => {
      if (key !== "file" && value) {
        formData[`update[${key}]`] = value;
      }
    });
    delete formData["update[withoutDateEnd]"];
    (formData["id"] = id), (formData["file"] = values["file"]);

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "safetywork",
        method: "updateСertificate",
        body: formData
      });

      runInAction(() => {
        if (data.result) {
          this.withoutLoading = true;
          this.getSafetyWorkList();
        } else {
          this.errorMessageForOneCert = data["message"];
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  deleteFile = async (id: string) => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "safetywork",
        method: "deleteFile",
        body: {
          id
        }
      });

      if (!data.result) {
        this.error = true;
        this.errorMessageForOneCert = data["message"];
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setErrorMessageForOneCert = (value: Partial<Errors["message"]>) => {
    this.errorMessageForOneCert = value;
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setFilterParamsFromQuery = (value: { [key: string]: string[] | string }) => {
    this.filterParams = value;
  };

  setQueryParams = (value: { [key: string]: string }) => {
    this.queryParams = value;
  };

  setSafetyWorkListOrder = (value: string) => {
    this.safetyWorkListOrder = value;
  };

  setSafetyWorkListOrdered = (value: string) => {
    this.safetyWorkListOrdered = value;
  };

  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  setSearchValueBuilding = (value: string) => {
    this.setPageBuilding(1);
    this.prevPageBuilding = 1;
    this.searchValueBuilding = value;
  };

  setPageBuilding = (value: number) => {
    if (!this.isLoadingForBuildingList) {
      this.pageBuilding = value;
    }
  };

  setSelectedCert = (value: string) => {
    this.selectedCert = value;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
