import styles from "./staffOneStatuses.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LoadedComponent from "widgets/LoadedComponent";
import StaffOneStatusesOnObject from "./StaffOneStatusesOnObject";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import StaffOneStatusesHeader from "./StaffOneStatusesHeader";

const StaffOneStatuses = () => {
  const { menuStore, staffOneStatusesStore, staffOneStore } = useStores();
  const [isFocusSearch, setIsFocusSearch] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    setSelectedTab(
      localStorage.getItem("selectedTab")
        ? localStorage.getItem("selectedTab")
        : "table"
    );
  }, []);

  useEffect(() => {
    menuStore.scrollbarRef.current?.view?.scroll({
      left: 0,
      behavior: "smooth"
    });
    localStorage.setItem(
      "selectedTab",
      selectedTab
        ? selectedTab
        : localStorage.getItem("selectedTab")
        ? localStorage.getItem("selectedTab")
        : "table"
    );
  }, [selectedTab]);

  const { id } = useParams();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    !staffOneStatusesStore.staffList.length &&
      staffOneStatusesStore.getStaffList();
    setIsFocusSearch(false);
    menuStore.sendTabId(id);
    staffOneStatusesStore.setSelectedOneForStatuses(id);
    staffOneStore.setSelectedOneForInfo(id);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffStatuses");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/statuses`
    });
  }, [id]);

  useOnClickOutside({
    ref,
    handler: (event) => {
      if (
        event.target["className"]?.length &&
        !event.target["className"].toLowerCase().includes("search")
      ) {
        setIsFocusSearch(false);
      }
    }
  });

  return (
    <LoadedComponent
      isLoading={staffOneStatusesStore.isLoading}
      error={staffOneStatusesStore.error}
    >
      <div className={styles.container}>
        <StaffOneStatusesHeader
          isFocusSearch={isFocusSearch}
          setIsFocusSearch={setIsFocusSearch}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <StaffOneStatusesOnObject selectedTab={selectedTab} />
        </ErrorBoundary>
      </div>
    </LoadedComponent>
  );
};

export default observer(StaffOneStatuses);
