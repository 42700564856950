import styles from "./salaryOperationsOneEdit.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Formik } from "formik";

import LoadedComponent from "widgets/LoadedComponent";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import Modal from "shared/ui/Modal";
import SalaryOperationsForm from "features/SalaryOperationsForm";

import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

import { getValidationSchema } from "./validation";

const SalaryOperationsOneEdit = () => {
  const { id } = useParams();
  const { menuStore, salaryOperationOneStore, salaryOperationsFormStore } =
    useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [openedModal, setOpenedModal] = useState("");
  const [initialValues, setInitialValues] = useState(
    salaryOperationsFormStore.initialValues
  );
  const [requiredField, setRequiredField] = useState<string[]>([]);

  const changeOpenedListName = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  useEffect(() => {
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary`) {
        menuStore.deleteWindow(key);
      }
    });

    salaryOperationOneStore.setSelectedOneOfSalaryOperation(id);

    menuStore.updateWindow({
      mainPath: `/salary/id=${id}`,
      path: `/salary/id=${id}/edit`
    });

    menuStore.sendTabId(id);
    menuStore.setOpenedModule("salaryOne");
    menuStore.setOpenedSubmodule("salaryOneEdit");

    setInitialValues(
      salaryOperationsFormStore.getInitialValuesForEdit(
        salaryOperationOneStore.selectedOneSalaryOperation
      )
    );

    initialValues
      ? setRequiredField(
          salaryOperationsFormStore.getRequiredFields(initialValues)
        )
      : "";
  }, [id]);

  return (
    <LoadedComponent isLoading={salaryOperationsFormStore.isLoading}>
      <div className={styles.form}>
        {initialValues ? (
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(
              Object.keys(initialValues),
              requiredField
            )}
            onSubmit={() => {
              return;
            }}
          >
            {({ values, isValid, dirty, handleSubmit, handleReset }) => {
              return (
                <>
                  <div className={styles.form__fields}>
                    <SalaryOperationsForm
                      values={values}
                      openedListName={openedListName}
                      changeOpenedListName={changeOpenedListName}
                      setOpenedModal={setOpenedModal}
                      id={id}
                      operation={
                        salaryOperationOneStore.selectedOneSalaryOperation
                      }
                      isEdit
                    />
                  </div>

                  <ButtonsGroupForSettings
                    saveBtnTitle="Сохранить изменения"
                    saveBtnDisabled={!isValid || !dirty}
                    saveBtnOnClick={handleSubmit}
                    addBtnTitle="Отменить изменения"
                    addBtnOnClick={handleReset}
                    addBtnDisabled={!dirty}
                    addBtnImg={<IconClose />}
                    cancelBtnTitle="Удалить операцию"
                    cancelBtnOnClick={() => {
                      setOpenedModal("delete_operation");
                      handleReset();
                    }}
                    cancelBtnImg={<IconBasket />}
                  />
                  {openedModal.length > 0 ? (
                    <Modal
                      type="clarification"
                      show={openedModal.length > 0}
                      onHide={() => {
                        setOpenedModal("");
                      }}
                      title={
                        openedModal === "paymentlist"
                          ? "Вы уверены, что хотите открепить операцию от Ведомости?"
                          : openedModal === "cancel_operation"
                          ? "Вы уверены, что хотите отменить операцию?"
                          : openedModal === "delete_operation"
                          ? "Вы уверены, что хотите удалить операцию?"
                          : ""
                      }
                      btnWithCrossTitle={
                        openedModal === "paymentlist"
                          ? "Открепить"
                          : openedModal === "cancel_operation"
                          ? "Да"
                          : openedModal === "delete_operation"
                          ? "Удалить"
                          : ""
                      }
                      btnWithCrossOnClick={() => {
                        setOpenedModal("");
                        salaryOperationOneStore.cancelOperation(id);
                      }}
                      blueBtnOnClick={() => {
                        setOpenedModal("");
                      }}
                      blueBtnTitle={
                        openedModal === "cancel_operation" ? "Нет" : "Отмена"
                      }
                      btnWithCrossImg={openedModal !== "cancel_operation"}
                    />
                  ) : null}
                </>
              );
            }}
          </Formik>
        ) : null}
      </div>
    </LoadedComponent>
  );
};

export default observer(SalaryOperationsOneEdit);
