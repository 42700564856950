import styles from "./selectMultiTable.module.scss";
import { useField } from "formik";
import { useRef, useState } from "react";
import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAddDefault.svg";
import { ReactComponent as IconSend } from "shared/assets/images/mainIcons/iconSend.svg";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import StatusIcon from "shared/ui/StatusIcon";
import Tooltip from "shared/ui/Tooltip";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type SelectMultiTableProps = {
  name: string;
  options: { [key: string]: OptionWithTitle };
  valueName?: string;
  placeholder?: string;
  withBorder?: boolean;
  isMassEdit?: boolean;
  onClickMass?: () => void;
};

const SelectMultiTable = ({
  name,
  options,
  valueName,
  placeholder,
  withBorder,
  isMassEdit,
  onClickMass
}: SelectMultiTableProps) => {
  const [listOptions, setListOptions] = useState<OptionWithTitle[]>([]);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [field, meta, { setValue }] = useField({
    name
  });
  const ref = useRef<HTMLDivElement>();
  const valueField = valueName || "newname";
  const values: string[] = field.value && Object.values(field.value);

  const handleOnKeyDown = (
    e: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === "Escape" && isMenuOpened) {
      setIsMenuOpened(false);
      e.stopPropagation();
    }
  };

  const deleteItem = (item: string) => {
    const itemObj = { title: getTitleFromOption(item) };
    itemObj[valueField] = item;
    setListOptions([...listOptions, itemObj]);

    const newValues: string[] = [];
    values.forEach((value) => {
      if (!listOptions[value] && value !== item) {
        newValues.push(value);
      }
    });
    setValue(newValues);
  };

  const getTitleFromOption = (value: string) => {
    let title = "";
    if (options[value]) {
      title = options[value]["title"];
    } else {
      Object.values(options).forEach((option) => {
        if (option[valueField] === value) {
          title = option["title"];
        }
      });
    }
    return title;
  };

  useOnClickOutside({
    ref,
    handler: () => setIsMenuOpened(false)
  });

  const getContent = () => {
    return (
      <>
        <div className={styles.container}>
          <div className={styles.valueContainer}>
            {values && values.length && !meta.error ? (
              values.map((item) => (
                <Chips
                  variation={ChipsVariation.COLS}
                  key={item}
                  className={`${styles.item} ${
                    isMassEdit ? styles.item_massEdit : ""
                  }`}
                  id={`selectMultiTable_chooseOption_${item}`}
                  title={getTitleFromOption(item)}
                  onDelete={() => {
                    deleteItem(item);
                  }}
                />
              ))
            ) : meta.error ? (
              <div className={styles.error}>
                <StatusIcon icon="attention" color="accent-red" />
                <p>Обязательное поле</p>
              </div>
            ) : placeholder ? (
              <div className={styles.placeholder}>
                {placeholder.toLowerCase()}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={styles.iconsContainer}>
            <IconAdd
              className={isMenuOpened ? styles.iconAdd_active : styles.iconAdd}
              onClick={() => setIsMenuOpened(!isMenuOpened)}
              id={`selectMultiTable_showMenu_${name}`}
              onKeyDown={handleOnKeyDown}
              tabIndex={-1}
            />
            {isMassEdit ? (
              <Tooltip
                text={
                  values && values.length
                    ? "Применить для всех"
                    : `${
                        placeholder ?? "Выберите значение"
                      }, чтобы применить для всех`
                }
                placement="top"
                borderColor={
                  values && values.length ? "blue-lazure" : "bw-gray6"
                }
                color={values && values.length ? "blue-lazure" : "bw-gray6"}
              >
                <IconSend
                  onClick={() => onClickMass && onClickMass()}
                  className={
                    values && values.length
                      ? styles.iconSend
                      : styles.iconSend_disabled
                  }
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
        </div>
        {isMenuOpened ? (
          <div className={styles.scrollboard} ref={ref}>
            <ItemsScrollBoard
              options={options}
              notSearchable={Object.values(options).length < 7}
              addItem={(item: OptionWithTitle) => {
                if (values && values.length) {
                  setValue([...values, item[valueField]]);
                } else {
                  setValue([item[valueField]]);
                }
              }}
              values={values}
              valueName={valueField}
            />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      {withBorder ? (
        <div
          className={`${styles.multiSelectCell_withBorder} ${
            isMenuOpened ? styles.multiSelectCell_active : ""
          } ${meta.error ? styles.multiSelectCell_error : ""}`}
        >
          {getContent()}
        </div>
      ) : (
        <td
          className={`${styles.multiSelectCell} ${
            isMenuOpened ? styles.multiSelectCell_active : ""
          } ${meta.error ? styles.multiSelectCell_error : ""}`}
        >
          {getContent()}
        </td>
      )}
    </>
  );
};

export default SelectMultiTable;
