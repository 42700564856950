import { useEffect, useState } from "react";
import styles from "./polatiAppVacantRequestFilter.module.scss";
import { Filter } from "stores/PolatiAppModule/types/Filter";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type PolatiAppVacantRequestFilterProps = {
  filters: { [key: string]: Filter };
  getDataWithFilter: (filter: Filter) => void;
};

const PolatiAppVacantRequestFilter = ({
  filters,
  getDataWithFilter
}: PolatiAppVacantRequestFilterProps) => {
  const [selectedFilter, setSelectedFilter] = useState("");

  useEffect(() => {
    Object.values(filters).length
      ? setSelectedFilter(Object.entries(filters)[0][0])
      : null;
  }, []);

  return (
    <div className={styles.filterHeader}>
      {Object.keys(filters)?.length
        ? Object.entries(filters).map((btn) => {
            const handleSelectedFilter = () => {
              selectedFilter !== btn[0] && setSelectedFilter(btn[0]);
              getDataWithFilter(filters[btn[0]]);
            };
            return (
              <>
                <p
                  key={btn[0]}
                  id={`PolatiAppVacantRequestFilter_${btn[0]}`}
                  className={`${
                    selectedFilter === btn[0]
                      ? styles.selectedFilterBtn
                      : styles.filterBtn
                  }`}
                  onClick={handleSelectedFilter}
                >
                  {btn[1].title}
                </p>
                <Chips
                  variation={ChipsVariation.FILTER}
                  key={btn[0]}
                  id={`PolatiAppVacantRequestFilter_${btn[0]}`}
                  className={`${
                    selectedFilter === btn[0]
                      ? styles.selectedFilterBtn
                      : styles.filterBtn
                  }`}
                  onClick={handleSelectedFilter}
                  title={btn[1].title}
                />
              </>
            );
          })
        : null}
    </div>
  );
};

export default PolatiAppVacantRequestFilter;
