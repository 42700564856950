import styles from "./staffOneEditSettingForm.module.scss";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { validationEditSetting } from "./validation";

import addIcon from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";

import RadioButton from "shared/ui/Inputs/RadioButton";
import { Input } from "shared/ui/Inputs/Input";
import Checkbox from "shared/ui/Inputs/Checkbox";
import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import Modal from "shared/ui/Modal";
import { PhoneInput } from "shared/ui/Inputs/PhoneInput";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";
import { getValues } from "shared/utils/helpers/getValues";
import { useParams } from "react-router-dom";
import { diff } from "deep-object-diff";
import { getEntries } from "shared/utils/helpers/getEntries";

type PhoneType = {
  number: number | string | null;
  comment: string | null;
  confirm: number | null;
}[];
type StaffOneEditSettingFormProps = {
  setShowSuccessModal: (arg: boolean) => void;
  setIsOpenForm: (arg: boolean) => void;
};

const StaffOneEditSettingForm = ({
  setShowSuccessModal,
  setIsOpenForm
}: StaffOneEditSettingFormProps) => {
  const { staffOneEditStore } = useStores();
  const { id } = useParams();

  const [contacts, setContacts] = useState<{ phones?: PhoneType }>({
    phones: []
  });
  const [selectedContact, setSelectedContact] = useState<number>(null);
  const [newPhoneIndex, setNewPhoneIndex] = useState<number>(null);

  const [isAddPhone, setIsAddPhone] = useState(false);
  const [isEmpty, setEmpty] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [info, setInfo] = useState<{ action: string; idx: number }>({
    action: "",
    idx: null
  });

  useEffect(() => {
    if (Object.keys(staffOneEditStore.selectedOneForEdit).length) {
      setContacts({
        phones: getValues(staffOneEditStore.selectedOneForEdit.phone)
      });
    }
  }, [staffOneEditStore.selectedOneForEdit]);

  return (
    <>
      <Formik
        initialValues={{
          contacts: contacts,
          selectedPhone: null
        }}
        validateOnBlur
        validateOnChange
        validationSchema={validationEditSetting}
        enableReinitialize
        onSubmit={(values) => {
          // Изменить при подключении апи
          values;
        }}
      >
        {({
          values,
          initialValues,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          dirty,
          isValid
        }) => {
          const newPhone = (action: string) => {
            const objectOfContact: { phones?: PhoneType } = {};
            const arrayOfContacts = values.contacts.phones;

            switch (action) {
              case "add":
                {
                  arrayOfContacts.push({
                    number: "",
                    comment: "",
                    confirm: 0
                  });
                  setNewPhoneIndex(arrayOfContacts.length - 1);
                  setSelectedContact(arrayOfContacts.length - 1);
                  setEmpty(false);
                }
                break;
              case "delete":
                {
                  arrayOfContacts.pop();
                }
                break;
              default:
                break;
            }

            objectOfContact.phones = arrayOfContacts;
            setFieldValue("contacts", objectOfContact);
            setContacts(objectOfContact);
          };
          const handleSendData = (evt) => {
            evt && evt.preventDefault();
            Promise.all([
              staffOneEditStore.resetPassword(
                staffOneEditStore.selectedOneForEdit.id,
                values.selectedPhone
              )
            ]).then(() => {
              setFieldValue("selectedPhone", null);
              setIsOpenForm(false);
              setIsAddPhone(false);
              setShowSuccessModal(true);
              setSelectedContact(null);
            });
          };
          const handleResetData = () => {
            setIsOpenForm(false);
            setIsAddPhone(false);
            setSelectedContact(null);
            setFieldValue("selectedPhone", null);
          };
          const editContact = async ({
            action,
            idx
          }: {
            action: string;
            idx: number;
          }) => {
            const phone = values.contacts.phones[idx];
            const phones = values.contacts.phones;
            const changes = getEntries(
              diff(
                initialValues["contacts"]?.["phones"]?.[idx] || {},
                phones[idx]
              )
            );
            setSelectedContact(null);

            switch (action) {
              case "submit":
                if (changes?.length > 1) {
                  staffOneEditStore.editPhone(
                    "phone",
                    id,
                    idx,
                    "number",
                    phones[idx]?.number,
                    phones[idx]?.comment
                  );
                } else if (changes?.length === 1) {
                  staffOneEditStore.editPhone(
                    "phone",
                    id,
                    idx,
                    changes[0]?.[0],
                    changes[0]?.[1]
                  );
                }
                setEmpty(false);
                break;
              case "edit":
                setSelectedContact(idx);
                break;
              case "delete":
                staffOneEditStore.deletePhone(id, idx);
                break;
              case "cancel":
                for (const key in phone) {
                  setFieldValue(
                    `contacts.phones.${idx}.${key}`,
                    initialValues.contacts.phones[idx][key]
                  );
                  setFieldTouched(`contacts.phones.${idx}.${key}`, false);
                }
                break;
              default:
                break;
            }
          };
          const addPhone = async (...data: [string, number, string]) => {
            const [action] = data;

            if (action === "submit") {
              setSelectedContact(null);
              const phones = values.contacts.phones;
              const newPhone = phones[phones.length - 1];
              staffOneEditStore.addPhone(id, newPhone.number, newPhone.comment);
            } else {
              newPhone("delete");
            }

            setIsAddPhone(false);
          };

          return (
            <Form className={styles.form}>
              <div className={styles.phoneContainer}>
                <div className={styles.phoneContainerContent}>
                  <div>У сотрудника будет сброшен установленный пароль</div>
                  <div>
                    Выберите номер телефона, на который необходимо отправить
                    новый пароль сотрудника
                  </div>
                </div>

                {!staffOneEditStore.isLoadingSettings ? (
                  <>
                    {Object.keys(values.contacts)?.length && (
                      <div className={styles.containerForm}>
                        {values.contacts.phones.map((contact, i) => {
                          return (
                            <ul
                              key={`contact_${i}`}
                              className={
                                isAddPhone && i === newPhoneIndex
                                  ? `${styles.confirmRowAddPhone} ${styles.confirmRowAddPhone_selected}`
                                  : selectedContact === i
                                  ? `${styles.confirmRowPhone} ${styles.confirmRowPhone_selected}`
                                  : styles.confirmRowPhone
                              }
                            >
                              {(isAddPhone && i === newPhoneIndex) ||
                              isEmpty ? null : (
                                <>
                                  <li>
                                    <RadioButton
                                      name="selectedPhone"
                                      value={contact["number"].toString()}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                    />
                                  </li>
                                  <li>
                                    <label
                                      htmlFor={`contacts.phones.${i}.confirm`}
                                      className={styles.confirmLabel}
                                    >
                                      Подтвержд.
                                    </label>
                                    <Checkbox
                                      name={`contacts.phones.${i}.confirm`}
                                      id={`contacts.phones.${i}.confirm`}
                                      disabled
                                    />
                                  </li>
                                </>
                              )}
                              <li>
                                <PhoneInput
                                  name={`contacts.phones.${i}.number`}
                                  label={
                                    staffOneEditStore.staffTableCols["phone"][
                                      "title"
                                    ]
                                  }
                                  notEditable={selectedContact !== i}
                                  required={selectedContact === i}
                                  fromAppSettings
                                />
                              </li>
                              <li>
                                <Input
                                  name={`contacts.phones.${i}.comment`}
                                  label={
                                    staffOneEditStore.staffTableCols["comment"][
                                      "title"
                                    ]
                                  }
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldTouched(
                                      `contacts.phones.${i}.comment`
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  disabled={selectedContact !== i}
                                />
                              </li>
                              <li>
                                {(isAddPhone && newPhoneIndex === i) ||
                                isEmpty ? (
                                  <ButtonsGroupForEdit
                                    id={`contact_${i}`}
                                    idx={i}
                                    btns={[
                                      {
                                        action: "submit",
                                        type: "button",
                                        icon: "bigcheck",
                                        color:
                                          !isValid || !dirty
                                            ? "bw-gray3"
                                            : "blue-lazure",
                                        disabled: !isValid || !dirty
                                      },
                                      {
                                        action: "cancel",
                                        type: "button",
                                        icon: "iconclose",
                                        color: "bw-gray5",
                                        disabled: false
                                      }
                                    ]}
                                    onClick={addPhone}
                                  />
                                ) : (
                                  <ButtonsGroupForEdit
                                    id={`contact_${i}`}
                                    idx={i}
                                    btns={
                                      selectedContact === i
                                        ? [
                                            {
                                              action: "submit",
                                              type: "button",
                                              icon: "bigcheck",
                                              color:
                                                !isValid || !dirty
                                                  ? "bw-gray3"
                                                  : "blue-lazure",
                                              disabled: !isValid || !dirty
                                            },
                                            {
                                              action: "cancel",
                                              type: "button",
                                              icon: "iconclose",
                                              color: "blue-lazure",
                                              disabled: false
                                            },
                                            {
                                              action: "delete",
                                              type: "button",
                                              icon: "iconbasket",
                                              color: "bw-gray5",
                                              disabled: true
                                            }
                                          ]
                                        : [
                                            {
                                              action: "edit",
                                              type: "button",
                                              icon: "iconedit",
                                              color: "bw-gray5",
                                              disabled: false
                                            },
                                            {
                                              action: "delete",
                                              type: "button",
                                              icon: "iconbasket",
                                              color: "bw-gray5",
                                              disabled: false
                                            }
                                          ]
                                    }
                                    onClick={(action, idx) => {
                                      if (isAddPhone) {
                                        setInfo({ action, idx });
                                        setShowDeleteModal(true);
                                      } else editContact({ action, idx });
                                    }}
                                  />
                                )}
                              </li>
                            </ul>
                          );
                        })}
                        {!isAddPhone && !isEmpty ? (
                          <button
                            type="button"
                            className={styles.buttonAddNumber}
                            onClick={() => {
                              setIsAddPhone(true);
                              newPhone("add");
                            }}
                          >
                            <p className={styles.buttonAddNumber__text}>
                              Добавить номер
                            </p>
                            <img
                              src={addIcon}
                              className={styles.buttonAddNumber__icon}
                            />
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <p className={styles.loading}>Пожалуйста, подождите</p>
                )}

                <div className={styles.buttonsWrapper}>
                  <Button
                    theme={ButtonTheme.SECONDARY}
                    size={ButtonSize.M}
                    disabled={values.selectedPhone === null}
                    onClick={handleSendData}
                  >
                    Отправить
                  </Button>
                  <Button
                    theme={ButtonTheme.CANCELLED}
                    size={ButtonSize.M}
                    onClick={handleResetData}
                  >
                    Отмена
                  </Button>
                </div>
              </div>
              <Modal
                type="clarificationForFormWindows"
                show={showDeleteModal}
                onHide={() => {
                  setShowDeleteModal(false);
                }}
                title="Вы уверены что хотите закрыть окно добавления номера без сохранения данных?"
                btnWithCrossTitle="Закрыть без сохранения"
                btnWithCrossOnClick={() => {
                  newPhone("delete");
                  setIsAddPhone(false);
                  setShowDeleteModal(false);
                  editContact(info);
                }}
                greyBtnOnClick={() => {
                  setShowDeleteModal(false);
                }}
                greyBtnTitle="Отмена"
                blueBtnTitle="Сохранить и закрыть"
                blueBtnOnClick={() => {
                  setIsAddPhone(true);
                  newPhone("add");
                  setShowDeleteModal(false);
                }}
                btnWithCrossImg
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default observer(StaffOneEditSettingForm);
