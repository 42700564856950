import styles from "./staffTableBody.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

import { getEntries } from "shared/utils/helpers/getEntries";
import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";
import StaffTablePresave from "./StaffTablePresave";
import StaffTableTotal from "./StaffTableTotal";
import { getValues } from "shared/utils/helpers/getValues";

const StaffTableBody = () => {
  const { aregisterOneStore, menuStore } = useStores();
  const { id } = useParams();

  return (
    <tbody className={styles.tbody}>
      {getValues(aregisterOneStore.presavesStaffList[id])
        .sort((a, b) =>
          a.main.staff_info.fio.localeCompare(b.main.staff_info.fio)
        )
        .map((staff, i) =>
          getValues(staff.main?.staff_info?.used_company).map(
            (company, idx) => (
              <tr
                key={`${staff.main.staff_info.staff_id}_${company}`}
                className={classNames("", {
                  [styles.oddRow]: i % 2 && idx === 0,
                  [styles.oddRowWithoutNum]: i % 2 && idx !== 0,
                  [styles.evenRow]: !(i % 2) && idx === 0,
                  [styles.evenRowWithoutNum]: !(i % 2) && idx !== 0,
                  [styles.highlightedCompanyStaff]:
                    aregisterOneStore.focusedCompanyStaff[id] ===
                    `${staff.main.staff_info.staff_id}_${company}`,
                  [styles.firstCompanyRow]: idx === 0,
                  [styles.anotherCompanyRow]: idx > 0
                })}
              >
                {/* Отображение данных из почасовок */}
                {idx === 0 ? (
                  <td
                    className={styles.num}
                    rowSpan={
                      getEntries(staff.main?.staff_info?.used_company).length
                    }
                  >
                    {i + 1}
                  </td>
                ) : null}
                {getKeys(staff).map((group) => {
                  const staff_id = staff.main.staff_info.staff_id;
                  if (group === "main")
                    return (
                      <td
                        key={group}
                        className={classNames(styles.main, {
                          // 28 - ширина столбца num, таблица прилипает к левой границе окна
                          [styles.tdShadowRight]:
                            menuStore.scroll.scrollLeft > 28
                        })}
                      >
                        <div className={styles.staff}>
                          {idx === 0 && (
                            <div className={styles.staffFioContainer}>
                              <div className={styles.staffTn}>
                                {staff[group].staff_info?.tn}
                              </div>
                              <Link
                                className={styles.link}
                                to={`/staff/id=${staff_id}`}
                                target="_blank"
                                id={`AregisterOneStaffTable_navigateToStaff_${staff_id}`}
                              >
                                {staff[group].staff_info?.fio}
                              </Link>
                            </div>
                          )}
                          <div
                            className={`${styles.staffCompany} ${
                              styles[
                                `staffCompany_${aregisterOneStore.selects.companies?.[company]?.color}`
                              ]
                            }`}
                          >
                            {
                              aregisterOneStore.selects?.companies?.[company]
                                ?.title
                            }
                          </div>
                        </div>
                      </td>
                    );
                  else if (group === "group_by_presave")
                    return (
                      <StaffTablePresave
                        staff_id={staff_id}
                        staff={staff}
                        company={company}
                        company_idx={idx}
                        key={group}
                      />
                    );
                  else if (group === "total")
                    return (
                      <StaffTableTotal
                        staff={staff}
                        company_idx={idx}
                        company_id={company}
                        key={group}
                      />
                    );
                })}
              </tr>
            )
          )
        )}
    </tbody>
  );
};

export default observer(StaffTableBody);
