import { useParams } from "react-router-dom";
import { useStores } from "stores";
import styles from "./paymentListTabs.module.scss";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import { observer } from "mobx-react-lite";
import { getValues } from "shared/utils/helpers/getValues";
import StaffOneSalaryFieldsRecordsInfo from "../../RecordsInfo";

const PaymentListTabs = () => {
  const { staffOneSalaryStore } = useStores();
  const { id } = useParams();

  //Массив компаний, которые присутствуют в таблице "Ведомости сотрудника"
  const availableCompanyForUid = getValues(
    staffOneSalaryStore.paymentList[id]
  ).map((payment) => payment.object?.company_id);

  const handleSetActivePaymentType = (value: string) =>
    staffOneSalaryStore.setSelectedTypeForPaymentList(id, value);

  const handleSetActiveCompany = (value: string) =>
    staffOneSalaryStore.setSelectedCompanyForPaymentList(id, value);

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabs}>
        <ButtonsTabs
          selectedTab={staffOneSalaryStore.selectedTypeForPaymentList[id]}
          tabs={[
            {
              title: "Все ведомости",
              id: "all"
            },
            ...getValues(staffOneSalaryStore.paymentListTypes)
          ]}
          changeActiveTab={handleSetActivePaymentType}
          title="Тип ведомости"
        />
      </div>
      <div className={styles.tabs}>
        <ButtonsTabs
          selectedTab={staffOneSalaryStore.selectedCompanyForPaymentList[id]}
          tabs={[
            {
              title: "Все компании",
              id: "all"
            },
            ...getValues(staffOneSalaryStore.companiesDict).filter((company) =>
              availableCompanyForUid.includes(company["id"])
            )
          ]}
          changeActiveTab={handleSetActiveCompany}
          title="Компании"
        />
      </div>
      <StaffOneSalaryFieldsRecordsInfo name="paymentlist" />
    </div>
  );
};

export default observer(PaymentListTabs);
