import styles from "./staffOneSafetyWork.module.scss";

import Tooltip from "shared/ui/Tooltip";

import { Certificate } from "stores/StaffModule/types/SafetyWork";
import { Company } from "stores/StaffModule/types/Company";

import { diff } from "deep-object-diff";
import StatusIcon from "shared/ui/StatusIcon";
import { getUnits } from "shared/utils/helpers/getUnits";
import { calculateDays } from "shared/utils/helpers/calculateDays";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

const pastelRed = "accent-pastel-red";
const yellow = "accent-yellow";
const normalRed = "accent-red";
const normalOrange = "accent-orange";

type TableCol = {
  color: string;
  date_end: string | JSX.Element;
  date_start: string | JSX.Element;
  id: string;
  kind_title: string;
  type_title: string | JSX.Element;
};

export const getPositionsForOneOfStaff = (
  certificates: {
    [key: string]: { [key: string]: { [key: string]: Certificate } };
  },
  companies: { [key: string]: Company },
  last_position?: (arg: boolean) => void
): { [key: string]: { [key: string]: string } } => {
  const positions: {
    [company_id: string]: { [position_value: string]: string };
  } = {};
  const allPositionsFromEmployments: {
    [company_id: string]: { [position_value: string]: string };
  } = {};

  certificates &&
    Object.keys(certificates).forEach((company) => {
      positions[company] = {};
      Object.keys(certificates[company]).forEach((position) => {
        positions[company][position] = null;
      });
    });

  Object.entries(companies).forEach(([company_id, company_data]) => {
    company_data?.employments &&
      Object.values(company_data.employments).length &&
      Object.values(company_data.employments).forEach((position) => {
        if (
          position.position?.value &&
          positions[company_id] &&
          !positions[company_id][position.position.value]
        ) {
          positions[company_id][position.position.value] =
            position.position_title || "Должность не указана";
        }

        if (last_position) {
          if (!allPositionsFromEmployments[company_id]) {
            allPositionsFromEmployments[company_id] = {};
          }

          if (position.position?.value) {
            allPositionsFromEmployments[company_id][position.position.value] =
              position.position_title || "Должность не указана";
          }
        }
      });
  });

  if (last_position) {
    last_position(
      Object.values(diff(positions, allPositionsFromEmployments)).length
        ? true
        : false
    );
  }

  return positions;
};

export const getTableArray = (
  position: string,
  activePosition: string,
  certificatesList: { [key: string]: Certificate }
): TableCol[] => {
  const table: TableCol[] = [];
  if (position === activePosition && Object.values(certificatesList).length) {
    Object.values(certificatesList).forEach((certificate) => {
      let color = "",
        icon: JSX.Element | null = null,
        tooltipStyles: {
          text: string;
          color: string;
          backgroundColor: string;
        } = {
          text: "",
          color: "",
          backgroundColor: ""
        };

      if (!certificate.date_start || !certificate.date_end) {
        color = "red";
        icon = <StatusIcon icon="attention" color="danger" />;
        tooltipStyles = {
          color: normalRed,
          backgroundColor: pastelRed,
          text: "Нет данных по документу."
        };
      } else {
        switch (certificate.tr) {
          case "tr-red":
            color = "red";
            icon = <StatusIcon icon="attention" color="danger" />;
            tooltipStyles = {
              color: normalRed,
              backgroundColor: pastelRed,
              text: "Истёк срок действия документа."
            };
            break;
          case "tr-warning":
            color = "warning";
            icon = <StatusIcon icon="hourglass" color="warning" />;
            tooltipStyles = {
              color: normalOrange,
              backgroundColor: yellow,
              text: `Через ${calculateDays(new Date(), certificate.date_end)}
            ${getUnits(
              calculateDays(new Date(), certificate.date_end),
              "день",
              "дня",
              "дней"
            )} истекает срок действия документа`
            };
            break;
          default:
            break;
        }
      }

      table.push({
        id: certificate.id,
        color: color,
        kind_title: certificate.kind_title,
        type_title:
          icon && certificate.date_start && certificate.date_end ? (
            <div className={styles.typeWithIcon}>
              <div>{certificate.type_title}</div>
              <div className={styles.tooltip_forTypeWithIcon}>
                <Tooltip {...tooltipStyles} withoutFlip>
                  <span>{icon}</span>
                </Tooltip>
              </div>
            </div>
          ) : (
            certificate.type_title
          ),
        date_start: icon ? (
          <div
            className={
              certificate.date_start ? styles.tooltip : styles.tooltip_forIcon
            }
          >
            <Tooltip {...tooltipStyles} withoutFlip>
              <>
                {certificate.date_start
                  ? getFormattedDate(certificate.date_start)
                  : icon}
              </>
            </Tooltip>
          </div>
        ) : (
          getFormattedDate(certificate.date_start)
        ),
        date_end: icon ? (
          <div
            className={
              certificate.date_end ? styles.tooltip : styles.tooltip_forIcon
            }
          >
            <Tooltip {...tooltipStyles} withoutFlip>
              <span>
                {certificate.date_end
                  ? getFormattedDate(certificate.date_end)
                  : icon}
              </span>
            </Tooltip>
          </div>
        ) : (
          getFormattedDate(certificate.date_end)
        )
      });
    });
  }

  return table;
};
