import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import Switch from "shared/ui/Inputs/Switch";
import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import { Input } from "shared/ui/Inputs/Input";
import Checkbox from "shared/ui/Inputs/Checkbox";

import styles from "./buildlingCondition.module.scss";

import iconAddActive from "shared/assets/images/mainIcons/iconAdd/iconAddActive.svg";
import iconAddDisabled from "shared/assets/images/mainIcons/iconAdd/iconAddDisabled.svg";

import { FormikErrors, useFormikContext } from "formik";
import { StatusType } from "stores/StaffModule/staffStatus";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type openedSubMenu = {
  staffFields: boolean;
  states: boolean;
};

type BuildingConditionProps = {
  id: string;
  values: StatusType;
  initialValues: StatusType;
  errors: FormikErrors<StatusType>;
  openedSubMenus: openedSubMenu;
  setOpenedSubMenus: (arg: openedSubMenu) => void;
};

const BuildingCondition = ({
  id,
  values,
  initialValues,
  errors,
  openedSubMenus,
  setOpenedSubMenus
}: BuildingConditionProps) => {
  const { staffStatusStore } = useStores();

  const { setFieldValue, handleChange, handleBlur, setFieldTouched } =
    useFormikContext();
  const [focusedLine, setFocusedLine] = useState("");
  const prevEditLine = useRef("");

  const title = "Внешние состояния сотрудника";

  useEffect(() => {
    setFieldValue("custom.buidling_condition.active", 0);
    values["statesList"].forEach((state, i) => {
      if (!values["statesList"][i]["checked"]) {
        setFieldValue(`statesList.${i}.checked`, false);
      }
    });
    return () => {
      setOpenedSubMenus({
        staffFields: false,
        states: false
      });
      setFocusedLine("");
    };
  }, [id]);

  useEffect(() => {
    prevEditLine.current = focusedLine;
  }, [focusedLine]);

  const clearNewLine = () => {
    setFieldValue("custom.buidling_condition.i.long", "");
    setFieldValue("custom.buidling_condition.i.short", "");
    setFieldTouched("custom.buidling_condition.i.long", false);
    setFieldTouched("custom.buidling_condition.i.short", false);
  };

  const onClick = (...data: [string, number, string]) => {
    const [action, , stateId] = data;
    switch (action) {
      case "edit":
        {
          setFocusedLine(stateId);
          if (stateId === prevEditLine.current) return;
          clearPrevValue(prevEditLine.current);
        }
        break;
      case "add":
        {
          const newVal = values["custom"]["buidling_condition"]["i"];
          newVal.checked = false;
          staffStatusStore.addNewState(newVal);

          setFieldValue(`custom.buidling_condition.i.long`, "");
          setFieldValue(`custom.buidling_condition.i.short`, "");
          setFieldTouched(`custom.buidling_condition.i.long`, false);
          setFieldTouched(`custom.buidling_condition.i.short`, false);
        }
        break;
      case "cancel": {
        setFocusedLine("");
        clearPrevValue(stateId);
      }
    }
  };

  const modifyStatesList = (
    checked: boolean,
    item: { short: string; long: string; checked: boolean }
  ) => {
    if (checked) {
      item.checked = true;
      staffStatusStore.toggleExistingState("add", item);
    }
    if (!checked) {
      item.checked = false;
      staffStatusStore.toggleExistingState("remove", item);
    }
  };

  const clearPrevValue = (prevId: string) => {
    if (prevId !== "" && prevId !== "i" && prevId == focusedLine) {
      setFieldValue(
        `statesList.${prevId}.long`,
        initialValues.statesList[Number(prevId)].long
      );
      setFieldValue(
        `statesList.${prevId}.short`,
        initialValues.statesList[Number(prevId)].short
      );
      setFieldTouched(`statesList.${prevId}.long`, false);
      setFieldTouched(`statesList.${prevId}.short`, false);
    }
    if (prevId === "i") {
      clearNewLine();
    }
  };

  return (
    <section>
      <p>{title}</p>
      <label className={styles.label}>
        <Switch
          name="custom.buidling_condition.active"
          onChange={() => {
            setFieldTouched("custom.buidling_condition.active");
          }}
        />
        Состояния сотрудника на объекте доступны
      </label>
      <div>
        {values["custom"]["buidling_condition"]["active"] ? (
          <div
            className={`${
              openedSubMenus.states
                ? styles.valuesContainer_focused
                : styles.valuesContainer
            } ${
              values["custom"]["buidling_condition"]["fullList"].every(
                ({ checked }) => checked === false
              ) || !values["custom"]["buidling_condition"]["fullList"]
                ? styles.noValues
                : ""
            }`}
          >
            <div className="d-flex flex-wrap gap-2">
              {values["custom"]["buidling_condition"]["fullList"].map(
                ({ short, checked }) => {
                  if (checked) {
                    return (
                      <Chips
                        variation={ChipsVariation.MAIN}
                        key={short}
                        className={styles.chip}
                        title={short}
                      />
                    );
                  }
                }
              )}
            </div>
            <div
              className={styles.iconShowContainer}
              onClick={() =>
                setOpenedSubMenus({
                  staffFields: false,
                  states: !openedSubMenus.states
                })
              }
            >
              <img
                src={openedSubMenus.states ? iconAddActive : iconAddDisabled}
                alt="show"
              />
            </div>

            {openedSubMenus.states ? (
              <div className={styles.boardContainer}>
                <div className={styles.boardWrapper}>
                  <p className={styles.title}>{title}</p>
                  <div
                    className={`${styles.line_new} ${
                      focusedLine === "new" ? styles.line_focused : ""
                    }`}
                  >
                    <div className={styles.state}>
                      <Input
                        label="Состояние"
                        name={`custom.buidling_condition.i.long`}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldTouched(`custom.buidling_condition.i.long`);
                        }}
                        onBlur={handleBlur}
                        onClick={() => {
                          setFocusedLine("i");
                          if (prevEditLine.current === "i") return;
                          clearPrevValue(prevEditLine.current);
                        }}
                      />
                    </div>
                    <Input
                      label="Сокращённо"
                      name={`custom.buidling_condition.i.short`}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldTouched(`custom.buidling_condition.i.short`);
                      }}
                      onBlur={handleBlur}
                      onClick={() => {
                        setFocusedLine("i");
                        if (prevEditLine.current === "i") return;
                        clearPrevValue(prevEditLine.current);
                      }}
                    />
                    <div>
                      <ButtonsGroupForEdit
                        btns={[
                          {
                            action: "add",
                            type: "button",
                            icon: "iconadd",
                            color: "",
                            disabled:
                              !!errors["custom"]?.["buidling_condition"]?.["i"]
                                ?.long ||
                              !!errors["custom"]?.["buidling_condition"]?.["i"]
                                ?.short ||
                              !values["custom"]?.["buidling_condition"]?.["i"]
                          }
                        ]}
                        onClick={onClick}
                      />
                    </div>
                  </div>

                  <div className={styles.list}>
                    {values["statesList"].map((item, i) => {
                      const id = String(i);
                      return (
                        <div
                          key={item.short}
                          className={`
                            ${styles.line} ${
                            id === focusedLine ? styles.line_focused : ""
                          }`}
                        >
                          <div>
                            <Checkbox
                              name={`statesList.${i}.checked`}
                              onChange={(e) => {
                                modifyStatesList(
                                  (e.target as HTMLInputElement).checked,
                                  item
                                );
                              }}
                            />
                          </div>
                          <div className={styles.state}>
                            <Input
                              name={`statesList.${i}.long`}
                              label="Состояние"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldTouched(`statesList.${i}.long`);
                              }}
                              onBlur={handleBlur}
                              disabled={id !== focusedLine}
                            />
                          </div>
                          <Input
                            name={`statesList.${i}.short`}
                            label="Сокращённо"
                            onChange={(e) => {
                              setFieldTouched(`statesList.${i}.short`);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            disabled={id !== focusedLine}
                          />
                          <div className={styles.btnsGroup}>
                            {/* При разработке исправить цвета кнопок */}
                            <ButtonsGroupForEdit
                              id={id}
                              btns={
                                id === focusedLine
                                  ? [
                                      {
                                        action: "submit",
                                        type: "button",
                                        icon: "bigcheck",
                                        color: "",
                                        disabled: true
                                      },
                                      {
                                        action: "cancel",
                                        type: "button",
                                        icon: "iconclose",
                                        color: "",
                                        disabled: false
                                      }
                                    ]
                                  : [
                                      {
                                        action: "edit",
                                        type: "button",
                                        icon: "iconedit",
                                        color: "",
                                        disabled: false
                                      },
                                      {
                                        action: "delete",
                                        type: "button",
                                        icon: "iconbasket",
                                        color: "",
                                        disabled: true
                                      }
                                    ]
                              }
                              onClick={onClick}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default observer(BuildingCondition);
