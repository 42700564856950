import styles from "./staffOneStatusesGraph.module.scss";
import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { format, addMonths, startOfMonth } from "date-fns";

import { useStores } from "stores/index";
// import { Link } from "react-router-dom";

import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";

import StaffOneStatusesWindow from "../StaffOneStatusesWindow";
import StaffOneStatusesGraphTooltip from "./StaffOneStatusesGraphTooltip";
import StaffOneStatusesGraphOneStatus from "./StaffOneStatusesGraphOneStatus";
import StaffOneStatusesGraphVacSubStatus from "./StaffOneStatusesGraphVacSubStatus";

import Tooltip from "shared/ui/Tooltip";
import ScrollButton from "shared/ui/Buttons/ScrollButton";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import StaffOneStatusesGraphHeader from "./StaffOneStatusesGraphHeader";
import StaffOneStatusesGraphTodaybtn from "./StaffOneStatusesGraphTodaybtn";

import { fileUrl } from "stores/utils/consts";
import { classNames } from "shared/utils/helpers/classNames";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

const StaffOneStatusesGraph = () => {
  const { staffOneStatusesStore, menuStore } = useStores();
  const tableRef = useRef<HTMLTableElement>();

  useEffect(() => {
    staffOneStatusesStore.setStatusesForGraph();
  }, [
    staffOneStatusesStore.filteredCompany,
    staffOneStatusesStore.isReversedTable
  ]);

  return staffOneStatusesStore.eventData &&
    Object.values(staffOneStatusesStore.eventData).length &&
    (staffOneStatusesStore.filteredCompany === "all" ||
      (staffOneStatusesStore.eventData[staffOneStatusesStore.filteredCompany] &&
        staffOneStatusesStore.eventData[staffOneStatusesStore.filteredCompany]
          .size)) &&
    Object.values(staffOneStatusesStore.statusesCols).length ? (
    <div className={styles.tableWrapper}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StaffOneStatusesGraphTodaybtn />
      </ErrorBoundary>
      <div className={styles.scrollButton}>
        <ScrollButton
          tableRef={tableRef}
          leftButtonClass={styles.leftButton}
          rightButtonClass={styles.rightButton}
        >
          <Table className={styles.table} ref={tableRef}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <StaffOneStatusesGraphHeader />
            </ErrorBoundary>

            {Object.entries(staffOneStatusesStore.eventData).map(
              ([companyId, company]) => {
                return (
                  <tbody key={companyId}>
                    {[...company].map(
                      ([buildingId, building], buildingIndex) => {
                        // "хвосты" статусов которые в другом месяце
                        const tails: { [key: string]: number } = {};
                        // серединки статусов
                        const middles: { [key: string]: number } = {};
                        return (
                          <React.Fragment key={buildingId}>
                            <tr
                              className={styles.buildingRow}
                              key={`buildingRow_${buildingId}`}
                            >
                              {!buildingIndex && (
                                <td
                                  rowSpan={Object.values(company).length * 2}
                                  className={classNames(styles.companyName, {
                                    [styles.companyName_shadow]:
                                      menuStore.scroll.left
                                  })}
                                >
                                  <div>
                                    {building.firstStatus.company_title}
                                  </div>
                                </td>
                              )}
                              <td
                                colSpan={staffOneStatusesStore.monthTotalCols}
                              >
                                <div className={styles.building}>
                                  <a
                                    className={styles.buildingTitle}
                                    href={`${fileUrl}/crm/building/?id=${buildingId}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {building.title}
                                  </a>
                                  {/* <Link
                                    className={styles.buildingTitle}
                                    to={`../../building/id=${buildingId}/timesheet`}
                                    target="_blank"
                                  >
                                    {building.title}
                                  </Link> */}
                                  <img
                                    src={Buildings}
                                    className={styles.citiesImg}
                                  />
                                  <div className={styles.buildingStatusDate}>
                                    {getFormattedDate(
                                      building.firstStatus?.[
                                        building.isFirstSubVacation
                                          ? "sub_start_date"
                                          : "event_start"
                                      ]
                                    )}{" "}
                                    -{" "}
                                    {building.isActiveStatus
                                      ? "по настоящее время"
                                      : getFormattedDate(
                                          building.lastStatus?.[
                                            building.isLastSubVaсation
                                              ? "sub_end_date"
                                              : building.lastStatus["event_end"]
                                              ? "event_end"
                                              : "event_start"
                                          ]
                                        )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr
                              className={styles.buildingRowStatuses}
                              key={`statusBuilding_${buildingId}`}
                            >
                              {staffOneStatusesStore.monthList.map((month) => {
                                const statuses = building.months[month] || [];
                                // разница между статусами на объекте и общим количеством ячеек для этого месяца
                                const diff =
                                  statuses.length === 1 || middles[month]
                                    ? 0
                                    : staffOneStatusesStore.monthStatusesCount[
                                        month
                                      ] -
                                      statuses.length -
                                      (tails[month] || 0);

                                const statusCells = statuses.map(
                                  (status, ind) => {
                                    const isSubDate = Boolean(
                                      staffOneStatusesStore.statusesParams[
                                        status.type
                                      ].custom.sub_date
                                    );
                                    const isSubVacation = Boolean(
                                      status["sub_start_date"] &&
                                        status["sub_end_date"] &&
                                        isSubDate
                                    );

                                    let start = status.event_start;
                                    let end = status.event_end;
                                    if (staffOneStatusesStore.isReversedTable) {
                                      [start, end] = [end, start];
                                    }

                                    let colSpan = 1;
                                    if (statuses.length === 1) {
                                      colSpan =
                                        staffOneStatusesStore
                                          .monthStatusesCount[month] -
                                        (tails[month] || 0);
                                    }
                                    if (status.months >= 2) {
                                      colSpan++;
                                    }
                                    for (
                                      let monthNum = 1;
                                      monthNum < status.months - 1;
                                      monthNum++
                                    ) {
                                      const currentMonth = format(
                                        startOfMonth(
                                          addMonths(
                                            new Date(end),
                                            staffOneStatusesStore.isReversedTable
                                              ? monthNum
                                              : -monthNum
                                          )
                                        ),
                                        "yyyy-MM-dd"
                                      );
                                      const span =
                                        staffOneStatusesStore
                                          .monthStatusesCount[currentMonth] ||
                                        0;

                                      if (span) {
                                        middles[currentMonth] = 1;
                                      }
                                      colSpan += span;
                                    }
                                    if (status.months > 1) {
                                      tails[
                                        format(
                                          startOfMonth(new Date(start)),
                                          "yyyy-MM-dd"
                                        )
                                      ] = 1;
                                    }

                                    if (!isSubVacation) {
                                      return (
                                        <td
                                          key={status["id"]}
                                          colSpan={colSpan}
                                          id={status.id}
                                        >
                                          <Tooltip
                                            text={
                                              <StaffOneStatusesGraphTooltip
                                                status={status}
                                                isSubDate={isSubDate}
                                                ind={ind}
                                                buildingIndex={buildingIndex}
                                                isSubVacation={isSubVacation}
                                              />
                                            }
                                            placement="bottom"
                                          >
                                            <StaffOneStatusesGraphOneStatus
                                              status={status}
                                              isSubDate={isSubDate}
                                            />
                                          </Tooltip>
                                          {staffOneStatusesStore.selectedRow ===
                                          status["id"] ? (
                                            <div
                                              className={styles.windowContainer}
                                            >
                                              <div className={styles.window}>
                                                <StaffOneStatusesWindow
                                                  selectedStatus={status}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          {staffOneStatusesStore
                                            .includedStatuses[status.id] &&
                                            staffOneStatusesStore.includedStatuses[
                                              status.id
                                            ].map((includedStatus) => {
                                              //статус, который выводится под родителем (в которого он входит по датам)
                                              const isSubDateIncluded = Boolean(
                                                staffOneStatusesStore
                                                  .statusesParams[
                                                  includedStatus.type
                                                ].custom.sub_date
                                              );
                                              const isSubVacationIncluded =
                                                Boolean(
                                                  includedStatus[
                                                    "sub_start_date"
                                                  ] &&
                                                    includedStatus[
                                                      "sub_end_date"
                                                    ] &&
                                                    isSubDateIncluded
                                                );

                                              return (
                                                <React.Fragment
                                                  key={includedStatus["id"]}
                                                >
                                                  <Tooltip
                                                    text={
                                                      <StaffOneStatusesGraphTooltip
                                                        status={includedStatus}
                                                        isSubDate={
                                                          isSubDateIncluded
                                                        }
                                                        ind={ind}
                                                        buildingIndex={
                                                          buildingIndex
                                                        }
                                                        isSubVacation={
                                                          isSubVacationIncluded
                                                        }
                                                      />
                                                    }
                                                    placement="bottom"
                                                  >
                                                    {!isSubVacationIncluded ? (
                                                      <StaffOneStatusesGraphOneStatus
                                                        status={includedStatus}
                                                        isSubDate={
                                                          isSubDateIncluded
                                                        }
                                                      />
                                                    ) : (
                                                      <StaffOneStatusesGraphVacSubStatus
                                                        status={includedStatus}
                                                        isSubDate={
                                                          isSubDateIncluded
                                                        }
                                                      />
                                                    )}
                                                  </Tooltip>
                                                  {staffOneStatusesStore.selectedRow ===
                                                  includedStatus["id"] ? (
                                                    <div
                                                      className={
                                                        styles.windowContainer
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          styles.window
                                                        }
                                                      >
                                                        <StaffOneStatusesWindow
                                                          selectedStatus={
                                                            includedStatus
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </React.Fragment>
                                              );
                                            })}
                                        </td>
                                      );
                                    } else {
                                      return (
                                        <td
                                          key={status["id"]}
                                          colSpan={colSpan}
                                          id={status.id}
                                        >
                                          <Tooltip
                                            text={
                                              <StaffOneStatusesGraphTooltip
                                                status={status}
                                                isSubDate={isSubDate}
                                                ind={ind}
                                                buildingIndex={buildingIndex}
                                                isSubVacation={isSubVacation}
                                              />
                                            }
                                            placement="bottom"
                                          >
                                            <StaffOneStatusesGraphVacSubStatus
                                              status={status}
                                              isSubDate={isSubDate}
                                            />
                                          </Tooltip>
                                          {staffOneStatusesStore.selectedRow ===
                                          status["id"] ? (
                                            <div
                                              className={styles.windowContainer}
                                            >
                                              <div className={styles.window}>
                                                <StaffOneStatusesWindow
                                                  selectedStatus={status}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      );
                                    }
                                  }
                                );
                                if (diff > 0) {
                                  statusCells.unshift(
                                    <td
                                      colSpan={diff}
                                      className={styles.diff}
                                      key={`${buildingId}_${month}`}
                                    ></td>
                                  );
                                }
                                return statusCells;
                              })}
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )}
                  </tbody>
                );
              }
            )}
            <tfoot>
              <tr>
                {[...Array(staffOneStatusesStore.monthTotalCols)].map(
                  (_, index) => {
                    // браузеры не уважают colSpan, если нет хотя бы 1 строки,
                    //точно соответствующей количеству столбцов в таблице
                    return (
                      <td key={`empty_${index}`}>
                        <div></div>
                      </td>
                    );
                  }
                )}
              </tr>
            </tfoot>
          </Table>
        </ScrollButton>
      </div>
    </div>
  ) : (
    <div className={styles.withoutStatuses}>
      Сотрудника не было ни на одном объекте
    </div>
  );
};

export default observer(StaffOneStatusesGraph);
