import styles from "./staffImportButtonsDuplicateWindow.module.scss";
import { useStores } from "stores";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import FormWindow from "shared/ui/FormWindow";
import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { useFormikContext } from "formik";
import { StaffImportTableForFormik } from "stores/StaffModule/types/StaffImportTable";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type StaffImportButtonsDuplicateWindowProps = {
  setIsOpenedPanel: (value: boolean) => void;
};

const StaffImportButtonsDuplicateWindow = ({
  setIsOpenedPanel
}: StaffImportButtonsDuplicateWindowProps) => {
  const { staffImportStore } = useStores();
  const [selectedDuplicateCols, setSelectedDuplicateCols] = useState(
    staffImportStore.selectedDuplicateCols
  );

  const { setFieldTouched } = useFormikContext<StaffImportTableForFormik>();

  return (
    <div className={styles.windowContainer}>
      <div className={styles.window}>
        <FormWindow
          title="Выбор полей для проверки на дубли"
          setOpenWindow={() => setIsOpenedPanel(false)}
          withoutSendData
          saveBtnTitle="Готово"
          saveBtnOnClickFunc={() => {
            staffImportStore.setSelectedDuplicateCols(selectedDuplicateCols);
            setIsOpenedPanel(false);
          }}
          addBtnTitle="Отмена"
          addBtnImg={<IconClose />}
          isScroll
        >
          <>
            <div className={styles.selectedColsBlock}>
              <div className={styles.caption}>
                Выберите нужное или удалите лишнее.
              </div>
              <div className={styles.cols}>
                {selectedDuplicateCols.map((title) => {
                  const deleteSelectedDuplicateCols = () => {
                    if (
                      staffImportStore.colsForDuplicateCheck[title][
                        "selectable"
                      ]
                    ) {
                      const selectedDuplicateColsFilter =
                        selectedDuplicateCols.filter((item) => item !== title);
                      setSelectedDuplicateCols(selectedDuplicateColsFilter);
                      setFieldTouched(title);
                    }
                  };
                  return (
                    <Chips
                      key={title}
                      variation={ChipsVariation.COLS}
                      title={
                        staffImportStore.colsForDuplicateCheck[title]["title"]
                      }
                      onClick={deleteSelectedDuplicateCols}
                      onDelete={() => null}
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles.optionList}>
              <ItemsScrollBoard
                options={staffImportStore.colsForDuplicateCheck}
                values={selectedDuplicateCols}
                valueName="name"
                isItemBtnMode
                notSearchable
                addItem={(value) => {
                  setSelectedDuplicateCols([
                    ...selectedDuplicateCols,
                    value["name"] as string
                  ]);

                  setFieldTouched(value["name"] as string);
                }}
              />
            </div>
          </>
        </FormWindow>
      </div>
    </div>
  );
};

export default observer(StaffImportButtonsDuplicateWindow);
