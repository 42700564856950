import styles from "./selectMulti.module.scss";
import { useField, useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";

import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAddDefault.svg";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type SelectMultiProps = {
  name: string;
  isMenuOpened: boolean;
  options: { [key: string]: OptionWithTitle };
  setIsMenuOpened: (isMenuOpened: boolean) => void;
  topContainer?: boolean;
  notSearchable?: boolean;
  required?: boolean;
  label?: string;
  searchPlaceholder?: string;
  valueName?: string;
  onClickSelectedOption?: (arg: string) => void;
  aboveContainer?: boolean;
  bottomContainer?: boolean;
  onClick?: (option: OptionWithTitle) => void;
  withoutButtons?: boolean;
};

const SelectMulti = ({
  name,
  label,
  searchPlaceholder,
  options,
  isMenuOpened,
  setIsMenuOpened,

  topContainer,
  aboveContainer,
  bottomContainer,
  notSearchable,
  required,
  valueName,
  onClickSelectedOption,
  onClick,
  withoutButtons
}: SelectMultiProps) => {
  const [listOptions, setListOptions] = useState<OptionWithTitle[]>([]);
  const { setFieldTouched } = useFormikContext();
  const [field, meta, { setValue }] = useField({
    name,
    required
  });
  const ref = useRef<HTMLDivElement>();
  const valueField = valueName || "newname";
  const values: string[] = Object.values(field.value);

  const getTitleFromOption = (value: string) => {
    let title = "";
    if (options[value]) {
      title = options[value]["title"];
    } else {
      Object.values(options).forEach((option) => {
        if (option[valueField] === value) {
          title = option["title"];
        }
      });
    }
    return title;
  };

  const deleteItem = (item: string) => {
    const itemObj = { title: getTitleFromOption(item) };
    itemObj[valueField] = item;
    setListOptions([...listOptions, itemObj]);

    const newValues = [];
    values.forEach((value) => {
      if (!listOptions[value] && value !== item) {
        newValues.push(value);
      }
    });
    setValue(newValues);
  };

  const updateListOptions = () => {
    if (listOptions.length) {
      const idx = listOptions.findIndex((option) =>
        values.includes(option[valueField] as string)
      );
      if (idx !== -1) {
        const array = [...listOptions];
        array.splice(idx, 1);
        setListOptions(array);
      }
    } else {
      const modifiedOptions: OptionWithTitle[] = [];
      Object.entries(options).map(([key, option]) => {
        if (!values.includes(key)) {
          modifiedOptions.push(option);
        }
      });
      setListOptions(modifiedOptions);
    }
  };

  useEffect(() => {
    updateListOptions();
    ref.current.focus();
  }, [meta.value]);

  const handleOnKeyDown = (
    e: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.key === "Escape" && isMenuOpened) {
      setIsMenuOpened(false);
      e.stopPropagation();
    }
  };

  return (
    <div
      className={`${
        isMenuOpened && !aboveContainer && !bottomContainer
          ? styles.field_focused
          : styles.field
      } ${meta.touched && meta.error ? styles.field_error : ""}`}
    >
      <div
        className={styles.selectContainer}
        onClick={() => {
          if (withoutButtons) {
            setIsMenuOpened(!isMenuOpened);
            if (required) {
              setFieldTouched(field.name);
            }
          }
          return;
        }}
        id={`selectmulti_showMenuWithoutBtn_${name}`}
        onKeyDown={(e) => handleOnKeyDown(e)}
        tabIndex={-1}
        ref={ref}
      >
        <p
          className={`${
            !values.length
              ? `${styles.label} ${required ? styles.label_required : ""} ${
                  required && meta.touched && meta.error
                    ? styles.label_required_error
                    : ""
                }`
              : `${styles.label__small} ${
                  required ? styles.label__small_required : ""
                }`
          }`}
        >
          {label}
        </p>

        <div
          className={`${!label ? "" : styles.field__items_withLabel} ${
            styles.field__items
          }`}
        >
          {values.map((item: string) => {
            const handleDeteleItem = () => !withoutButtons && deleteItem(item);
            return (
              <Chips
                variation={ChipsVariation.MAIN}
                key={item}
                onClick={() =>
                  onClickSelectedOption ? onClickSelectedOption(item) : ""
                }
                id={`selectmulti_chooseOption_${item}`}
                title={getTitleFromOption(item)}
                onDelete={handleDeteleItem}
              />
            );
          })}
        </div>

        {!withoutButtons && (
          <IconAdd
            className={`${isMenuOpened ? styles.iconClose : styles.iconAdd} ${
              values.length ? styles.iconAdd_withItems : ""
            }`}
            onClick={() => {
              setIsMenuOpened(!isMenuOpened);
              if (required) {
                setFieldTouched(field.name);
              }
            }}
            id={`selectmulti_showMenu_${name}`}
            onKeyDown={handleOnKeyDown}
            tabIndex={-1}
          />
        )}
      </div>

      {isMenuOpened ? (
        <div
          className={`${styles.scrollboard} ${
            topContainer
              ? styles.scrollboard_top
              : aboveContainer
              ? styles.scrollboard_above
              : bottomContainer
              ? styles.scrollboard_bottom
              : " "
          } `}
        >
          <ItemsScrollBoard
            options={listOptions}
            addItem={(item: OptionWithTitle) => {
              if (values.length) {
                setValue([...values, item[valueField]]);
              } else {
                setValue([item[valueField]]);
              }
              onClick && onClick(item);
            }}
            values={values}
            searchPlaceholder={searchPlaceholder}
            notSearchable={notSearchable}
            valueName={valueField}
          />
        </div>
      ) : null}

      {meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default SelectMulti;
