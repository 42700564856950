import { classNames } from "shared/utils/helpers/classNames";
import styles from "./button.module.scss";
import { ButtonHTMLAttributes, ReactNode } from "react";

export enum ButtonTheme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  CANCELLED = "cancelled",
  FLOATING = "floating",
  CLEAR = "clear",
  ROUND = "round"
}

export enum ButtonSize {
  S = "size_s",
  M = "size_m",
  L = "size_l"
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  /**
   * Стили, заданные в родителе для кнопки
   */
  className?: string;
  /**
   * Вид кнопки, передаем из enum ButtonTheme
   */
  theme?: ButtonTheme;
  /**
   * Размер кнопки, передаем из enum ButtonSize
   */
  size?: ButtonSize;
}

export const Button = ({
  className,
  children,
  theme = ButtonTheme.PRIMARY,
  size,
  ...otherProps
}: ButtonProps) => {
  const optional: string[] = [className, styles[theme], styles[size]];

  return (
    <button className={classNames(styles.button, {}, optional)} {...otherProps}>
      {children}
    </button>
  );
};
