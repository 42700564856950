import styles from "./checkboxes.module.scss";
import { useEffect, useRef, useState } from "react";
import { useField, useFormikContext } from "formik";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import Checkbox from "shared/ui/Inputs/Checkbox";

import iconAddActive from "shared/assets/images/mainIcons/iconAdd/iconAddActive.svg";
import iconAddDisabled from "shared/assets/images/mainIcons/iconAdd/iconAddDisabled.svg";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type CheckboxesProps = {
  label: string;
  values: string[];
  options: { [index: string]: string };
  isMenuOpened: boolean;
  setIsMenuOpened: (isMenuOpened: boolean) => void;
  required?: boolean;
  top?: boolean;
  name: string;
  disabled?: boolean;
};

const Checkboxes = ({
  label,
  values,
  options,
  isMenuOpened,
  setIsMenuOpened,
  required,
  top,
  name,
  disabled
}: CheckboxesProps) => {
  const [data, setData] = useState<string[]>(values);
  const [field, meta, { setValue }] = useField(name);
  const { setFieldTouched, handleChange } = useFormikContext();
  const ref = useRef<HTMLUListElement>();

  useEffect(() => {
    setData(values);
  }, [values]);

  useOnClickOutside({
    ref,
    handler: (e) => {
      if (e.target["dataset"]["data-type"] === "openedMenuBtn") {
        return;
      } else {
        setIsMenuOpened(false);
      }
    }
  });

  const remove = (item: string) => {
    const idx = data.findIndex((value) => value === item);

    if (idx !== -1) {
      const array = [...data];
      array.splice(idx, 1);
      setData(array);
      setValue(array);
    }
  };

  const labelClassName =
    !data || !data?.length
      ? `${styles.label} ${required ? styles.label_required : ""} ${
          required && meta.error ? styles.label_required_error : ""
        }`
      : `${styles.label_small} ${required ? styles.label_small_required : ""}`;

  return (
    <div
      className={`${
        isMenuOpened ? styles.valuesContainer_focused : styles.valuesContainer
      } ${meta.error ? styles.valuesError : ""} ${
        disabled ? styles.valuesContainer_disabled : ""
      }`}
    >
      <p className={`m-0 ${labelClassName}`}>{label}</p>
      {values && values.length ? (
        <div
          className={`${styles.inputContainer} ${!label ? "mt-0" : "mt-3"} `}
        >
          {data.map((item) => {
            const handleDeleteItem = () => !disabled && remove(item);
            return (
              <Chips
                variation={ChipsVariation.MAIN}
                key={item}
                title={options[item]}
                onDelete={handleDeleteItem}
              />
            );
          })}
        </div>
      ) : null}
      <div
        className={styles.iconShowContainer}
        onClick={() => {
          if (!disabled) {
            setIsMenuOpened(!isMenuOpened);
            if (required) {
              setFieldTouched(field.name);
            }
          }
        }}
        id={`checkboxes_${name}_show`}
        data-type="openedMenuBtn"
      >
        <img
          src={isMenuOpened ? iconAddActive : iconAddDisabled}
          alt="icon"
          data-type="openedMenuBtn"
        />
      </div>
      {isMenuOpened ? (
        <ul
          className={`${styles.list} ${top ? " " + styles.listTop : ""}`}
          ref={ref}
        >
          {options
            ? Object.entries(options).map(([key, value]) => {
                return (
                  <li key={key}>
                    <label className={styles.fieldLabel}>
                      <Checkbox
                        name={field.name}
                        value={key}
                        onChange={(event: React.ChangeEvent) => {
                          !disabled && handleChange(event);
                        }}
                      />
                      {value}
                    </label>
                  </li>
                );
              })
            : null}
        </ul>
      ) : null}

      {meta.error ? <div className={styles.error}>{meta.error}</div> : null}
    </div>
  );
};

export default Checkboxes;
