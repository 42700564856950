import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState } from "react";
import styles from "./staffOneEditFormPhotoAlt.module.scss";
import { fileUrl } from "stores/utils/consts";
import StaffOneEditPhotoAltGallery from "./StaffOneEditPhotoAltGallery";
import iconStar from "shared/assets/images/mainIcons/iconIndicatorStar.svg";
import iconClose from "shared/assets/images/mainIcons/iconClose.svg";
import { ReactComponent as IconImage } from "shared/assets/images/mainIcons/iconImage.svg";
import { ReactComponent as IconAddWithoutFill } from "shared/assets/images/mainIcons/iconAdd/iconAddWithoutFill.svg";

type StaffOneEditFormPhotoAltProps = {
  value:
    | { [key: string]: { src: string | number; hash: string } }
    | string[]
    | string;
  mainPhoto: { src: string | number; hash: string };
  disabled?: boolean;
  userId: string;
};

const StaffOneEditFormPhotoAlt = ({
  value,
  mainPhoto,
  userId
}: StaffOneEditFormPhotoAltProps) => {
  const { staffOneEditStore } = useStores();
  const [galleryState, setGalleryState] = useState("closed");
  const [galleryImageIndex, setGalleryImageIndex] = useState<number>(null);
  const [cropData, setCropData] = useState("");

  if (!value) return;

  const galleryImages = Object.values(value).map(({ hash, src }) => {
    return {
      hash,
      src: `${fileUrl}${src}?${Math.floor(Math.random() * 100000)}`,
      isMain: false
    };
  });

  if (mainPhoto["src"]) {
    galleryImages.unshift({
      hash: mainPhoto["hash"],
      src: `${fileUrl}${mainPhoto["src"]}?${Math.floor(
        Math.random() * 100000
      )}`,
      isMain: true
    });
  }

  return (
    <div className={styles.photosGalleryWrapper}>
      {galleryImages.map(
        (
          photo: { src: string | number; hash: string; isMain: boolean },
          idx
        ) => {
          if (photo["src"] !== -1)
            return (
              <div
                className={`${styles.photoWrapper} ${
                  galleryState === "viewing" && idx === galleryImageIndex
                    ? styles.photoWrapper__disabled
                    : ""
                }`}
                key={photo["hash"]}
              >
                <img
                  className={styles.photo}
                  src={photo["src"] as string}
                  onClick={() => {
                    setGalleryImageIndex(idx);
                    setGalleryState("viewing");
                  }}
                />
                <img
                  src={iconClose}
                  className={styles.iconClose}
                  alt="Delete"
                  id={`StaffOneEditPhotoAlt_delete_${photo["hash"]}`}
                  onClick={() => {
                    staffOneEditStore.setDeletePhoto(photo["hash"]);
                  }}
                />
                {photo["isMain"] ? (
                  <img src={iconStar} className={styles.iconStar} alt="Main" />
                ) : (
                  ""
                )}
              </div>
            );
        }
      )}
      <input
        type="file"
        id="StaffOneEditPhotoAlt_uploadPhoto"
        accept=".jpg, .jpeg, .png"
        onClick={(e) => {
          e.preventDefault();
          setGalleryState("uploading");
          setGalleryImageIndex(null);
        }}
        name="uploadImg"
        hidden
      />
      <label
        htmlFor="StaffOneEditPhotoAlt_uploadPhoto"
        className={styles.placeForAdd}
      >
        <div className="position-relative">
          <IconImage className={styles.iconAddFotoDefault} />
          <IconAddWithoutFill className={styles.iconAddWithoutFill} />
        </div>
      </label>
      {galleryState !== "closed" ? (
        <StaffOneEditPhotoAltGallery
          cropData={cropData}
          setCropData={setCropData}
          loadedImages={galleryImages}
          imageIndex={galleryImageIndex}
          setImageIndex={setGalleryImageIndex}
          galleryState={galleryState}
          setGalleryState={setGalleryState}
          userId={userId}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default observer(StaffOneEditFormPhotoAlt);
