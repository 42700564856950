import styles from "./staffRating.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useEffect, useRef, useState } from "react";

import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import SearchInput from "shared/ui/Inputs/SearchInput";
import StaffRatingTable from "./StaffRatingTable/index";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Button, ButtonTheme } from "shared/ui/Button";

const StaffRating = () => {
  const { menuStore, modalWindowsStore, staffRatingStore } = useStores();
  const [activeRatingTypeTab, setActiveRatingTypeTab] = useState("");

  const inputRef = useRef<HTMLInputElement>();

  const handleFindData = () => {
    // делаем все вкладки неактивными для общего поиска
    staffRatingStore.setActiveSwitchedTab("");
    staffRatingStore.setSearchValue(staffRatingStore.searchValue);
    staffRatingStore.getRatingStaff(
      staffRatingStore.activeRatingYearTab,
      staffRatingStore.activeSwitchedTab
    );
    inputRef.current.blur();
  };

  useEffect(() => {
    staffRatingStore.getRatingList();
    menuStore.setSavedScroll();
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("staffRating");
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/staffRating",
      title: "Рейтинги"
    });
    activeRatingTypeTab !== "itr" && setActiveRatingTypeTab("itr");
    staffRatingStore.activeSwitchedTab !== "inTop" &&
      staffRatingStore.setActiveSwitchedTab("inTop");
  }, []);

  return (
    <>
      <div
        className={`${styles.headWrapper} ${
          menuStore.isMenuShown ? styles.headWrapper__collapsed : ""
        }`}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ButtonsTabs
            selectedTab={"itr"}
            tabs={[
              {
                title: "Рейтинг ИТР",
                id: "itr"
              }
            ]}
            changeActiveTab={() => {
              return;
            }}
          />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ButtonsTabs
            selectedTab={staffRatingStore.activeRatingYearTab}
            tabs={[...staffRatingStore.ratingYearsTabs]}
            changeActiveTab={staffRatingStore.setActiveRatingYearTab}
          />
        </ErrorBoundary>
        <div className={styles.search}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <SearchInput
              inputRef={inputRef}
              onChange={(e) => {
                staffRatingStore.setSearchValue(e.target.value);
              }}
              value={staffRatingStore.searchValue}
              handleFindData={handleFindData}
              blurCondition={
                staffRatingStore.isLoading || modalWindowsStore.isErrorWindow
              }
              clearSearch={() => {
                // так как табы развыделены, выдаем список по умолчанию "Топ-100"
                staffRatingStore.setActiveSwitchedTab("inTop");
                staffRatingStore.setSearchValue("");
                staffRatingStore.getRatingStaff(
                  staffRatingStore.activeRatingYearTab,
                  staffRatingStore.activeSwitchedTab
                );
              }}
            />
          </ErrorBoundary>
        </div>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          resetKeys={[staffRatingStore.activeSwitchedTab]}
        >
          <div className={styles.tabsBlock}>
            <SwitchedTabs
              tabs={
                // в рейтинге за 2022 год только одна страница в выдаче
                staffRatingStore.activeRatingYearTab ===
                "3db7aa62d638b6ff24674ad3eac64704e3094b65"
                  ? [{ id: "inTop", title: "Топ-100" }]
                  : [...staffRatingStore.itrSwitchedTabs]
              }
              changeActiveTab={(id) => {
                staffRatingStore.setActiveSwitchedTab(id);
                // при клике на неактивную вкладку очищается поле поиска и загружается список сотрудников
                if (staffRatingStore.searchValue.length) {
                  staffRatingStore.setSearchValue("");
                  staffRatingStore.getRatingStaff(
                    staffRatingStore.activeRatingYearTab,
                    staffRatingStore.activeSwitchedTab
                  );
                }
              }}
              selectedTab={staffRatingStore.activeSwitchedTab}
            />
            <div className={styles.linksBlock}>
              <Button
                onClick={() =>
                  window.open(
                    "https://drive.google.com/file/d/1zl8l6uuEwTbZpGvBS37wckMUXN2upjUY/view?usp=sharing",
                    "_blank"
                  )
                }
                id="StaffRating_video_first"
                theme={ButtonTheme.CLEAR}
                className={styles.link}
              >
                Что такое Рейтинг ИТР
              </Button>
              <Button
                onClick={() =>
                  window.open(
                    "https://drive.google.com/file/d/1kCPKEYK0Bdumw6-FBdJ5lffgA5gVGDzk/view",
                    "_blank"
                  )
                }
                id="StaffRating_video_second"
                theme={ButtonTheme.CLEAR}
                className={styles.link}
              >
                Как устроен Рейтинг ИТР
              </Button>
            </div>
          </div>
        </ErrorBoundary>
      </div>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        resetKeys={[
          staffRatingStore.activeSwitchedTab,
          staffRatingStore.activeSwitchedTab
        ]}
      >
        <StaffRatingTable
          selectedTab={staffRatingStore.activeRatingYearTab}
          switchedTab={staffRatingStore.activeSwitchedTab}
        />
      </ErrorBoundary>
    </>
  );
};
export default observer(StaffRating);
