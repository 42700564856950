import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Awards } from "../types/Awards";
import { Widgets } from "../types/Widgets";
import { cloneDeep, without } from "lodash";
import { Select } from "stores/utils/types/Select";

export default class StaffOneAwardsStore {
  error: { [staff_id: string]: boolean } = {};
  isLoading: { [staff_id: string]: boolean } = {};

  awards: Awards = {};
  // массив полей наград, количество полей изменяется в зависимости от открытых/закрытых виджетов наград
  awardsArray: string[] = [];
  // справочник "award_groups" чтобы достать заголовки для виджетов
  awardGroups: { [key: string]: Select<{ color: string }> } = {};
  // массив заголовков для виджетов
  fieldsTitlеsArray: string[] = [];

  staffInfo: Partial<Widgets["staff"]> = {};
  searchValue = "";
  isFocusSearch = false;

  actualPositionTitle = "";

  rootStore: RootStore;

  openedAllStaffForAllAwards: { [key: string]: Partial<Widgets> } = {};

  // счетчик найденных наград
  foundAwards: string[] = [];
  // массив полей в которых найдено совпадение по поиску
  awardsFieldsFoundDuringSearch: string[] = [];

  updateSections = (data?: Partial<Widgets>) => {
    if (data) {
      this.awards = data["widgets"]["awards"];
      this.awardGroups = data["selects"]["award_groups"];
      this.staffInfo = data.staff;
      Object.values(this.awardGroups).forEach((group) => {
        !this.fieldsTitlеsArray.includes(group.title) &&
          this.fieldsTitlеsArray.push(group.title);
      });
    } else {
      this.awards = {};
      this.staffInfo = {};
      this.awardGroups = {};
      this.fieldsTitlеsArray = [];
    }
  };

  setSelectedOneForAllAwards = (id: string) => {
    this.isLoading[id] = true;
    this.error[id] = false;

    this.updateSections();
    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }
    if (!this.rootStore.menuStore.allWindows[`/staff/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
      delete this.openedAllStaffForAllAwards[id];
    }

    if (this.openedAllStaffForAllAwards[id]) {
      if (Object.values(this.openedAllStaffForAllAwards[id]).length) {
        this.updateSections(this.openedAllStaffForAllAwards[id]);
      } else {
        this.error[id] = true;
      }
      this.isLoading[id] = false;
    } else this.getAwardsForOneOfStaff(id);
  };

  getAwardsForOneOfStaff = async (id: string) => {
    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          staff_fields:
            "surname, name, patronymic, id, company, uid, photo, position",
          widgets: ["awards"],
          selects: "award_groups, position",
          cols: "staff_awards"
        }
      });

      if (!data["errors"]) {
        this.openedAllStaffForAllAwards[id] = data;
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: `${data["staff"]["surname"]} ${data["staff"]["name"]}`
        });
        if (this.rootStore.menuStore.tabId === id) {
          runInAction(() => (this.isLoading[id] = false));
          this.updateSections(this.openedAllStaffForAllAwards[id]);
        }
        if (Object.values(data.selects.position).length) {
          Object.values(data.selects.position).forEach((item) => {
            Object.values(item).forEach((positionElement) => {
              if (positionElement["id"] === this.staffInfo["position"]) {
                this.actualPositionTitle = positionElement["title"];
              }
            });
          });
        }
      } else {
        this.openedAllStaffForAllAwards[id] = {};
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: "Ничего не найдено"
        });

        runInAction(() => {
          this.error[id] = true;
          this.isLoading[id] = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error[id] = true;
      });
    }
  };

  setIsFocusSearch = (value: boolean) => {
    this.isFocusSearch = value;
  };

  setSearchValue = (value: string) => {
    this.searchValue = value;
  };

  setAwardsArray = (value: string[]) => {
    this.awardsArray = value;
  };

  setFoundAwards = (foundAwards: string[]) => {
    this.foundAwards = foundAwards;
  };

  setAwardsFieldsFoundDuringSearch = (fields: string[]) => {
    this.awardsFieldsFoundDuringSearch = fields;
  };

  changeOpenedAwards = (action: "add" | "delete", fields: string[]) => {
    let newAwardsArray = cloneDeep(this.awardsArray);
    if (action === "add") {
      fields.forEach((field) => {
        !newAwardsArray.includes(field) && newAwardsArray.push(field);
      });
    } else {
      fields.forEach((field) => {
        newAwardsArray = without(newAwardsArray, field);
      });
    }
    this.setAwardsArray(newAwardsArray);
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
