import styles from "./staffOneStatusesTabs.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { classNames } from "shared/utils/helpers/classNames";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type StaffOneStatusesTabsProps = {
  changeSelectedStatus: (arg: string) => void;
};

const StaffOneStatusesTabs = ({
  changeSelectedStatus
}: StaffOneStatusesTabsProps) => {
  const { staffOneStatusesStore } = useStores();

  return (
    <div className={styles.tabs}>
      {Object.entries(staffOneStatusesStore.statusesParams).length
        ? Object.entries(staffOneStatusesStore.statusesParams).map(
            ([key, status]) => {
              const handleSetSelectedStatus = () => changeSelectedStatus(key);

              if (
                !staffOneStatusesStore.statusesParams[
                  staffOneStatusesStore.lastStatus["type"]
                ]?.custom?.ignore_transit_cols_bool
                  ? status.custom.choice &&
                    staffOneStatusesStore.showStatuses.includes(key)
                  : true
              ) {
                return (
                  <Chips
                    variation={ChipsVariation.FILTER}
                    key={key}
                    className={classNames(styles.button, {
                      [styles.selectedGrayBtn]:
                        staffOneStatusesStore.selectedStatus === key
                    })}
                    onClick={handleSetSelectedStatus}
                    id={`StaffOneStatusesTabs_statusForm_${key}`}
                    title={status.title}
                    icon={status.custom?.icon}
                    iconColor={
                      staffOneStatusesStore.selectedStatus !== status.id
                        ? status.custom.color_api
                        : "default"
                    }
                  />
                );
              }
            }
          )
        : ""}
    </div>
  );
};
export default observer(StaffOneStatusesTabs);
