import styles from "./buildingOneInfo.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";

import Tooltip from "shared/ui/Tooltip";
import StatusIcon from "shared/ui/StatusIcon";
import BuildingOneInfoUsers from "./BuildingOneInfoUsers";
import { fileUrl } from "stores/utils/consts";
import { Fragment } from "react";
import { Chips, ChipsVariation } from "shared/ui/Chips";

const BuildingOneInfo = () => {
  const { buildingOneStore } = useStores();

  return (
    <>
      {/* Проверяем наличие полей в buildingCols */}
      {buildingOneStore.buildingCols &&
        Object.values(buildingOneStore.buildingCols).length &&
        // И проходимся по массиву columns
        Object.entries(buildingOneStore.buildingCols).map(([title, param]) => {
          // Если поле можно отображать, то рендерим его

          // Поля, плашки которых должны быть без фона
          const withoutBackground = [
            "payment_types",
            "hide_objectstatus"
          ].includes(title);

          if (param?.show && param?.hidden !== "on") {
            switch (title) {
              // кейсы для полей с массивом "плашек"
              case "users":
                return <BuildingOneInfoUsers key={title} />;

              case "guid1c":
              case "payment_types":
              case "hide_objectstatus":
                return (
                  <div key={title} className={styles.row}>
                    <div className={styles.field}>
                      <p className={styles.title}>{param.title}</p>
                      {buildingOneStore.selectedOne[title]
                        ? Object.values(
                            buildingOneStore.selectedOne[title]
                          ).map((item: { title: string; id: string }) => {
                            return (
                              <Chips
                                key={title}
                                variation={ChipsVariation.COLSDEFAULT}
                                className={`${styles.chips} ${
                                  withoutBackground
                                    ? styles.chipsWithoutBackground
                                    : ""
                                }`}
                              >
                                {(withoutBackground &&
                                  buildingOneStore.variables[title]?.[`${item}`]
                                    ?.title) ||
                                (!withoutBackground && item) ? (
                                  <span key={item.id}>
                                    {withoutBackground ||
                                    buildingOneStore.variables[title]?.[
                                      `${item}`
                                    ]?.title
                                      ? buildingOneStore.variables[title]?.[
                                          `${item}`
                                        ]?.title
                                      : item?.title
                                      ? item.title
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Chips>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                );

              // Поля с булевым значением
              case "search_staff":
              case "for_mobile":
                return (
                  <div key={title} className={styles.row}>
                    <div className={styles.field}>
                      <p className={styles.title}>{param.title}</p>
                      <div className={styles.value}>
                        {buildingOneStore.selectedOne[title] ? (
                          <StatusIcon icon="bigcheck" color="accent-green" />
                        ) : (
                          <StatusIcon icon="dash" color="accent-coral" />
                        )}
                      </div>
                    </div>
                  </div>
                );

              // Если приходит - отображаем тултип "В разработке", если нет - пустая строка
              case "client":
                return (
                  <div key={title} className={styles.row}>
                    <div className={styles.field}>
                      <p className={styles.title}>{param.title}</p>
                      {buildingOneStore.selectedOne[title] ? (
                        <Tooltip
                          color="#008CFF"
                          backgroundColor="#F2F9FF"
                          text="Раздел находится в разработке"
                          placement="right"
                        >
                          <a className={styles.value}>
                            {buildingOneStore.selectedOne[title]}
                          </a>
                        </Tooltip>
                      ) : (
                        <p className={styles.value}>
                          <br />
                        </p>
                      )}
                    </div>
                  </div>
                );

              case "customers":
                return (
                  <div key={title} className={styles.row}>
                    <div className={styles.field}>
                      <p className={styles.title}>{param.title}</p>
                      <div
                        className={`${styles.value} flex-column align-items-start gap-2`}
                      >
                        {Object.values(buildingOneStore.selectedOne[title]).map(
                          (customer) => {
                            return (
                              <Fragment key={customer.id}>
                                <div>
                                  <a
                                    className={styles.link}
                                    href={`${fileUrl}/crm/customer/?id=${customer.id}`}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    {customer.title}
                                  </a>
                                </div>

                                <div>
                                  {customer.contracts
                                    ? Object.values(customer.contracts).map(
                                        (contract, ind) => {
                                          return (
                                            <span
                                              key={`${customer.id}_${contract}_${ind}`}
                                            >
                                              {ind !== 0 ? " " : ""}
                                              {contract} /
                                            </span>
                                          );
                                        }
                                      )
                                    : null}
                                </div>
                              </Fragment>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                );

              // id не отображаем никогда, pictures в объекте отдельный блок
              case "pictures":
              case "id":
                return;

              case "company":
                return (
                  <div key={title} className={styles.row}>
                    <div className={styles.field}>
                      <p className={styles.title}>{param.title}</p>
                      <Chips
                        variation={ChipsVariation.NONACTIVE}
                        title={
                          buildingOneStore.variables[title] &&
                          buildingOneStore.selectedOne[title]
                            ? // если оба пункта да - отображаем title из справочника по ключу-значению поля
                              buildingOneStore.variables[title][
                                buildingOneStore.selectedOne[title]
                              ]?.title
                            : // если один из пунктов - нет, отображаем значение или пустую строку
                              buildingOneStore.selectedOne[title]
                        }
                        className={styles.chipsGreen}
                      />
                    </div>
                  </div>
                );

              // остальные поля отображаем по дефолту
              default:
                return (
                  <div key={title} className={styles.row}>
                    <div className={styles.field}>
                      <p className={styles.title}>{param.title}</p>
                      <div
                        className={styles.value}
                      >
                        {/* Проверяем есть ли список для поля и если ли значение  */}
                        {buildingOneStore.variables[title] &&
                        buildingOneStore.selectedOne[title]
                          ? // если оба пункта да - отображаем title из справочника по ключу-значению поля
                            buildingOneStore.variables[title][
                              buildingOneStore.selectedOne[title]
                            ]?.title || <br />
                          : // если один из пунктов - нет, отображаем значение или пустую строку
                            buildingOneStore.selectedOne[title] || <br />}
                      </div>
                    </div>
                  </div>
                );
            }
          }
        })}
    </>
  );
};

export default observer(BuildingOneInfo);
