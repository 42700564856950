import styles from "./autoComplete.module.scss";
import { useEffect, useState } from "react";

import { Scrollbars } from "react-custom-scrollbars-2";
import { useFormikContext } from "formik";
import { IRecordsForEdit } from "stores/StaffModule/types/RecordsForEdit";
import { classNames } from "shared/utils/helpers/classNames";

type AutoCompleteProps = {
  array: { value: string; [key: string]: string }[];
  name: string;
  label: string;
  values: { [key: string]: string | number | null } | Partial<IRecordsForEdit>;
  initialValues:
    | { [key: string]: string | number | null }
    | Partial<IRecordsForEdit>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage: string;
  isLoading: boolean;
  cleanArray: () => void;
  keys: string[];
  disabled?: boolean;
};

const AutoComplete = ({
  array,
  name,
  label,
  values,
  initialValues,
  onChange,
  errorMessage,
  isLoading,
  cleanArray,
  keys,
  disabled
}: AutoCompleteProps) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [source, setSource] = useState("");
  const [prevSource, setPrevSource] = useState("");
  let isChanged = false;

  useEffect(() => {
    let string = "";
    keys.forEach((key) => {
      if (values[key]) {
        if (name === "fio") {
          string += (string.length ? " " : "") + values[key];
        } else {
          string += (string.length ? ", " : "") + values[key];
        }
      }
    });
    setSource(string);
    initialValues[name] = string;
  }, [initialValues]);

  const changeData = (item?: string | { value: string }) => () => {
    if (item) {
      if (typeof item === "object") {
        setFieldValue(name, item["value"]);
        setSource(item["value"]);

        Object.entries(item).forEach(([key, value]) => {
          key !== "value" && setFieldValue(key, value);
        });
      } else {
        if (name === "fio") {
          setFieldValue(name, item);
          setSource(item);
          const array = item.split(" ");
          keys.forEach((key, index) => {
            setFieldValue(
              key,
              index !== keys.length - 1
                ? array[index]
                : array.slice(index, array.length).join(" ")
            );
          });
        }
      }
      isChanged = true;
    } else {
      if (!isChanged) {
        setFieldValue(name, source);
      }
    }

    keys.forEach((key) => {
      setFieldTouched(key);
    });
    setPrevSource("");
    cleanArray();
  };
  const handleOnBlur = () => {
    setTimeout(() => {
      changeData();
    }, 200);
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    isChanged = false;
    if (!event.target?.value?.length) {
      keys.forEach((key) => setFieldValue(key, event.target.value));
      setFieldValue(name, event.target.value);
      setSource(event.target.value);
      setPrevSource(event.target.value);
      cleanArray();
    } else {
      setPrevSource(event.target.value);
      onChange(event);
    }
  };

  return (
    <div className={styles.relative} tabIndex={0} onBlur={handleOnBlur}>
      <input
        name={name}
        value={values[name] || values[name] === null ? values[name] : source}
        onChange={handleOnChange}
        disabled={disabled}
        className={classNames(styles.input, {
          [styles.inputDisabled]: disabled
        })}
        autoComplete="no-autofill-please"
      />
      <label
        className={classNames(styles.label, {
          [styles.labelWithData]: values[name]?.length,
          [styles.labelNoData]: !values[name] || values[name].length === 0
        })}
      >
        {label}
      </label>

      {(array.length > 1 || prevSource || errorMessage) && (
        <div className={styles.list}>
          {!isLoading ? (
            <Scrollbars
              style={{ width: "100%" }}
              autoHeight
              autoHeightMax="250px"
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <>
                {prevSource && name === "fio" ? (
                  <li onClick={changeData(prevSource)}>
                    <p className={styles.currentSource}>{prevSource}</p>
                  </li>
                ) : null}
                {array.map((item, index) => {
                  return (
                    <li key={index} onClick={changeData(item)}>
                      <p>{item["value"]}</p>
                    </li>
                  );
                })}
              </>
            </Scrollbars>
          ) : (
            <p className={styles.loading}>Пожалуйста, подождите</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
