import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores/index";
import LoadedComponent from "widgets/LoadedComponent";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import AwardsHeader from "./AwardsHeader";
import AwardsSearch from "./AwardsSearch";
import AwardsFields from "./AwardsFields";
import { useEffect } from "react";

const StaffOneAwards = () => {
  const { menuStore, staffOneAwardsStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    menuStore.sendTabId(id);
    staffOneAwardsStore.setSelectedOneForAllAwards(id);
    staffOneAwardsStore.setIsFocusSearch(false);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffAwards");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/awards`
    });
  }, [id]);

  return (
    <LoadedComponent
      isLoading={staffOneAwardsStore.isLoading[id]}
      error={staffOneAwardsStore.error[id]}
    >
      <>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AwardsHeader />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AwardsSearch id={id} />
        </ErrorBoundary>
        {Object.values(staffOneAwardsStore.awardGroups).map((field) => {
          const filteredAwards = Object.fromEntries(
            Object.entries(staffOneAwardsStore.awards).filter(([, value]) =>
              staffOneAwardsStore.searchValue
                ? String(value.type_title.toLowerCase()).includes(
                    staffOneAwardsStore.searchValue
                  ) &&
                  staffOneAwardsStore.awardGroups[value.custom.group].title ===
                    field.title
                : staffOneAwardsStore.awardGroups[value.custom.group].title ===
                  field.title
            )
          );
          return (
            <ErrorBoundary FallbackComponent={ErrorFallback} key={field.id}>
              <AwardsFields awards={filteredAwards} field={field.title} />
            </ErrorBoundary>
          );
        })}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneAwards);
