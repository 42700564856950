import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStores } from "stores";

export const useAuthData = () => {
  const { userStore, menuStore } = useStores();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    userStore.getNewAccessKey();
  }, []);

  useEffect(() => {
    if (!Object.values(menuStore.modulesList).length && userStore.accessKey) {
      menuStore.getAllModulesList();
    }
  }, [Object.values(menuStore.modulesList).length, userStore.accessKey]);

  useEffect(() => {
    if (userStore.accessKey) {
      const queryParams = searchParams.toString();
      const url = `${location.pathname}${queryParams ? `?${queryParams}` : ""}`;
      navigate(url);
    }
  }, [userStore.accessKey]);
};
