import styles from "./staffImportTableBodyDuplicateError.module.scss";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import Tooltip from "shared/ui/Tooltip";
import attention from "shared/assets/images/iconStatus/Attention.svg";

type StaffImportTableBodyDuplicateErrorProps = {
  index: number;
  title?: string;
};

const StaffImportTableBodyDuplicateError = ({
  index,
  title
}: StaffImportTableBodyDuplicateErrorProps) => {
  const { staffImportStore } = useStores();
  const navigate = useNavigate();

  const { uid, full_name, staff_id } = title
    ? staffImportStore.verificationResponse[index]["doubles"][title]
    : staffImportStore.verificationResponse[index]["doubles"] &&
      Object.values(staffImportStore.verificationResponse[index]["doubles"])[0];

  return (
    <div
      className={`${styles.duplicateError} ${
        !staffImportStore.duplicateColsResponse[index]?.includes(title)
          ? styles.duplicateError_grey
          : ""
      }`}
    >
      <img src={attention} alt="" />
      <Tooltip
        text={
          <>
            <div>Нажмите чтобы перейти в профиль сотрудника: </div>{" "}
            <div>{`ТН${uid} ${full_name}`} </div>
          </>
        }
        borderColor="bw-gray6"
        color="bw-gray6"
        placement="top"
      >
        <p onClick={() => navigate(`../staff/id=${staff_id}`)}>{`ТН${uid}`}</p>
      </Tooltip>
    </div>
  );
};

export default observer(StaffImportTableBodyDuplicateError);
