import styles from "./textarea.module.scss";
import { useRef, useState } from "react";
import { useField, useFormikContext } from "formik";
import TextareaAutosize from "react-textarea-autosize";
import { Col } from "stores/utils/types/Col";
import { classNames } from "shared/utils/helpers/classNames";
import { ClearFieldButton } from "shared/ui/ClearFieldButton";
import { Placement } from "@floating-ui/react-dom";
interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Для прокидывания стилей из родительского компонента
   */
  className?: string;
  /**
   * Отображается в заполненном поле
   */
  label?: string;
  /**
   * Разрешает перенос на новый абзац по клику на Enter
   */
  enableEnter?: boolean;
  /**
   * Добавляет кнопку очистки в поле ввода
   */
  withClearBtn?: boolean;
  /**
   * С какой стороны будет тултип на кнопке
   */
  clearBtnPlacement?: Placement;
  /**
   * Применяет нужные стили если Textarea используется в sharedComponent CommentInputField
   */
  forCommentInputField?: boolean;
  /**
   * Максимальное количество строк
   */
  maxRows?: number;
  /**
   * Допустимое количество символов в поле комментария
   */
  commentCol?: Partial<Col>;
  /**
   * Обязательность поля
   */
  required?: boolean;
  /**
   * Заблокировано ли поле
   */
  disabled?: boolean;
  /**
   * Нужен ли счетчик символов
   */
  withoutCounter?: boolean;
  textAreaRef?: React.RefObject<HTMLTextAreaElement>;
}

const ALLOWED_NUM_OF_CHARS = 250;

const Textarea = ({
  className,
  label,
  enableEnter,
  withClearBtn,
  clearBtnPlacement,
  forCommentInputField,
  maxRows,
  commentCol,
  textAreaRef,
  withoutCounter,
  ...props
}: TextareaProps) => {
  const { name, required, onBlur, onChange, placeholder, maxLength, disabled } =
    props;
  const [isFocus, setIsFocus] = useState(false);
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const initialCounterValue = useRef(
    commentCol?.length &&
      (commentCol.type === "text" || commentCol.type === "varchar")
      ? Number(commentCol.length)
      : ALLOWED_NUM_OF_CHARS
  );

  const [counter, setCounter] = useState(initialCounterValue.current);

  const isError = meta.touched && meta.error;
  const isRequired = required && !isFocus && !field.value;

  const handleOnBlur = (
    event: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    setIsFocus(false);
    onBlur && onBlur(event);
    setFieldTouched(field.name);
  };

  const handleClick = () => {
    setIsFocus(true);
  };

  const handleResetTextarea = () => {
    setFieldValue(field.name, "");
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFieldValue(field.name, event.target.value);
    onChange && onChange(event);
    setCounter(initialCounterValue.current - event.target.value.length);
  };

  const handleAddIndent = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    enableEnter ? "" : event.key === "Enter" && event.preventDefault();
  };

  return (
    <>
      <div
        className={classNames(styles.wrapper, {
          [styles.wrapperRequired]: isRequired,
          [styles.wrapperInvalid]: isError && !isFocus && !field.value
        })}
      >
        {label && (isFocus || field.value) ? (
          <p
            className={classNames(styles.label, {
              [styles.labelRequired]: required,
              [styles.labelInvalid]: required && isError
            })}
          >
            {label}
          </p>
        ) : null}
        <TextareaAutosize
          ref={textAreaRef}
          name={field.name}
          value={field.value}
          onBlur={handleOnBlur}
          placeholder={placeholder || " "}
          onChange={handleChange}
          onClick={handleClick}
          onKeyDown={handleAddIndent}
          maxLength={
            initialCounterValue.current
              ? initialCounterValue.current
              : maxLength
          }
          maxRows={maxRows}
          disabled={disabled}
          className={classNames(
            styles.textarea,
            {
              [styles.textIndent]: withClearBtn,
              [styles.forCommentInputFieldTextarea]: forCommentInputField,
              [styles.withoutLabel]: !label,
              [styles.textareaError]: isError,
              [styles.required]: isRequired
            },
            [className]
          )}
        />
        {!disabled && !withoutCounter && (
          <div
            className={classNames(styles.initialCounterValue, {
              [styles.forCommentInputField]: forCommentInputField
            })}
          >
            Осталось символов:{" "}
            <span
              className={classNames(styles.green, {
                [styles.red]: counter <= 20
              })}
            >
              {!field.value ? initialCounterValue.current : counter} /{" "}
              {initialCounterValue.current}
            </span>
          </div>
        )}
        {withClearBtn ? (
          <ClearFieldButton
            name={field.name}
            disabled={disabled}
            isFocused={isFocus}
            onClick={handleResetTextarea}
            className={field.value ? styles.clearBtnActive : styles.clearBtn}
            clearBtnPlacement={clearBtnPlacement}
          />
        ) : null}
        {!forCommentInputField && isError ? (
          <div
            className={classNames(styles.error, {
              [styles.errorWithoutCounter]: withoutCounter
            })}
          >
            {meta.error}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Textarea;

export type TextareaAttrs = JSX.LibraryManagedAttributes<
  typeof Textarea,
  TextareaProps
>;
