import styles from "./buildingOneStaffTable.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores";
import { useState, useEffect, Fragment, FocusEvent } from "react";
import { useParams } from "react-router-dom";
import { useFormikContext } from "formik";

import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import BuildingOneStaffTableEventBlock from "./BuildingOneStaffTableEventBlock";
import BuildingOneStaffTableEventDate from "./BuildingOneStaffTableEventDate";
import BuildingOneStaffTablePhone from "./BuildingOneStaffTablePhone";
import BuildingOneStaffTableOV from "./BuildingOneStaffTableOV";
import Textarea from "shared/ui/Inputs/Textarea";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import BuildingOneStaffTableSearchRow from "./BuildingOneStaffTableSearchRow";
import BuildingOneStaffTableError from "./BuildingOneStaffTableError";
import BuildingOneStaffTableSafetywork from "./BuildingOneStaffTableSafetywork";

import iconArrowDouble from "shared/assets/images/mainIcons/iconArrowDouble.svg";

import { Staff } from "stores/BuildingModule/types/BuildingStaffType";
import { BuildingOneStaffValues } from "stores/BuildingModule/types/BuildingOneStaffValues";
import { ErrorBoundary } from "react-error-boundary";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";

type BuildingOneStaffTableProps = {
  setSelectedStaff: (arg: Staff) => void;
  setIsOpenWindow: (arg: boolean) => void;
};

const BuildingOneStaffTable = ({
  setSelectedStaff,
  setIsOpenWindow
}: BuildingOneStaffTableProps) => {
  const { menuStore, buildingOneStaffStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [isOpenedWindowCalendar, setIsOpenedWindowCalendar] = useState(false);

  const { id } = useParams();
  const { values } = useFormikContext<BuildingOneStaffValues>();

  const cols = Object.entries(
    buildingOneStaffStore.tabs[buildingOneStaffStore.activeTab[id]]?.cols || {}
  );

  const changeOpenedListName = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      buildingOneStaffStore.maxPage[id] >= buildingOneStaffStore.page[id] &&
      buildingOneStaffStore.page[id] === buildingOneStaffStore.prevPage
    ) {
      buildingOneStaffStore.setPage(buildingOneStaffStore.page[id] + 1);
    }
  }, [
    menuStore.isScrollBottom,
    buildingOneStaffStore.page[id],
    buildingOneStaffStore.maxPage[id],
    buildingOneStaffStore.prevPage
  ]);

  useEffect(() => {
    if (
      buildingOneStaffStore.page[id] !== 1 &&
      buildingOneStaffStore.page[id] <= buildingOneStaffStore.maxPage[id] &&
      buildingOneStaffStore.page[id] !== buildingOneStaffStore.prevPage &&
      !buildingOneStaffStore.isLoadingMoreStaff[id]
    ) {
      buildingOneStaffStore.getMoreBuildingStaff(
        id,
        buildingOneStaffStore.activeTab[id]
      );
    }
  }, [
    buildingOneStaffStore.page[id],
    buildingOneStaffStore.maxPage[id],
    buildingOneStaffStore.prevPage,
    buildingOneStaffStore.isLoadingMoreStaff[id]
  ]);

  return (
    <div className={styles.loading}>
      <BuildingOneStaffTableError />
      <LoadedComponent
        isLoading={buildingOneStaffStore.isLoadingForTable[id]}
        error={buildingOneStaffStore.error[id]}
      >
        <>
          <Table className={styles.table}>
            <thead
              className={classNames(styles.header, {
                [styles.header_shadow]: menuStore.isScroll
              })}
            >
              <tr>
                {cols.map(([col_name, col_title], index) => {
                  return (
                    <Fragment key={col_name}>
                      {index === 0 ? (
                        <th className={styles.header__title}>
                          <div className="d-flex align-items-center">
                            <p className="m-0">№</p>
                          </div>
                        </th>
                      ) : null}
                      <th className={styles.header__title}>
                        <div className="d-flex align-items-center">
                          <p className="m-0">{col_title}</p>
                          <img src={iconArrowDouble} alt="sort" />
                        </div>
                      </th>
                    </Fragment>
                  );
                })}
              </tr>
            </thead>

            {Object.values(values || {}).length ? (
              <tbody className={styles.table__body}>
                <BuildingOneStaffTableSearchRow />
                {Object.entries(values).map(([staff_id, staff_data], index) => {
                  const isGray = staff_data.grey_list === 1;
                  const isBlack = staff_data.black_list === 1;

                  const handleClickEventDate = (event_type: string) => {
                    setSelectedStaff(staff_data);
                    changeOpenedListName(staff_id);
                    setIsOpenWindow(true);
                    buildingOneStaffStore.setSelectedStatus(
                      event_type,
                      staff_id
                    );
                  };

                  const handleBlurTextarea = (event: FocusEvent) =>
                    buildingOneStaffStore.updateStaffComment(
                      staff_id,
                      event["target"]["value"]
                    );

                  return (
                    <tr
                      key={staff_id}
                      className={classNames(styles.table__row, {
                        [styles.table__row_secondary]: !(index % 2),
                        [styles.table__row_black]: isBlack,
                        [styles.table__row_grey]: isGray
                      })}
                    >
                      {Object.keys(
                        buildingOneStaffStore.tabs[
                          buildingOneStaffStore.activeTab[id]
                        ].cols
                      ).map((col_name, ind) => {
                        return (
                          <Fragment key={`${staff_id}_${col_name}`}>
                            {ind === 0 ? (
                              <td>
                                <div>
                                  <p>{index + 1}</p>
                                </div>
                              </td>
                            ) : null}
                            <td>
                              {(() => {
                                switch (col_name) {
                                  case "safetywork":
                                    return (
                                      <BuildingOneStaffTableSafetywork
                                        staff_id={staff_id}
                                        col_title={
                                          buildingOneStaffStore.tabs[
                                            buildingOneStaffStore.activeTab[id]
                                          ].cols[col_name]
                                        }
                                        staff_data={staff_data}
                                        openedListName={openedListName}
                                        changeOpenedListName={
                                          changeOpenedListName
                                        }
                                      />
                                    );
                                  case "name":
                                    return (
                                      <Link
                                        className={styles.link}
                                        id={`BuildingOneStaffTable_staffLink_${staff_id}`}
                                        to={`/staff/id=${staff_id}`}
                                        target="_blank"
                                      >
                                        {getFormattedDate(staff_data[col_name])}
                                      </Link>
                                    );
                                  case "event":
                                    return staff_data ? (
                                      <ErrorBoundary
                                        FallbackComponent={ErrorFallback}
                                      >
                                        <BuildingOneStaffTableEventBlock
                                          staff_id={staff_id}
                                          event={staff_data.event}
                                          in_list={isBlack || isGray}
                                          openedListName={openedListName}
                                          changeOpenedListName={
                                            changeOpenedListName
                                          }
                                          onClick={handleClickEventDate}
                                        />
                                      </ErrorBoundary>
                                    ) : null;
                                  case "event_start":
                                  case "event_end":
                                    return (
                                      <ErrorBoundary
                                        FallbackComponent={ErrorFallback}
                                      >
                                        <BuildingOneStaffTableEventDate
                                          staff_id={staff_id}
                                          col_name={col_name}
                                          staff_data={staff_data}
                                          openedListName={openedListName}
                                          changeOpenedListName={
                                            changeOpenedListName
                                          }
                                        />
                                      </ErrorBoundary>
                                    );
                                  case "invest":
                                  case "over_price":
                                    return (
                                      <ErrorBoundary
                                        FallbackComponent={ErrorFallback}
                                      >
                                        <BuildingOneStaffTableOV
                                          staff_id={staff_id}
                                          col_name={col_name}
                                          col_title={
                                            buildingOneStaffStore.tabs[
                                              buildingOneStaffStore.activeTab[
                                                id
                                              ]
                                            ].cols[col_name]
                                          }
                                          staff_data={staff_data}
                                          openedListName={openedListName}
                                          changeOpenedListName={
                                            changeOpenedListName
                                          }
                                          isOpenedWindowCalendar={
                                            isOpenedWindowCalendar
                                          }
                                          setIsOpenedWindowCalendar={
                                            setIsOpenedWindowCalendar
                                          }
                                        />
                                      </ErrorBoundary>
                                    );
                                  case "phone":
                                    return (
                                      <ErrorBoundary
                                        FallbackComponent={ErrorFallback}
                                      >
                                        <BuildingOneStaffTablePhone
                                          staff_data={staff_data}
                                          col_name={col_name}
                                          staff_id={staff_id}
                                        />
                                      </ErrorBoundary>
                                    );
                                  case "comment":
                                    return buildingOneStaffStore.openedType ===
                                      "edit" ? (
                                      <Textarea
                                        name={`${staff_id}.${col_name}`}
                                        value={staff_data[col_name] || " "}
                                        maxRows={5}
                                        onBlur={handleBlurTextarea}
                                        commentCol={
                                          buildingOneStaffStore.staffTableCols
                                            .comment as {
                                            [key: string]: string;
                                          }
                                        }
                                      />
                                    ) : (
                                      <div>
                                        <p>{staff_data[col_name]}</p>
                                      </div>
                                    );
                                  default:
                                    return (
                                      <div>
                                        <p>
                                          {getFormattedDate(
                                            staff_data[col_name]
                                          )}
                                        </p>
                                      </div>
                                    );
                                }
                              })()}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              !buildingOneStaffStore.isLoadingForTable[id] && (
                <tbody>
                  <tr className={styles.withoutStaff}>
                    <td colSpan={cols.length + 1}>Ничего не найдено</td>
                  </tr>
                </tbody>
              )
            )}
          </Table>
          {buildingOneStaffStore.isLoadingMoreStaff[id] ? (
            <div className={styles.firstCol}>
              <p className={styles.loading}>Пожалуйста, подождите</p>
            </div>
          ) : null}
        </>
      </LoadedComponent>
    </div>
  );
};

export default observer(BuildingOneStaffTable);
