import { useCallback, useEffect } from "react";
import { useStores } from "stores";
import styles from "./staffImportUpload.module.scss";
import { read, utils } from "xlsx";
import { StaffImportTableType } from "stores/StaffModule/types/StaffImportTable";
import { useDropzone } from "react-dropzone";
import { ReactComponent as UploadIcon } from "shared/assets/images/menuIcons/iconDefault/ImportEmployee.svg";
import { ReactComponent as UploadIconDefault } from "shared/assets/images/menuIcons/iconSelected/ImportEmployee.svg";
import { observer } from "mobx-react-lite";
import StaffImportUploadFileDownload from "./StaffImportUploadFileDownload";
import { Button } from "shared/ui/Button";

const StaffImportUpload = () => {
  const { staffImportStore } = useStores();

  useEffect(() => {
    if (!staffImportStore.dataForTable.length) {
      localStorage.removeItem("import_staff");
    }
  }, [staffImportStore.dataForTable.length]);

  // функция запрещает вызывать загрузку файла по нажатию любой области кроме кнопки загрузить
  const handleMouseClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event["target"]["id"] !== "StaffImportUpload_add") {
      event.stopPropagation();
    }
  };

  // функция, которая загружает файл в react-приложение
  const onDrop = useCallback((files: Array<File>) => {
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      staffImportStore.setIsLoading(true);
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows: StaffImportTableType[] = utils.sheet_to_json(
            wb.Sheets[sheets[0]],
            { defval: "" }
          );
          staffImportStore.setDataForTable(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".xlx",
        ".numbers"
      ]
    }
  });
  return (
    <form id="StaffImportUpload_form" onSubmit={(e) => e.preventDefault()}>
      <div
        {...getRootProps({
          className: "dropzone",
          onDrop: (event) => event.preventDefault()
        })}
      >
        <input {...getInputProps()} />
        <div
          className={`${styles.container} ${
            isDragActive ? styles.container_active : ""
          }`}
          id="StaffImportUpload_mainBlock"
          onClick={handleMouseClick}
        >
          {isDragActive ? (
            <UploadIcon className={styles.uploadIcon} />
          ) : (
            <UploadIconDefault
              className={`${styles.uploadIcon} ${styles.uploadIcon_default}`}
            />
          )}
          <div className={styles.text}>
            Чтобы загрузить файл, перетащите его сюда или нажмите кнопку
            &ldquo;Загрузить файл&rdquo;
          </div>
          <Button id="StaffImportUpload_add">Загрузить файл</Button>
          <StaffImportUploadFileDownload />
        </div>
      </div>
    </form>
  );
};

export default observer(StaffImportUpload);
