import styles from "./select.module.scss";
import { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";

import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  offset,
  flip,
  shift,
  autoUpdate,
  FloatingPortal
} from "@floating-ui/react";

import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import { ReactComponent as IconMinus } from "shared/assets/images/mainIcons/iconMinus.svg";

import type { Placement } from "@floating-ui/react";
import { classNames } from "shared/utils/helpers/classNames";
import { Button, ButtonTheme } from "shared/ui/Button";

import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";

type SelectProps = {
  label?: string;
  isFloating?: boolean;
  title?: string;
  options: { [key: string]: OptionWithTitle } | OptionWithTitle[];
  onChange?: (option: string) => void;
  onClick?: (option: OptionWithTitle) => void;
  onFocus?: (event: React.FocusEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
  placement?: Placement;
  name: string;
  required?: boolean;
  disabled?: boolean;
  valueName?: string;
  placeholderVisible?: boolean;
  placeholder?: string;
  isSelectForTable?: boolean;

  withClearBtn?: boolean;
  mark?: JSX.Element;
  isSearchable?: boolean;
  isSearchWithPagination?: boolean;
  page?: number;
  prevPage?: number;
  maxPage?: number;
  setPage?: (value: number) => void;
  getList?: () => void;
  setSearchValue?: (value: string) => void;
  searchValue?: string;
  isLoading?: boolean;
  notSortable?: boolean;
  withOpenedMenu?: boolean;
};

const Select = ({
  label,
  options,
  isFloating,
  title,
  onClick,
  onFocus,
  onChange,
  placement,
  name,
  required,
  disabled,
  valueName,
  placeholderVisible,
  placeholder,
  isSelectForTable,

  withClearBtn,
  mark,
  isSearchable,
  isSearchWithPagination,
  page,
  prevPage,
  maxPage,
  setPage,
  getList,
  setSearchValue,
  searchValue,
  isLoading,
  notSortable,
  withOpenedMenu
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [isMenuOpened, setIsMenuOpened] = useState(withOpenedMenu || false);
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta, { setValue }] = useField({
    name,
    required
  });

  const valueField = valueName || "newname";
  const value = field.value;

  // Настройки выпадающего списка в строгом порядке, т.к. выполняются по порядку
  const { refs, floatingStyles, context } = useFloating({
    open: isMenuOpened, // Открыт ли список
    onOpenChange: setIsMenuOpened, // Функция, изменяющая состояние списка
    placement: placement || "bottom-end", // Положение списка
    // whileElementsMounted - функция, которая вызывается при монтировании ссылочных и плавающих элементов
    // возвращает функцию очистки, вызываемую при их размонтировании.
    // autoUpdate - функция, которая гарантирует, что плавающий элемент остается привязанным к ссылочному элементу
    whileElementsMounted: autoUpdate,
    middleware: [
      // Модификатор размещения, который перемещает плавающий элемент вдоль указанных осей.
      // Нужно для добавления расстояния между ссылкой и плавающим элементом
      offset(10),
      // Оптимизатор видимости, который меняет расположение плавающего элемента
      // чтобы он оставался в поле зрения с возможностью перевернуть его в любое место.
      flip({ fallbackAxisSideDirection: "end" }),
      // Оптимизатор видимости, который сдвигает плавающий элемент по указанным осям, чтобы он оставался в поле зрения.
      shift()
    ]
  });

  // Слушатели событий для изменения состояния списка
  const click = useClick(context);
  const dismiss = useDismiss(context, {
    // Закрытие списка при клике на ESC
    escapeKey: true
  });
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role
  ]);

  useEffect(() => {
    if (value === null || value === "" || value === undefined)
      return setSelectedOption(value);
    if (label) {
      setSelectedOption(label);
    } else {
      if (options[value]) {
        setSelectedOption(options[value].title);
      } else
        Object.values(options).find((option: OptionWithTitle) => {
          if (option[valueField] === value) setSelectedOption(option.title);
        });
    }
  }, [value, options]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  return (
    <div>
      <div
        id={`${name}_select`}
        className={`position-relative ${
          meta.error && meta.touched ? styles.error : ""
        } ${disabled ? styles.disabled : ""}`}
        onFocus={onFocus && onFocus}
        ref={refs.setReference}
        aria-labelledby="select-label"
        {...getReferenceProps()}
      >
        <div
          className={`
        ${
          isSelectForTable
            ? styles.withPlaceholderContainer
            : styles.valueContainer
        } ${!selectedOption ? styles.inputPrompt : ""} ${
            isMenuOpened ? styles.openedMenu : ""
          }`}
          onClick={() => setFieldTouched(field.name)}
          id={`select_container_${name}`}
        >
          {isFloating && selectedOption ? (
            <p
              className={`${styles.label_floating} ${
                required ? styles.label_floating_required : ""
              }`}
            >
              <span className={mark ? styles.label_withMark : ""}>
                {title}
                {mark ? mark : null}
              </span>
            </p>
          ) : isSelectForTable ? (
            <p
              className={`${styles.label_bold}
          ${required ? styles.label_bold__required : ""}`}
            >
              {title}
            </p>
          ) : null}
          {isSelectForTable && placeholderVisible ? (
            <p className={styles.placeholder}>
              {selectedOption || placeholder}
            </p>
          ) : (
            <p
              className={`${styles.label}
            ${isFloating && selectedOption ? styles.isFloating : ""}
            ${
              required && (!isFloating || !selectedOption)
                ? styles.label_required
                : ""
            } ${!selectedOption ? styles.label_withoutValue : ""}`}
            >
              {selectedOption || title}
            </p>
          )}

          <div className={styles.buttons}>
            <IconExpand
              className={`${
                isMenuOpened
                  ? styles.buttons__openBtn_transformed
                  : styles.buttons__openBtn
              } ${isSelectForTable ? styles.iconExpand : ""} `}
            />
            {withClearBtn && !disabled ? (
              <Button
                theme={ButtonTheme.ROUND}
                disabled={!field.value}
                onClick={() => {
                  if (field.value) {
                    setFieldValue(field.name, null);
                    setFieldTouched(field.name);
                  }
                }}
              >
                <IconMinus
                  id={`Select_clearBtn_${field.name}`}
                  className={classNames(styles.clearBtn, {
                    [styles.clearBtnDisabled]: !field.value
                  })}
                  data-clear={true}
                />
              </Button>
            ) : null}
          </div>
        </div>
        {meta.error && meta.touched ? (
          <div className={styles.errorMsg}>
            {Object.values(meta.error).map((val) => {
              return val ? val : "";
            })}
          </div>
        ) : (
          ""
        )}
      </div>

      <FloatingPortal>
        {isMenuOpened && (
          <div
            className={`${styles.list} ${
              isSelectForTable ? styles.list_tableSelect : ""
            } `}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            data-list="true"
          >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ItemsScrollBoard
                options={options}
                values={value}
                valueName={valueName}
                notSearchable={
                  options &&
                  Object.values(options).length <= 10 &&
                  !isSearchable
                }
                isSearchWithPagination={isSearchWithPagination}
                page={page}
                prevPage={prevPage}
                maxPage={maxPage}
                setPage={setPage}
                getList={getList}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                isLoading={isLoading}
                withEmptyValue={!required}
                addItem={(option) => {
                  setValue(option[valueField]);
                  setSelectedOption(option["title"]);
                  onClick && onClick(option);
                  setIsMenuOpened(false);
                  setTimeout(() => {
                    setFieldTouched(name);
                  }, 100);
                }}
                notSortable={notSortable}
              />
            </ErrorBoundary>
          </div>
        )}
      </FloatingPortal>
    </div>
  );
};

export default Select;
