import styles from "./staffImportTableTitles.module.scss";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStores } from "stores";
import { classNames } from "shared/utils/helpers/classNames";
import { StaffImportTableForFormik } from "stores/StaffModule/types/StaffImportTable";
import Checkbox from "shared/ui/Inputs/Checkbox";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import SelectMultiTable from "shared/ui/TableInputs/SelectMultiTable";
import Tooltip from "shared/ui/Tooltip";
import StaffImportTableError from "../StaffImportTableError";
import { ReactComponent as IconSend } from "shared/assets/images/mainIcons/iconSend.svg";

const StaffImportTableTitles = () => {
  const { staffImportStore, menuStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");

  const { setFieldValue, setFieldTouched, values, errors } =
    useFormikContext<StaffImportTableForFormik>();

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  const handleSetSelect = () => {
    values["staff"].forEach((_item, ind) => {
      if (staffImportStore.staffStatus[ind] === "correct") {
        values["selectAll"]
          ? setFieldValue(`staff.${ind}.select`, 0)
          : setFieldValue(`staff.${ind}.select`, 1);
      }
    });
    setFieldTouched("selectAll");
  };

  const handleSetHoldingEdu = () => {
    values["staff"].forEach((_item, ind) => {
      values["holdingEduAll"]
        ? setFieldValue(`staff.${ind}.holding_edu`, 0)
        : setFieldValue(`staff.${ind}.holding_edu`, 1);
    });
    setFieldTouched("holdingEduAll");
  };

  const handleSetCompany = () => {
    values["companyAll"] &&
      values["companyAll"].length &&
      values["staff"].forEach((_item, ind) => {
        setFieldValue(`staff.${ind}.company`, values["companyAll"]);
        setTimeout(() => {
          setFieldTouched(`staff.${ind}.company`);
        }, 100);
      });
    setFieldTouched("companyAll");
  };

  const handleSetEduStartDate = () => {
    values["edu_date_start_all"] &&
      !errors["edu_date_start_all"] &&
      values["staff"].forEach((_item, ind) => {
        values["staff"][ind]?.["holding_edu"] &&
          setFieldValue(
            `staff.${ind}.edu_date_start`,
            values["edu_date_start_all"]
          );
        setTimeout(() => {
          setFieldTouched(`staff.${ind}.edu_date_start`);
        }, 100);
      });
  };

  return (
    <tr className={styles.tableTitle}>
      <td>
        <p>№</p>
      </td>
      {staffImportStore.currentTitles.map((title) => {
        switch (title) {
          case "select":
            return (
              <td key={title}>
                <div className={styles.selectCell}>
                  <p>Выбрать все</p>
                  <Checkbox
                    name="selectAll"
                    id="selectAll"
                    onChange={handleSetSelect}
                    disabled={
                      !Object.values(staffImportStore.staffStatus).some(
                        (status) => status === "correct"
                      )
                    }
                  />
                </div>
              </td>
            );
          case "company":
            return (
              <td key={title} className={styles.cellWithInput}>
                <p
                  className={classNames("", {
                    [styles.requiredField]:
                      staffImportStore.requiredFields.includes(title)
                  })}
                >
                  {staffImportStore.cols[title]}
                </p>
                <SelectMultiTable
                  key={title}
                  name="companyAll"
                  options={staffImportStore.companiesDict}
                  placeholder="Выберите компании"
                  valueName="id"
                  withBorder
                  isMassEdit
                  onClickMass={handleSetCompany}
                />
              </td>
            );
          case "edu_date_start":
            return (
              <td key={title} className={styles.cellWithInput}>
                <p
                  className={classNames("", {
                    [styles.requiredField]:
                      staffImportStore.requiredFields.includes(title)
                  })}
                >
                  {staffImportStore.cols[title]}
                </p>
                <div className={styles.datePickerCellWrapper}>
                  <DatePickerField
                    name="edu_date_start_all"
                    title=""
                    placeholderVisible
                    isCalendarOpened={openedListName === title}
                    setIsCalendarOpened={() => {
                      changeOpenedWindows(title);
                    }}
                    className={styles.datePickerCustom}
                    withoutError
                    placement="right"
                  />
                  <Tooltip
                    text={
                      values["edu_date_start_all"]
                        ? "Применить для всех"
                        : "Выберите дату, чтобы применить для всех"
                    }
                    placement="right"
                  >
                    <div className={styles.iconWrapper}>
                      <IconSend
                        id="StaffImportTableTitles_complete_edu_date_start"
                        onClick={handleSetEduStartDate}
                        className={
                          values["edu_date_start_all"] &&
                          !errors["edu_date_start_all"]
                            ? styles.iconSend
                            : styles.iconSend_disabled
                        }
                      />
                    </div>
                  </Tooltip>
                </div>
                <StaffImportTableError error={errors["edu_date_start_all"]} />
              </td>
            );
          case "holding_edu":
            return (
              <td key={title}>
                <div className={styles.selectCell}>
                  <p>{staffImportStore.cols[title]}</p>
                  <Checkbox
                    name="holdingEduAll"
                    id="holdingEduAll"
                    onChange={handleSetHoldingEdu}
                  />
                </div>
              </td>
            );
          case "phone_1":
            return (
              <td key={title}>
                <p
                  className={classNames("", {
                    [styles.requiredField]:
                      staffImportStore.requiredFields.includes("phone")
                  })}
                >
                  {staffImportStore.cols[title]}
                </p>
              </td>
            );

          default:
            return (
              <td
                key={title}
                className={classNames("", {
                  [styles.patronymic]: title === "patronymic",
                  [styles.patronymic__shadow]:
                    title === "patronymic" && menuStore.scroll["scrollLeft"]
                })}
              >
                <p
                  className={classNames("", {
                    [styles.requiredField]:
                      staffImportStore.requiredFields.includes(title)
                  })}
                >
                  {staffImportStore.cols[title]}
                </p>
              </td>
            );
        }
      })}
    </tr>
  );
};

export default observer(StaffImportTableTitles);
