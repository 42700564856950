import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";

import { format, parse } from "date-fns";
import { cloneDeep, isEmpty } from "lodash";

import { Col } from "stores/StaffModule/types/Col";
import { BuildingEventType } from "stores/StaffModule/types/BuildingEventType";
import { Staff } from "../types/BuildingStaffType";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Retention } from "stores/StaffModule/types/Retention";
import { Status } from "stores/StaffModule/types/Status";
import { SafetyWorkWidget } from "../types/SafetyWorkWidget";
import { Selects } from "../types/Selects";
import { Widgets } from "stores/StaffModule/types/Widgets";
import { IColForStatuses } from "stores/StaffModule/types/IColForStatuses";
import { StaffGuid } from "stores/StaffModule/types/StaffGuid";
import { getEntries } from "shared/utils/helpers/getEntries";

type Tab = {
  title: string;
  edit_over_price: boolean;
  cols: {
    [key: string]: string;
  };

  id?: string;
  color?: string;
  color_level?: boolean;
};

type StatusesInitialValuesType = {
  [key: string]:
    | Partial<{
        event_start: string;
        event_end: string | null;
        sub_start_date: string | null;
        sub_end_date: string | null;
        phone: string;
        money: number;
        date: string;
        without_edu: number;
      }> & { [key: string]: string | number | null };
};

type StatusesGroups = {
  [key: string]: {
    bool_column: {
      [key: string]: string[];
    };
    required_column: { [key: string]: number };
  };
};

type missingSelects = {
  selects: Selects;
  errors?: string;
};

export type InitialValuesForStatus = Omit<
  Partial<Status> & {
    transferbuilding?: string;
    building_parent?: string;
    close_transfer?: number;
    company?: string;
    money?: string | number;
    date?: string;
    without_edu?: number;
  },
  "author" | "future" | "months" | "level"
>;

// hardcode
const requiredCols = [
  "dismiss_type",
  "dismiss_reason",
  "dismiss_comment",
  "dismiss_object"
];

const dismiss_fields = [
  "dismiss_date",
  "dismiss_reason",
  "dismiss_type",
  "dismiss_comment",
  "dismiss_position",
  "black_list",
  "black_reason",
  "grey_list",
  "grey_reason"
];

export default class BuildingOneStaffStore {
  error: { [building_id: string]: boolean } = {};
  isLoading: { [building_id: string]: boolean } = {};
  isLoadingForTable: { [building_id: string]: boolean } = {};
  isLoadingAllowEdu = false;
  isLoadingForForm = false;
  isLoadingForSafetyworkWidget = false;
  isLoadingMoreStaff: { [building_id: string]: boolean } = {};
  // индикатор повторной загрузки таблицы сотрудников, при положительном значении которого
  // не прекращается isLoadingForTable, пока повторный запрос в процессе

  // становится true, когда выбран быстрый поиск по всем вкладкам,
  // но в текущей вкладке результатов нет и нужно загрузить первую по очереди вкладку,
  // в которой есть результат
  isReloadStaffTableData = false;

  onPage = 100;
  page: { [building_id: string]: number } = {};
  maxPage: { [building_id: string]: number } = {};
  prevPage = 1;

  dirty: { [building_id: string]: boolean } = {};
  errorsMessage: {
    [building_id: string]: { [key: string]: Errors["message"] };
  } = {};

  tabs: { [key: string]: Tab } = {};
  staffList: {
    [building_id: string]: {
      [tab_name: string]: {
        [key: string]: Staff;
      };
    };
  } = {};

  eventTypes: { [key: string]: BuildingEventType } = {};

  staffTableCols: {
    [key: string]: Partial<IColForStatuses>;
  } = {};
  safetyworkWidgets: {
    [building_id: string]: {
      [staff_id: string]:
        | { [key: string]: SafetyWorkWidget }
        | { error: string };
    };
  } = {};

  // вид поиска, где 1 (true) это поиск по всем вкладкам, а 0 (false) - поиск по текущей
  search_mode: { [building_id: string]: 0 | 1 } = {};
  search_value: { [building_id: string]: string } = {};

  // groups и initialValues и directories для формы изменения статуса сотрудника
  statusesGroups: StatusesGroups = {};
  statusesInitialValues: StatusesInitialValuesType = {};
  missingDirectories: Partial<Selects> = {};
  selectedStatus = "";

  activeTab: { [key: string]: string } = {};
  groupCount: { [building_id: string]: { [group: string]: number } } = {};
  openedType = "";

  openedAllEventAllowEdu: {
    [staff_id: string]: Retention;
  } = {};
  allUpdatedEventAllowEdu: {
    [staff_id: string]: Retention;
  } = {};

  versionHistory: {
    [building_id: string]: { [col_name: string]: Staff["invest"] };
  } = {};

  rootStore: RootStore;

  // hardcode
  dismissStatus = "e329ed9b615763361b86d0d31cc3aac6b620c262";
  withHoldingEduFields = ["money", "date", "without_edu"];

  // данные стафф-кодов
  staffGuidMessage: Record<string, Record<string, Errors["message"]>> = {};
  staffGuid: Record<string, StaffGuid> = {};

  setSelectedBuilding = (id: string) => {
    this.isLoading[id] = true;
    this.isLoadingForTable[id] = true;
    this.error[id] = false;

    this.rootStore.buildingOneStore.selectedOne = {};

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/building", "Объекты");
    }
    if (!this.rootStore.menuStore.allWindows[`/building/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/building/id=${id}`,
        "Загрузка..."
      );
      delete this.staffList[id];
    }

    if (
      this.staffList[id]?.[
        !this.activeTab[id]
          ? (this.activeTab[id] = Object.keys(this.tabs)[0])
          : this.activeTab[id]
      ]
    ) {
      runInAction(() => {
        this.rootStore.buildingOneStore.setSelectedBuildingForInfo(id);

        !this.activeTab[id]
          ? (this.activeTab[id] = Object.keys(this.tabs)[0])
          : "";

        this.isLoading[id] = false;
        this.isLoadingForTable[id] = false;
      });
    } else {
      Promise.all([
        this.rootStore.buildingOneStore.getBuildingOne(id),
        this.getBuildingStaffCols(id),
        this.getStaffCols(id),
        this.getBuildingEventTypeCols(id)
      ]).then(() => this.getBuildingStaffSelects(id));
    }
  };

  getBuildingStaffSelects = async (building_id: string) => {
    try {
      const data: ApiResponse<{ groups: { [key: string]: Tab } }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          method: "getBuildingStaffSelects"
        });

      runInAction(() => {
        if (data.code === 200) {
          if (data.result.groups) {
            this.tabs = data.result.groups;

            this.activeTab[building_id] = Object.keys(data.result.groups)[0];

            Promise.all([this.getBuildingEventType(building_id)]).then(() => {
              runInAction(() => (this.isLoading[building_id] = false));
              this.getBuildingStaffTable(
                building_id,
                Object.keys(data.result.groups)[0]
              );
            });
          } else {
            this.isLoading[building_id] = false;
            this.isLoadingForTable[building_id] = false;
          }
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[building_id] = true;
      });
    }
  };

  getBuildingStaffTable = async (
    building: string,
    group: string,
    isSearchOnlyOneGroup?: boolean
  ) => {
    this.page[building] ?? (this.page[building] = 1);
    this.isLoadingForTable[building] = true;

    try {
      const params = {
        building,
        group
      };

      if (this.search_value[building]) {
        params["search"] = this.search_value[building];
      }

      const data: ApiResponse<{ [staff_id: string]: Staff }> & {
        groups: { [key: string]: number };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "getBuildingStaffTable",
        on_page: this.onPage,
        params: params
      });

      runInAction(() => {
        this.staffList[building] ?? (this.staffList[building] = {});
        this.search_value[building] ?? (this.search_value[building] = "");
        this.search_mode[building] ?? (this.search_mode[building] = 1);
        this.errorsMessage[this.rootStore.menuStore.tabId] = {};

        if (
          this.search_value[building]?.length &&
          !isSearchOnlyOneGroup &&
          !data.groups[group]
        ) {
          let hasActiveGroup = false;
          Object.entries(data.groups).forEach(([group_id, count]) => {
            if (!hasActiveGroup && count > 0) {
              hasActiveGroup = true;
              this.activeTab[building] = group_id;
              this.isReloadStaffTableData = true;
              return this.getBuildingStaffTable(building, group_id);
            }
          });
        } else {
          if (data.code === 200) {
            this.staffList[building][group] = data.result;

            if (isSearchOnlyOneGroup) {
              const groups: { [key: string]: number } = {};
              Object.entries(data.groups).forEach(([group_id, count]) => {
                if (group_id === group) {
                  groups[group_id] = count;
                } else groups[group_id] = 0;
              });
              this.groupCount[building] = groups;
            } else this.groupCount[building] = data.groups;

            if (
              !Object.values(this.missingDirectories).length &&
              Object.keys(data.result).length
            ) {
              this.getMissingDirectories(Object.keys(data.result)[0]);
            }
            //   бэк решил при отсутствии сотрудников в одной из вкладок отправлять нам код 500
          } else if (data.code === 500) {
            this.staffList[building][group] = {};
          }

          this.maxPage[building] = data["nav"]["max_page"] || 1;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[building] = true;
      });
    } finally {
      runInAction(() => {
        if (this.isReloadStaffTableData) {
          this.isReloadStaffTableData = false;
        } else this.isLoadingForTable[building] = false;
      });
    }
  };

  getMoreBuildingStaff = async (building: string, group: string) => {
    this.isLoadingMoreStaff[building] = true;
    try {
      const data: ApiResponse<Record<string, Staff>> & {
        groups: { [key: string]: number };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "getBuildingStaffTable",
        on_page: this.onPage,
        page: this.page[building],
        params: { building, group }
      });

      runInAction(() => {
        !this.staffList[building] ? (this.staffList[building] = {}) : "";
        this.errorsMessage[this.rootStore.menuStore.tabId] = {};
        if (data.code === 200) {
          this.staffList[building][group] = {
            ...this.staffList[building][group],
            ...data.result
          };
          this.rootStore.menuStore.isScrollBottom = false;

          //   бэк решил при отсутствии сотрудников в одной из вкладок отправлять нам код 500
        } else if (data.code === 500) {
          this.staffList[building][group] = {};
        }
        this.prevPage = this.page[building];
      });
    } catch (error) {
      runInAction(() => {
        this.error[building] = true;
      });
    } finally {
      runInAction(() => (this.isLoadingMoreStaff[building] = false));
    }
  };

  getBuildingEventType = async (building_id: string) => {
    try {
      const data: ApiResponse<{ [key: string]: BuildingEventType } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building_events_type",
          method: "getList"
        });
      runInAction(() => {
        if (data["result"] !== -1) {
          this.eventTypes = data.result;

          const types = {
            active: "58f3be3209ee7f6c586d7a7e583b5e9eab8caac2",
            transfer_in: "a0f6708450dcb7ff0895a240457396a705a28811",
            transfer_out: "a0f6708450dcb7ff0895a240457396a705a28811",
            vacation: "5b700e8e92e40355f0f772c3ab9de6615d585cf2",
            dismiss: "e329ed9b615763361b86d0d31cc3aac6b620c262"
          };

          Object.keys(this.tabs).forEach((tab) => {
            if (types[tab]) {
              const event_type: BuildingEventType = data.result[types[tab]];

              if (tab.includes("transfer") && tab === "transfer_in") {
                this.tabs.transfer_in.id = event_type.id;
                this.tabs.transfer_out.id = event_type.id;

                this.tabs.transfer_in.color = event_type.custom.color_api;
                this.tabs.transfer_out.color = event_type.custom.color_api;

                this.tabs.transfer_in.color_level = true;
              } else if (tab !== "transfer_in") {
                this.tabs[tab].id = event_type.id;
                this.tabs[tab].color = event_type.custom.color_api;
              }
            } else {
              this.tabs[tab].id = tab;
              this.tabs[tab].color = "light-green";
              this.tabs[tab].color_level = true;
            }
          });

          Object.entries(data.result).forEach(([key, status]) => {
            !this.statusesInitialValues[key]
              ? (this.statusesInitialValues[key] = {})
              : "";
            !this.statusesGroups[key]
              ? (this.statusesGroups[key] = {
                  bool_column: {},
                  required_column: {}
                })
              : "";

            this.statusesInitialValues[key]["event_start"] = null;
            this.statusesGroups[key].required_column.event_start = 1;

            if (status.custom && Object.values(status.custom)) {
              if (status.custom.date_end) {
                this.statusesInitialValues[key]["event_end"] = null;
                if (status.custom.date_end_required) {
                  this.statusesGroups[key].required_column["event_end"] = 1;
                }
              }

              if (status.custom.sub_date) {
                this.statusesInitialValues[key]["sub_start_date"] = null;
                this.statusesInitialValues[key]["sub_end_date"] = null;
              }

              if (Object.values(status.custom.staff_cols).length) {
                Object.values(status.custom.staff_cols).forEach((field) => {
                  if (this.staffTableCols[field]) {
                    switch (field) {
                      case "position":
                        this.staffTableCols[field].disabled = 1;
                        this.statusesInitialValues[key][field] =
                          "Должность не указана";
                        break;
                      case "transferbuilding":
                        this.statusesInitialValues[key][field] = "";
                        this.statusesGroups[key].required_column[field] = 1;
                        break;
                      case "dismiss_position":
                        this.statusesInitialValues[key][field] = "";
                        break;
                      case "dismiss_date":
                        this.statusesInitialValues[key][field] = null;
                        break;
                      default:
                        if (this.staffTableCols[field].type === "date") {
                          this.statusesInitialValues[key][field] = null;
                        } else if (this.staffTableCols[field].type === "bool") {
                          this.statusesInitialValues[key][field] = 0;
                        } else if (field.includes("comment")) {
                          this.statusesInitialValues[key][field] = "";
                        } else if (
                          this.staffTableCols[field].default === "NULL" ||
                          this.staffTableCols[field].default ===
                            "CURRENT_TIMESTAMP"
                        ) {
                          this.statusesInitialValues[key][field] = null;
                        } else {
                          this.statusesInitialValues[key][field] = this
                            .staffTableCols[field].default as string;
                        }
                        break;
                    }

                    if (requiredCols.includes(field)) {
                      this.statusesGroups[key].required_column[field] = 1;
                    }
                  }
                });
              }

              if (Object.values(status.custom.bool_cols).length) {
                Object.entries(status.custom.bool_cols).forEach(
                  ([parentField, bool]) => {
                    Object.values(bool).forEach((field) => {
                      if (
                        this.staffTableCols[field] &&
                        !Object.keys(
                          Object.values(status.custom.staff_cols)
                        ).includes(field)
                      ) {
                        !this.statusesGroups[key].bool_column[parentField]
                          ? (this.statusesGroups[key].bool_column[parentField] =
                              [])
                          : "";

                        !this.statusesGroups[key].bool_column[
                          parentField
                        ].includes(field) &&
                          this.statusesGroups[key].bool_column[
                            parentField
                          ].push(field);

                        this.statusesInitialValues[key][field] =
                          this.staffTableCols[field].default === "NULL" ||
                          this.staffTableCols[field].default ===
                            "CURRENT_TIMESTAMP"
                            ? null
                            : (this.staffTableCols[field].default as string);
                      }
                    });
                  }
                );
              }
            }
          });
        } else this.error[building_id] = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[building_id] = true;
      });
    }
  };

  getBuildingEventTypeCols = async (building_id: string) => {
    try {
      const data: ApiResponse<{ [key: string]: Col } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building_staff_events",
          method: "getTableCols"
        });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.entries(data["result"]).forEach(([key, field]) => {
            if (!this.staffTableCols[key]) {
              this.staffTableCols[key] = field;
            }
          });
          this.staffTableCols.transferbuilding = {
            default: "",
            length: 100,
            newname: "transferbuilding",
            title: "Перемещение на другой объект",
            type: "varchar"
          };
        } else this.error[building_id] = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[building_id] = true;
      });
    }
  };

  getBuildingStaffCols = async (building_id: string) => {
    try {
      const data: ApiResponse<{ [key: string]: Col } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building_staff",
          method: "getTableCols"
        });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.entries(data["result"]).forEach(([key, field]) => {
            if (!this.staffTableCols[key]) {
              this.staffTableCols[key] = field;
            }
          });

          // hardcode
          this.staffTableCols["safetywork"] = {
            title: "Статус сертификата",
            newname: "safetywork"
          };
          this.staffTableCols["index"] = {
            title: "№",
            newname: "index"
          };
          this.staffTableCols["total_over_price_aregister"] = {
            title: "Итоговая ставка по Ч-Ч",
            newname: "total_over_price_aregister"
          };
          this.staffTableCols["FIO"] = {
            title: "ФИО",
            newname: "FIO"
          };
          this.staffTableCols["over_price_aregister"].title = "Ставка по Ч-Ч";
          this.staffTableCols["over_price"].title = "Надбавка по заявке";
          this.staffTableCols["uid"].title = "ТН";
        } else this.error[building_id] = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[building_id] = true;
      });
    }
  };

  getStaffCols = async (building_id: string) => {
    try {
      const data: ApiResponse<{ [key: string]: Col } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          currentClass: "staff",
          method: "getTableCols"
        });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.entries(data["result"]).forEach(([key, field]) => {
            if (!this.staffTableCols[key]) {
              this.staffTableCols[key] = field;
            }
          });
        } else this.error[building_id] = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[building_id] = true;
      });
    }
  };

  setActiveTab = (value: string, building_id: string) => {
    this.activeTab[building_id] = value;
    this.safetyworkWidgets[building_id] = {};
    if (!this.staffList[building_id]?.[this.activeTab[building_id]]) {
      this.getBuildingStaffTable(building_id, value);
    } else if (this.isLoadingForTable[building_id]) {
      this.isLoadingForTable[building_id] = false;
    }
  };

  setSelectedStatus = (id: string, staff_id: string) => {
    if (this.selectedStatus !== id) {
      this.selectedStatus = id;
      staff_id?.length && this.checkEventAllowEdu(staff_id, id);
    }
  };

  changeOpenedType = (value: string) => {
    this.openedType = this.openedType === value ? "" : value;
  };

  getEventTypeOptions = (building_id: string) => {
    const transit_cols: string[] = Object.values(
      this.eventTypes[this.tabs[this.activeTab[building_id]].id]?.custom
        ?.transit_cols
    );
    const options: BuildingEventType[] = this.eventTypes[
      this.tabs[this.activeTab[building_id]].id
    ]?.custom?.ignore_transit_cols_bool
      ? Object.values(this.eventTypes)
      : [];

    if (
      !this.eventTypes[this.tabs[this.activeTab[building_id]].id]?.custom
        ?.ignore_transit_cols_bool
    ) {
      Object.values(transit_cols).forEach((id) => {
        options.push(this.eventTypes[id]);
      });
    }

    return options;
  };

  addNewStaffEvent = async (
    value: InitialValuesForStatus,
    staff_id: string
  ) => {
    this.cleanErrorMessage();
    this.isLoadingForForm = true;

    const formData: InitialValuesForStatus = {
      uid: staff_id,
      ...cloneDeep(value)
    };

    Object.entries(formData).forEach(([key, fieldValue]) => {
      if (key === "without_edu") {
        if (formData["without_edu"] === 0) {
          formData["without_edu"] = 1;
        } else if (formData["without_edu"] === 1) {
          delete formData[key];
        }
      } else {
        !fieldValue ? delete formData[key] : "";
      }
    });

    delete formData["company"];

    if (value.transferbuilding) {
      formData.building_parent = this.rootStore.menuStore.tabId;
      formData.building = value.transferbuilding;
      delete formData.transferbuilding;
    } else {
      formData.building = this.rootStore.menuStore.tabId;
    }

    if (
      // тип нового статуса - Активен
      this.eventTypes[value.type]?.custom?.active &&
      // тип последнего статуса - Трансфер
      this.activeTab[this.rootStore.menuStore.tabId]?.includes("transfer")
    ) {
      // обязательный параметр при принятии из Трансфера с статусом "Активен"
      formData.close_transfer = 1;
    }

    // тип нового статуса - Уволен
    if (value.type === this.dismissStatus) {
      // для добавления статуса меняем имена параметров для dismiss-полей
      dismiss_fields.forEach((dismiss_field) => {
        if (dismiss_field in formData) {
          formData[`extra_col[${dismiss_field}]`] = formData[dismiss_field];
          delete formData[dismiss_field];
        }
      });
    }

    // если для статуса можно проводит фин.удержания и на выбранную дату удержания есть
    if (
      this.eventTypes[value.type].custom.close_withholding &&
      this.openedAllEventAllowEdu[staff_id].result
    ) {
      // а таже в форме заполнены поля удержаний
      if (!formData["without_edu"]) {
        this.withHoldingEduFields.forEach((edu_field) => {
          if (edu_field !== "without_edu" && edu_field in formData) {
            // то переименовываем параметр
            formData[`close_withholding_edu[${edu_field}]`] =
              edu_field === "money"
                ? formData[edu_field] && isNaN(formData[edu_field] as number)
                  ? (formData[edu_field] as string)
                      .slice(0, -2)
                      .replace(",", ".")
                      .replace(/\s/g, "")
                  : formData[edu_field]
                : formData[edu_field];
          }
        });
      }
    }

    // и удаляем все поля удержаний (в любом случае)
    this.withHoldingEduFields.forEach((field) => {
      field in formData && delete formData[field];
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "building",
        method: "addStaffEvent",
        body: { ...formData }
      });

      if (data.result) {
        if (
          value.event_start &&
          parse(value.event_start, "yyyy-MM-dd", new Date())
        ) {
          this.setSelectedBuilding(this.rootStore.menuStore.tabId);
        } else {
          return;
        }
      } else if (data.message) {
        runInAction(() => {
          this.errorsMessage[this.rootStore.menuStore.tabId].add = data.message;
          this.isLoadingForForm = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
        this.isLoadingForForm = false;
      });
    }
  };

  updateStaffEvent = async (
    building_id: string,
    staff_id: string,
    col_name: string,
    values: {
      event_id: string;
      event_start?: string;
      event_end?: string;
      comment?: string;
    }
  ) => {
    this.cleanErrorMessage();
    const formData: InitialValuesForStatus = {};

    Object.entries(values).forEach(([key, value]) => {
      key === "event_id"
        ? (formData[key] = value)
        : (formData[`update[${key}]`] = value ?? "");
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "building",
        method: "updateStaffEvent",
        body: { ...formData }
      });

      this.errorsMessage[this.rootStore.menuStore.tabId] = {};

      if (data.result) {
        this.dirty[building_id] = true;
        this.errorsMessage[this.rootStore.menuStore.tabId].update &&
          delete this.errorsMessage.update;
        const id = this.rootStore.menuStore.tabId;
        this.staffList[id][this.activeTab[id]][staff_id][col_name] =
          values[col_name];
      } else {
        this.errorsMessage[this.rootStore.menuStore.tabId].update =
          data.message;
      }
    } catch (error) {
      runInAction(() => {
        this.error[building_id] = true;
      });
    }
  };

  checkEventAllowEdu = async (
    staff_id: string,
    event_id: string,
    date?: string
  ) => {
    this.isLoadingForForm = true;
    this.openedAllEventAllowEdu[staff_id]
      ? delete this.openedAllEventAllowEdu[staff_id]
      : "";

    try {
      const data: Retention = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "checkEventAllowEdu",
        params: {
          uid: staff_id,
          event: event_id,
          date: date ? date : format(new Date(), "yyyy-MM-dd")
        }
      });

      runInAction(() => {
        if (
          data.code === 200 &&
          this.eventTypes[event_id]?.custom?.close_withholding
        ) {
          this.openedAllEventAllowEdu[staff_id] = {
            status: event_id,
            date: date ? date : format(new Date(), "yyyy-MM-dd"),
            ...data
          };
        } else {
          this.openedAllEventAllowEdu[staff_id]
            ? delete this.openedAllEventAllowEdu[staff_id]
            : "";
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    } finally {
      runInAction(() => (this.isLoadingForForm = false));
    }
  };

  updateEventAllowEdu = async (
    staff_id: string,
    event_id: string,
    date?: string
  ) => {
    this.isLoadingAllowEdu = true;
    this.allUpdatedEventAllowEdu[staff_id]
      ? delete this.allUpdatedEventAllowEdu[staff_id]
      : "";

    try {
      const data: Retention = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "checkEventAllowEdu",
        params: {
          uid: staff_id,
          event: event_id,
          date: date ? date : format(new Date(), "yyyy-MM-dd")
        }
      });

      runInAction(() => {
        if (
          data.code === 200 &&
          this.eventTypes[event_id]?.custom?.close_withholding
        ) {
          this.allUpdatedEventAllowEdu[staff_id] = {
            status: event_id,
            date: date ? date : format(new Date(), "yyyy-MM-dd"),
            ...data
          };
        } else {
          this.allUpdatedEventAllowEdu[staff_id]
            ? delete this.allUpdatedEventAllowEdu[staff_id]
            : "";
        }
        this.isLoadingAllowEdu = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
        this.isLoadingAllowEdu = false;
      });
    }
  };

  cleanErrorMessage = () => {
    this.errorsMessage[this.rootStore.menuStore.tabId] &&
      delete this.errorsMessage[this.rootStore.menuStore.tabId];
  };

  getOverPriceHistory = async (rid: string) => {
    try {
      const data: ApiResponse<Staff["invest"]> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "core",
          currentClass: "version",
          method: "getRecords",
          params: {
            rid,
            key: "over_price_aregister"
          }
        });

      runInAction(() => {
        if (data.code === 200) {
          !this.versionHistory[this.rootStore.menuStore.tabId]
            ? (this.versionHistory[this.rootStore.menuStore.tabId] = {})
            : "";

          this.versionHistory[this.rootStore.menuStore.tabId]["over_price"] =
            data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  addOverPriceData = async (
    rid: string,
    start_date: string,
    value: string | number
  ) => {
    this.cleanErrorMessage();
    this.isLoadingForForm = true;
    const formData = {
      rid,
      key: "over_price_aregister",
      start_date,
      value,
      company: this.rootStore.buildingOneStore.selectedOne.company
    };

    if (!formData["start_time"]) {
      delete formData["start_time"];
    }

    try {
      const data: ApiResponse<string> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "core",
        currentClass: "version",
        method: "add",
        body: formData
      });

      if (data["result"]) {
        this.getOverPriceHistory(rid);
      } else {
        runInAction(() => {
          this.errorsMessage[this.rootStore.menuStore.tabId]["over_price"] =
            data["message"];
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  getOVRecords = async (key: string, rid: string) => {
    try {
      const data: ApiResponse<Staff["invest"]> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getOVData",
          params: {
            rid,
            key,
            company: this.rootStore.buildingOneStore.selectedOne.company,
            auto_priority: 1
          }
        });

      runInAction(() => {
        if (data.code === 200) {
          !this.versionHistory[this.rootStore.menuStore.tabId]
            ? (this.versionHistory[this.rootStore.menuStore.tabId] = {})
            : "";

          this.versionHistory[this.rootStore.menuStore.tabId][key] =
            data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  addOVData = async (
    key: string,
    rid: string,
    start_date: string,
    value: string,
    staff_guid: string,
    staff_code: string
  ) => {
    this.cleanErrorMessage();
    this.isLoadingForForm = true;
    const formData = {
      rid,
      key,
      start_date,
      value: value ?? "",
      company: this.rootStore.buildingOneStore.selectedOne.company,
      staff_guid,
      staff_code
    };

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "addOVData",
        body: formData
      });

      if (data["result"]) {
        this.getOVRecords(key, rid);
      } else {
        runInAction(() => {
          this.errorsMessage[this.rootStore.menuStore.tabId][key] =
            data["message"];
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  updateStaffComment = async (id: string, comment: string) => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        currentClass: "staff",
        method: "update",
        params: { comment, id }
      });

      if (data["result"]) {
        this.dirty[this.rootStore.menuStore.tabId] = true;
        this.staffList[this.rootStore.menuStore.tabId][
          this.activeTab[this.rootStore.menuStore.tabId]
        ][id]["comment"] = comment;
      } else {
        runInAction(() => {
          this.errorsMessage[this.rootStore.menuStore.tabId].comment =
            data["message"];
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  setDirty = () => {
    this.dirty[this.rootStore.menuStore.tabId] = false;
  };

  getActualWidgetByCompany = async (staff_id: string, building_id: string) => {
    this.isLoadingForSafetyworkWidget = true;
    try {
      const data: ApiResponse<{ [key: string]: SafetyWorkWidget }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          method: "getActualWidgetByCompany",
          params: {
            uid: staff_id,
            company: this.rootStore.buildingOneStore.selectedOne.company
          }
        });
      runInAction(() => {
        if (data.code === 200) {
          !this.safetyworkWidgets[building_id]
            ? (this.safetyworkWidgets[building_id] = {})
            : "";
          if (!data.message) {
            this.safetyworkWidgets[building_id][staff_id] = data.result;
          } else {
            this.safetyworkWidgets[building_id][staff_id] = {
              error: data.message.head
            };
          }
        } else this.error[building_id] = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error[building_id] = true;
      });
    } finally {
      runInAction(() => (this.isLoadingForSafetyworkWidget = false));
    }
  };

  getStatusesForOneOfStaff = async (id: string) => {
    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          staff_fields:
            "surname, name, patronymic, id, company, uid, photo, position",
          widgets: ["building_event_comment"],
          cols: "staff, building_staff_events, building_planned_object",
          selects:
            "dismiss_reason, type, status, dismiss_type, buildings, position, company, grazd, vakans, ist_inf, reason_off, size, education, soisk_status,place_of_study, manager_mass,region, building_events_type"
        }
      });

      if (!data["errors"]) {
        if (Object.keys(data.selects).length) {
          Object.entries(data.selects).forEach(([key, value]) => {
            switch (key) {
              case "buildings":
                Object.entries(value as Widgets["selects"]["building"]).forEach(
                  ([id, buildings]) => {
                    Object.values(buildings).forEach((building) => {
                      const addBuildingsDirectory = (fieldName: string) => {
                        !this.staffTableCols[fieldName].directory
                          ? (this.staffTableCols[fieldName].directory = {})
                          : "";
                        !this.staffTableCols[fieldName].directory[id]
                          ? (this.staffTableCols[fieldName].directory[id] = {})
                          : "";
                        this.staffTableCols[fieldName]?.directory?.[id]
                          ? (this.staffTableCols[fieldName].directory[id][
                              building.id
                            ] = {
                              newname: building.id,
                              title: building.title
                            })
                          : "";
                      };
                      if (this.staffTableCols["building"]) {
                        addBuildingsDirectory("building");
                      }

                      if (this.staffTableCols["transferbuilding"]) {
                        addBuildingsDirectory("transferbuilding");
                      }

                      if (this.staffTableCols["dismiss_object"]) {
                        addBuildingsDirectory("dismiss_object");
                      }
                    });
                  }
                );
                break;
              default:
                key !== "building_event_type" &&
                  Object.values(value).forEach((item) => {
                    this.staffTableCols[key] &&
                    !this.staffTableCols[key]?.directory
                      ? (this.staffTableCols[key].directory = {})
                      : "";
                    this.staffTableCols[key]?.directory
                      ? (this.staffTableCols[key].directory[
                          item.id ? item.id : item.title
                        ] = {
                          newname: item.id ? item.id : item.title,
                          title: item.title
                        })
                      : "";
                  });
                break;
            }
          });
          if (Object.values(this.missingDirectories).length) {
            Object.entries(this.missingDirectories).forEach(([key, value]) => {
              if (!this.staffTableCols[key]?.directory) {
                this.staffTableCols[key] && !this.staffTableCols[key]?.directory
                  ? (this.staffTableCols[key].directory = {})
                  : "";
                Object.values(value).forEach((item) => {
                  this.staffTableCols[key]?.directory
                    ? (this.staffTableCols[key].directory[
                        item.id ? item.id : item.title
                      ] = {
                        newname: item.id ? item.id : item.title,
                        title: item.title
                      })
                    : "";
                });
              }
            });
          }
        }
      }
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  // справочники из метода getOne, удалить getMissingDirectories и missingDirectories, когда будут приходить в методу getWidgets
  // Недостающие справочники
  // grazd, vakans, ist_inf, reason_off, size, education, soisk_status,place_of_study, manager_mass,region
  getMissingDirectories = async (id: string) => {
    try {
      const data: missingSelects = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getOne",
        params: {
          staff_id: id,
          action: "edit"
        }
      });

      if (!data.errors) {
        if (Object.keys(data["selects"]).length) {
          Object.entries(data["selects"]).forEach(([key, value]) => {
            if (key == "position") return;
            this.missingDirectories[key] = {};
            Object.values(value).forEach((item) => {
              runInAction(() => {
                this.missingDirectories[key][item.id ? item.id : item.title] = {
                  newname: item.id ? item.id : item.title,
                  title: item.title
                };
              });
            });
          });
        }
        this.getStatusesForOneOfStaff(id);
      } else {
        runInAction(() => {
          this.error[this.rootStore.menuStore.tabId] = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  setPage = (value: number) => {
    if (!this.isLoading[this.rootStore.menuStore.tabId]) {
      this.page[this.rootStore.menuStore.tabId] = value;
    }
  };

  setSearchValue = (value: string) => {
    this.search_value[this.rootStore.menuStore.tabId] = value;
  };

  setSearchMode = (value: typeof this.search_mode[string]) => {
    this.search_mode[this.rootStore.menuStore.tabId] = value;
  };

  selectStaffGuid = async (uid: string, company: string, date: string) => {
    this.staffGuid[uid] && delete this.staffGuid[uid];
    this.staffGuidMessage[uid] = {};

    try {
      const data: ApiResponse<StaffGuid> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "building",
          method: "selectStaffGuid",
          params: { uid, company, date }
        });

      if (data.code === 200) {
        this.staffGuid[uid] = data.result;
        if (!isEmpty(this.staffGuid[uid].all_guids)) {
          getEntries(this.staffGuid[uid].all_guids).forEach(
            ([staff_guid, staff_guid_data]) => {
              !this.staffGuid[uid].list && (this.staffGuid[uid].list = {});

              this.staffGuid[uid].list[staff_guid] = {
                id: staff_guid,
                title: staff_guid_data.staff_code_and_position
              };

              if (staff_guid_data.message) {
                this.staffGuidMessage[uid][staff_guid] = {
                  head: staff_guid_data.message,
                  color: "danger",
                  body: {}
                };
              }
            }
          );
        }

        if (data.result.message) {
          this.staffGuidMessage[uid].warning = {
            head: data.result.message,
            color: "warning",
            body: {}
          };
        }
      } else if (data.message) {
        runInAction(() => (this.staffGuidMessage[uid].error = data.message));
      }
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
