import styles from "./truddogTabs.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { ICompany } from "stores/StaffModule/types/RecordsForEdit";
import { useParams } from "react-router-dom";
import { getEntries } from "shared/utils/helpers/getEntries";
import { classNames } from "shared/utils/helpers/classNames";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type TruddogTabsProps = {
  values: { companies: Record<string, ICompany>; company: string[] };
};

const TruddogTabs = ({ values }: TruddogTabsProps) => {
  const { staffOneEditStore } = useStores();
  const { id } = useParams();

  const employments =
    values.companies?.[staffOneEditStore.selectedCompanyId[id]]?.employments;

  const truddogSorted = getEntries(employments).sort((a, b) => {
    const firstDate = a[1].trud_dog.start_date;
    const secondDate = b[1].trud_dog.start_date;
    if (firstDate === secondDate) return 0;
    else if (firstDate < secondDate) return 1;
    else return -1;
  });

  const handleOnClickBtn = (key: string) => () =>
    staffOneEditStore.setSelectedTypeOfJob(id, key);

  return (
    <div className={styles.tabsContainer}>
      {truddogSorted.map(([key, values]) => (
        <Chips
          variation={ChipsVariation.FILTER}
          key={key}
          id={`TruddogTabs_selectJob_${key}`}
          className={classNames("", {
            [styles.selectedGrayBtn]:
              staffOneEditStore.selectedTypeOfJob[id] === key,
            [styles.grayBtn]: staffOneEditStore.selectedTypeOfJob[id] !== key
          })}
          onClick={handleOnClickBtn(key)}
        >
          <p className={styles.title}>{values.employment_title || ""}</p>
          <p
            className={classNames(styles.status, {
              [styles.statusSelected]:
                staffOneEditStore.selectedTypeOfJob[id] === key
            })}
          >
            {key}{" "}
            <span
              className={classNames(styles.statusValue, {
                [styles.statusSelected]:
                  staffOneEditStore.selectedTypeOfJob[id] === key
              })}
            >
              {values.status}
            </span>
          </p>
        </Chips>
      ))}
    </div>
  );
};

export default observer(TruddogTabs);
