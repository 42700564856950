import styles from "./clearButton.module.scss";
import { useField } from "formik";

import { ReactComponent as IconMinus } from "shared/assets/images/mainIcons/iconMinus.svg";
import { Button, ButtonTheme } from "shared/ui/Button";
import { classNames } from "shared/utils/helpers/classNames";

type ClearButtonProps = {
  name: string;
  className: string;
  onClick: () => void;
};

export const ClearButton = ({ name, className, onClick }: ClearButtonProps) => {
  const [field] = useField(name);

  return (
    <Button
      theme={ButtonTheme.ROUND}
      disabled={!field.value}
      className={classNames("", { [styles.clearBtnHovered]: field.value }, [
        className
      ])}
      onClick={onClick}
    >
      <IconMinus
        id={`ClearButton_clearBtn_${field.name}`}
        className={classNames(styles.clearBtnImg, {
          [styles.clearBtnDisabled]: !field.value
        })}
      />
    </Button>
  );
};
