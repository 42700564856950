import styles from "./staffOneHistoryOne.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";

import icon1C from "shared/assets/images/mainIcons/icon1C.svg";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type StaffOneHistoryOneProps = {
  user_name: string | number;
  time: string;
  historyItems: { [key: string]: string | number }[];
};

const StaffOneHistoryOne = ({
  user_name,
  time,
  historyItems
}: StaffOneHistoryOneProps) => {
  const { staffOneAllSectionsStore } = useStores();

  return (
    <div className={styles.storyContainer}>
      <div className={styles.storyWrapper}>
        <div className={styles.storyData}>
          <div className={styles.date}>
            {getFormattedDate(time).replace(/ /g, ", в ")}
          </div>
          <div className={styles.name}>
            {user_name === "crm_bot" ? (
              <img src={icon1C} alt="icon" />
            ) : (
              user_name
            )}
          </div>
        </div>
        {historyItems.map((item) => {
          const { id, text, company } = item;
          return (
            <div className={styles.storyContent} key={id}>
              {company && (
                <div
                  className={
                    styles[
                      `company_${
                        Object.values(
                          staffOneAllSectionsStore.companiesData
                        ).find((companyData) => companyData.title === company)
                          ?.color || "primary"
                      }`
                    ]
                  }
                >
                  {company}
                </div>
              )}
              <div className={styles.text}>{text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(StaffOneHistoryOne);
