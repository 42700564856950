import { format } from "date-fns";
import { cloneDeep, isEqual } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { StaffImportTableType, StaffStatus } from "./types/StaffImportTable";
import { Errors } from "stores/utils/types/ErrorsType";
import { TableParams } from "stores/utils/types/TableParams";
import { ApiResponse } from "stores/utils/types/ApiResponse";

type ColsForDuplicateCheck = {
  name: string;
  default: boolean;
  selectable: boolean;
  title: string;
};

type VerificationResponse = {
  [key: string]: Partial<{
    approve: boolean;
    incorrect_cols: Record<string, string>;
    doubles: {
      [key: string]: { uid: number; staff_id: string; full_name: string };
    };
  }>;
};

export type ErrorDictTitle =
  | "requiredFields"
  | "duplicateRow"
  | "validationError"
  | "invalideResponse";

export default class StaffImportStore {
  isLoading = false; // лоадер при импорте таблицы с экселя
  isLoadingDuplicateCheck = false; // лоадер при вызове проверки на дубликат
  error = false;
  errorMessage: Partial<Errors["message"]> = {}; // ошибки с бэка
  dataForTable: StaffImportTableType[] = [];
  duplicate: Record<string, StaffImportTableType> = {}; // дубликаты из импортируемой таблицы
  errorsList: Errors["message"][] = []; // список текущих сообщений при импорте таблицы
  companiesDict: Record<string, { id: string; title: string }> = {};
  requiredFields: string[] = [];
  colsForDuplicateCheck: Record<string, ColsForDuplicateCheck> = {}; // список колонок, по которым можно осуществить проверку на дубли
  selectedDuplicateCols: string[] = []; // массив колонок, которые выбрали для проверки на дубли
  verificationResponse: VerificationResponse = {}; // ответ с бэка, который содержит данные после проверки на дубли и корректность заполнения полей
  staffStatus: Record<string, StaffStatus> = {}; // поле с указанием статуса для каждой строки таблицы. При изменении данных сотрудника после проверки также всегда меняем статус сотрудника в этом поле
  initialStaffStatus: Record<string, StaffStatus> = {}; // поле с указанием статуса для каждой строки таблицы. Это поле перезаписывается лишь после проверки с бэка. Изменения в таблице это поле не меняют
  duplicateColsResponse: Record<string, string[]> = {}; // поле, где ключом является индекс строки таблицы, а значением - массив ошибок дублей в данной строке
  countAddedStaff = 0; // количество добавленных записей
  currentTitles = [
    "select",
    "status",
    "surname",
    "name",
    "patronymic",
    "company",
    "holding_edu",
    "edu_date_start",
    "birthday",
    "pasp_n",
    "pasp_place",
    "pasp_date",
    "pasp_code",
    "propisk",
    "living_adress",
    "birth_place",
    "snils",
    "inn",
    "training_date_start",
    "training_date_finish",
    "place_of_study",
    "phone_1"
  ];

  cols = {
    select: "Выбрано все",
    status: "Статус",
    surname: "Фамилия",
    name: "Имя",
    patronymic: "Отчество",
    company: "Компании",
    holding_edu: "Вычет УЦ",
    edu_date_start: "Дата начала вычета",
    birthday: "Дата рождения",
    pasp_n: "Серия и номер паспорта",
    pasp_place: "Кем выдан",
    pasp_date: "Дата выдачи",
    pasp_code: "Код подразделения",
    propisk: "Адрес регистрации",
    living_adress: "Адрес фактического проживания",
    birth_place: "Место рождения",
    snils: "СНИЛС",
    inn: "ИНН",
    training_date_start: "Дата начала обучения",
    training_date_finish: "Дата окончания обучения",
    place_of_study: "Учебный центр",
    phone_1: "Номер телефона 1",
    phone: "Номер телефона"
  };

  // справочник возможных сообщений при импорте, которые выявляются на фронте
  errorsDict: Record<ErrorDictTitle, Errors["message"]> = {
    requiredFields: {
      head: "Заполните обязательные поля и запустите проверку на дубли",
      color: "danger",
      body: {}
    },
    duplicateRow: {
      head: "В загруженном файле Excel есть повторяющиеся записи:",
      color: "warning",
      body: {}
    },
    validationError: {
      head: "Некоторые данные заполнены некорректно",
      color: "danger",
      body: {}
    },
    invalideResponse: {
      head: "Найдены дублирующиеся поля",
      color: "danger",
      body: {}
    }
  };

  rootStore: RootStore;

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  // преобразовываем дату из таблицы в формат YYYY-MM-DD
  getDateParse = (value: Date | number | string | string[]) => {
    return typeof value === "object" && !Array.isArray(value)
      ? value.toLocaleDateString("en-CA")
      : typeof value === "number"
      ? new Date(Date.UTC(0, 0, value - 1)).toLocaleDateString("en-CA")
      : value;
  };

  // преобразовываем телефонный номер из таблицы в формат, в котором присутствуют одни цифры, а в начало подставляем цифру "7"
  getValidPhone = (value: Date | number | string | string[]) => {
    if ((typeof value === "number" || typeof value === "string") && value) {
      const validPhone = String(value).replace(/\D/g, "");

      if (!validPhone) {
        return "";
      }
      const digitsArray = validPhone
        .slice(validPhone.length === 11 ? 1 : 0)
        .split("");

      digitsArray.splice(0, 0, "7");

      return digitsArray.join("");
    } else {
      return "";
    }
  };

  setDataForTable = (value: StaffImportTableType[]) => {
    if (value.length) {
      Promise.all([
        !this.requiredFields.length && this.getFieldParams(),
        !Object.values(this.companiesDict).length && this.getCompaniesList(),
        !Object.values(this.colsForDuplicateCheck).length &&
          this.getDuplicateCheckCols()
      ])
        .then(() => {
          runInAction(() => {
            this.isLoading = false;
          });
        })
        .catch(() => {
          runInAction(() => {
            this.error = true;
          });
        });

      this.verificationResponse = {};
      this.staffStatus = {};
      this.initialStaffStatus = {};
      this.duplicateColsResponse = {};
      const preparatedValue: StaffImportTableType[] = [];
      value.forEach((item) => {
        const objForOneStaff: Partial<StaffImportTableType> = {};
        const row = Object.values(item);
        // так как названия мы должны привязываться к порядку чередования столбцов импортируемой таблицы, то есть к индексам
        // то для прописанных хардкодом currentTitles задаем значение полей согласно индексам таблицы
        this.currentTitles.forEach((title) => {
          // у поля снилс убираем все возможные символы "-" и пробелы
          const snilsWithoutSpaces = String(row[11])
            .replaceAll("-", "")
            .replaceAll(" ", "");
          switch (title) {
            case "surname":
              objForOneStaff[title] = row[0] || null;
              break;
            case "name":
              objForOneStaff[title] = row[1] || null;
              break;
            case "patronymic":
              objForOneStaff[title] = row[2] || null;
              break;
            case "company":
              objForOneStaff[title] = [];
              break;
            case "holding_edu":
              objForOneStaff[title] = 1;
              break;
            case "select":
              objForOneStaff[title] = 0;
              break;
            case "edu_date_start":
              objForOneStaff[title] = format(new Date(), "yyyy-MM-dd");
              break;
            case "birthday":
              objForOneStaff[title] = this.getDateParse(row[3]);
              break;
            case "pasp_n":
              objForOneStaff[title] = String(row[4]).replace(/\s+/g, "");
              break;
            case "pasp_place":
              objForOneStaff[title] = row[5];
              break;
            case "pasp_date":
              objForOneStaff[title] = this.getDateParse(row[6]);
              break;
            case "pasp_code": {
              if (row[7]) {
                // если pasp_code не содержит символ "-", то добавляем его
                objForOneStaff[title] = !String(row[7]).includes("-")
                  ? `${String(row[7]).substring(0, 3)}-${String(
                      row[7]
                    ).substring(3)}`
                  : row[7];
              } else {
                objForOneStaff[title] = null;
              }

              break;
            }
            case "propisk":
              objForOneStaff[title] = row[8];
              break;
            case "living_adress":
              objForOneStaff[title] = row[9];
              break;
            case "birth_place":
              objForOneStaff[title] = row[10];
              break;
            case "snils": {
              if (row[11]) {
                // добавляем символ "-" и пробелы в нужных местах согласно валидации
                objForOneStaff[title] = `${snilsWithoutSpaces.substring(
                  0,
                  3
                )}-${snilsWithoutSpaces.substring(
                  3,
                  6
                )}-${snilsWithoutSpaces.substring(
                  6,
                  9
                )} ${snilsWithoutSpaces.substring(9)}`;
              } else {
                objForOneStaff[title] = null;
              }

              break;
            }
            case "inn":
              objForOneStaff[title] = row[12] || null;
              break;
            case "training_date_start":
              objForOneStaff[title] = this.getDateParse(row[13]);
              break;
            case "training_date_finish":
              objForOneStaff[title] = this.getDateParse(row[14]);
              break;
            case "place_of_study":
              objForOneStaff[title] = row[15] || null;
              break;
            case "phone_1":
              objForOneStaff[title] = this.getValidPhone(row[16]);
              break;
            case "__rowNum__":
              break;
            default:
              if (!title.includes("phone_")) {
                objForOneStaff[title] = null;
              }
          }
        });

        // если столбцов импортируемой таблицы больше чем 17, то все столбцы, начиная с 17 индекса являются дополнительными номерами телефона
        // вносим их количество в currentTitles и cols. И также записываем их в значения данных каждого человека, у которых они указаны в импортируемой таблице
        if (row.length > 17) {
          row.slice(17).forEach((phoneNumber, ind) => {
            if (phoneNumber) {
              objForOneStaff[`phone_${ind + 2}`] =
                this.getValidPhone(phoneNumber);
            } else {
              objForOneStaff[`phone_${ind + 2}`] = "";
            }

            if (
              !this.currentTitles.includes(`phone_${ind + 2}`) &&
              phoneNumber
            ) {
              this.currentTitles.push(`phone_${ind + 2}`);
              this.cols[`phone_${ind + 2}`] = `Номер телефона ${ind + 2}`;
            }
          });
        }

        preparatedValue.push(objForOneStaff);
      });

      // ищем дублирующиеся строки и если они присутствуют, то заносим их в объект дубликатов (this.duplicate)
      preparatedValue.forEach((_element, ind, array) => {
        let iteration = 0;
        while (iteration <= array.length) {
          if (
            isEqual(array[ind], array[iteration]) &&
            ind !== iteration &&
            !(ind in this.duplicate)
          ) {
            this.duplicate[iteration] = { ...array[iteration], ind };
          }
          iteration++;
        }
      });
      // в this.dataForTable записываем отфильтрованный (без дубликатов) массив preparatedValue
      this.dataForTable = preparatedValue.filter(
        (_element, index) => !(index in this.duplicate)
      );

      // если есть дублирующиеся строки, то добавляем их в список сообщений с указанием количества повторов
      if (Object.values(this.duplicate).length) {
        const error = cloneDeep(this.errorsDict["duplicateRow"]);
        const subError: Record<string, string> = {};

        Object.values(this.duplicate).forEach((element) => {
          if (!((element["ind"] as string) in subError)) {
            subError[element["ind"] as string] = `${element["surname"] || ""} ${
              element["name"] || ""
            } ${element["patronymic"] || ""} Удалено дублирующихся записей: ${
              Object.values(this.duplicate).filter(
                (item) => item["ind"] === element["ind"]
              ).length
            }`;
          }
        });
        error["body"] = {
          value: { head: "", list: { type: "text", body: subError } }
        };

        this.errorsList.push(error);
      }

      // для каждой строки таблицы говорим, что требуется проверка
      this.dataForTable.forEach(
        (_row, ind) => (this.staffStatus[ind] = "verificationRequired")
      );
    } else {
      this.dataForTable = value;
      this.errorsList = [];
      this.duplicate = {};
      this.isLoading = false;
    }
  };

  getFieldParams = async () => {
    try {
      const data: ApiResponse<Record<string, TableParams> | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          currentClass: "staff",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          Object.entries(data["result"]).forEach(([key, value]) => {
            if (value.required && this.currentTitles.includes(key)) {
              this.requiredFields.push(key);
            }
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCompaniesList = async () => {
    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "company",
        currentClass: "company",
        method: "getList",
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data.result !== -1) {
          Object.values(data.result).forEach((company) => {
            this.companiesDict[company.id] = company;
          });
        } else {
          this.companiesDict = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.companiesDict = {};
      });
    }
  };

  // с помощью этого метода можно добавлять или удалять сообщения из списка текущих сообщений errorsList
  setErrorsList = (value: Errors["message"], action: "add" | "delete") => {
    if (action === "add") {
      // новые сообщения добавляем в начало массива
      this.errorsList = [value].concat(this.errorsList);
    } else {
      this.errorsList = this.errorsList.filter(
        (error) => error.head !== value.head
      );
    }
  };

  setStaffStatus = (value: StaffStatus, index: number) => {
    runInAction(() => {
      this.staffStatus[index] = value;
    });
  };

  getDuplicateCheckCols = async () => {
    try {
      const data: ApiResponse<Record<string, ColsForDuplicateCheck>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getImportCheckDoubleCols"
        });

      runInAction(() => {
        if (data["code"] === 200 && Object.values(data["result"]).length) {
          Object.values(data["result"]).forEach((col) => {
            this.colsForDuplicateCheck[col["name"]] = col;
            if (col["default"]) {
              this.selectedDuplicateCols.push(col["name"]);
            }
          });
        } else {
          this.colsForDuplicateCheck = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.colsForDuplicateCheck = {};
      });
    }
  };

  setSelectedDuplicateCols = (value: string[]) => {
    this.selectedDuplicateCols = value;
    for (const key in this.staffStatus) {
      this.staffStatus[key] = "verificationRequired";
      this.dataForTable[key]["select"] = 0;
    }
  };

  setDuplicateColsResponse = (index: number, value: string[]) => {
    this.duplicateColsResponse[index] = value;
  };

  // подготавливаем данные для отправки на бэк
  getPreparedData = (values: StaffImportTableType[], isCheck?: boolean) => {
    const formData = cloneDeep(values).map((element) => {
      delete element["status"];
      !element["holding_edu"] && delete element["edu_date_start"];
      delete element["holding_edu"];
      const arrayOfPhone = [];
      Object.keys(element).forEach((key) => {
        if (key.includes("phone_")) {
          element[key] && arrayOfPhone.push(element[key]);
          delete element[key];
        }
      });

      arrayOfPhone.length ? (element["phone"] = arrayOfPhone) : "";

      return element;
    });

    const preparedFormData: Record<string, string> = {};
    formData.forEach((element, index) => {
      // если передан параметр isCheck, то отдаем только те записи, у которых поле select равно 0 (не выбраны чекбоксом в интерфейсе таблицы)
      if (isCheck ? !element["select"] : true) {
        Object.entries(element).forEach(([key, value]) => {
          if (key !== "select" && value) {
            if (Array.isArray(value)) {
              value.forEach(
                (element, ind) =>
                  (preparedFormData[`staff[${index}][${key}][${ind}]`] =
                    element)
              );
            } else {
              preparedFormData[`staff[${index}][${key}]`] = String(value);
            }
          }
        });
      }
    });

    const preparedCheckDoubleCols: Record<string, string> = {};
    if (this.selectedDuplicateCols.length) {
      this.selectedDuplicateCols.forEach((col, index) => {
        preparedCheckDoubleCols[`check_double[${index}]`] = col;
      });
    } else {
      preparedCheckDoubleCols["check_double[]"] = "";
    }

    return { ...preparedFormData, ...preparedCheckDoubleCols };
  };

  checkDuplicateStaff = async (values: StaffImportTableType[]) => {
    this.isLoadingDuplicateCheck = true;
    this.duplicateColsResponse = {};
    this.errorMessage = {};
    values.forEach((row, index) => {
      if (!row["select"]) {
        this.staffStatus[index] = "isLoadingDuplicateCheck";
      }
    });

    try {
      const data: ApiResponse<VerificationResponse> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          method: "importStaff",
          body: {
            add: 0,
            ...this.getPreparedData(values, true)
          }
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.dataForTable = values;

          Object.entries(data["result"]).forEach(([key, value]) => {
            this.verificationResponse[key] = value;

            // для каждой строки записываем список ошибок дублей, если такие имеются
            if (Object.keys(this.verificationResponse[key]["doubles"]).length) {
              this.duplicateColsResponse[key] = Object.keys(
                this.verificationResponse[key]["doubles"]
              );
            }

            // расставляем статусы для каждой строки согласно полю approve
            if (value["approve"]) {
              this.dataForTable[key]["select"] = 1;
              this.staffStatus[key] = "correct";
              this.initialStaffStatus[key] = "correct";
            } else {
              // если у строки approve: false, то это либо неправильно заполненное поле, либо ошибка дубля
              if (Object.values(value["incorrect_cols"])) {
                this.staffStatus[key] = "incorrectField";
                this.initialStaffStatus[key] = "incorrectField";
              }
              if (Object.values(value["doubles"])) {
                this.staffStatus[key] = "incorrectDouble";
                this.initialStaffStatus[key] = "incorrectDouble";
              }
            }
          });
        } else {
          this.errorMessage = data["message"];
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    } finally {
      setTimeout(() => {
        runInAction(() => (this.isLoadingDuplicateCheck = false));
      }, 3000);
    }
  };

  addNewStaff = async (values: StaffImportTableType[]) => {
    try {
      const data: ApiResponse<VerificationResponse> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          method: "importStaff",
          body: {
            add: 1,
            ...this.getPreparedData(values.filter((item) => item["select"]))
          }
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.countAddedStaff = Object.values(data["result"]).filter(
            (element) => element["approve"]
          ).length;
          if (values.filter((item) => !item["select"]).length) {
            this.checkDuplicateStaff(values.filter((item) => !item["select"]));
          } else {
            this.dataForTable = [];
          }
        } else {
          this.errorMessage = data["message"];
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    } finally {
      runInAction(() => (this.isLoadingDuplicateCheck = false));
    }
  };

  resetCountAddedStaff = () => {
    this.countAddedStaff = 0;
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
