import styles from "./filterMainCols.module.scss";
import { useState, useEffect } from "react";

import iconAddDefault from "shared/assets/images/mainIcons/iconAdd/iconAddDefault.svg";
import iconMinusCircle from "shared/assets/images/mainIcons/iconMinusCircle.svg";

import Tooltip from "shared/ui/Tooltip";

import { Col } from "stores/utils/types/Col";
import FilterMainWindow from "../FilterMainWindow";
import { Chips, ChipsVariation } from "shared/ui/Chips";

type FilterMainColsProps = {
  selectedFilter: string;
  array: string[];
  directory: Col["directory"] | { [key: string]: Col };
  setIsMenuOpened: () => void;
  isMenuOpened: boolean;
  deleteItem: (arg: string) => void;
  deleteAllItem: () => void;

  placeholder?: string;
  label?: string;
  subtitle?: string;
  error?: boolean;
  defaultArray?: string[];
  updateItems?: (arg: string[]) => void;
  isColumns?: boolean;
  isSearchWithPagination?: boolean;
  pageSelectsInfo?: Record<
    string,
    {
      page: number;
      prevPage: number;
      maxPage: number;
      searchValue: string;
      isLoading: boolean;
      setSearchValue: (value: string) => void;
      setPage: (value: number) => void;
      getList: () => void;
    }
  >;
  field?: string;
  dictForArray?: Record<string, string>;
  setDictForArray?: (value: Record<string, string>) => void;
  disabled?: boolean;
};

const FilterMainCols = ({
  array,
  defaultArray,
  deleteItem,
  deleteAllItem,
  updateItems,
  isColumns,

  directory,

  setIsMenuOpened,
  isMenuOpened,

  placeholder,
  label,
  subtitle,
  error,
  selectedFilter,
  isSearchWithPagination,
  pageSelectsInfo,
  field,
  dictForArray,
  setDictForArray,
  disabled
}: FilterMainColsProps) => {
  const [colsError, setColsError] = useState(false);

  useEffect(() => {
    setColsError(false);
  }, [selectedFilter]);

  const handleOpenWindow = () => {
    setIsMenuOpened();
  };

  const handleDeleteItem =
    (key: string) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      deleteItem(key);
    };

  return (
    <>
      <div
        className={`${styles.colsWrapper} 
            ${isMenuOpened ? styles.colsWrapper_active : ""} ${
          !array.length && colsError && error ? styles.colsWrapper_error : ""
        } ${!isColumns ? styles.widthForParams : ""} ${
          disabled ? styles.colsWrapper_disabled : ""
        }`}
        onBlur={() => setColsError(true)}
        onClick={handleOpenWindow}
        tabIndex={0}
      >
        {array.length || (defaultArray && defaultArray.length) ? (
          <div className={styles.wrapperWithLabel}>
            {label ? <p>{label}</p> : ""}
            <div className={array.length ? styles.cols : ""}>
              <div className={styles.cols}>
                {array.map((key) => (
                  <Chips
                    variation={ChipsVariation.COLS}
                    key={key}
                    onClick={handleDeleteItem(key)}
                    title={
                      directory[key]
                        ? directory[key]["title"]
                        : Array.isArray(directory)
                        ? dictForArray[key]
                        : key
                    }
                    onDelete={() => null}
                  />
                ))}
              </div>
              {defaultArray ? (
                <div className={styles.cols}>
                  {defaultArray.map((key) => (
                    <Tooltip
                      color="blue-lazure"
                      text="Обязательное поле"
                      placement="bottom"
                      key={key}
                    >
                      <Chips
                        variation={ChipsVariation.COLSDEFAULT}
                        key={key}
                        title={directory[key] ? directory[key]["title"] : key}
                      />
                    </Tooltip>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <p>{placeholder} </p>
        )}
        <div className={styles.rightBtnsBlock}>
          <img
            src={iconAddDefault}
            className={styles.iconAdd}
            id="FilterMainCols_openList"
          />
          {array.length ? (
            <img
              src={iconMinusCircle}
              className={styles.iconRemove}
              id="FilterMainCols_removeNewItem"
              onClick={() => deleteAllItem()}
            />
          ) : (
            ""
          )}
        </div>
      </div>

      {!array.length && colsError && error ? (
        <p className={styles.error}>Выберите хотя бы один столбец</p>
      ) : (
        ""
      )}

      {isMenuOpened ? (
        <FilterMainWindow
          title={placeholder}
          subtitle={subtitle}
          setIsMenuOpened={setIsMenuOpened}
          isMultiSelect
          valuesArray={array}
          defaultArray={defaultArray}
          directory={directory}
          isColumns={isColumns ? true : false}
          updateItems={updateItems}
          isSearchWithPagination={isSearchWithPagination}
          pageSelectsInfo={pageSelectsInfo}
          field={field}
          dictForArray={dictForArray}
          setDictForArray={setDictForArray}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default FilterMainCols;
