import { cloneDeep } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { Col } from "stores/utils/types/Col";
import { SelectModule } from "stores/StaffModule/types/Selects";
import { FilterType } from "stores/utils/types/FilterType";
import { ApiResponse } from "stores/utils/types/ApiResponse";

type SalaryPremiumsListItem = {
  id: string;
  last_update: string;
  calculation_period: string;
  status: string;
  object: {
    id: string;
    title: string;
    company_id: string;
    company_title: string;
  };
};

export default class SalaryPremiumsListStore {
  error = false;
  isLoading = false;
  isLoadingForFilters = false;
  errorMessage = "";

  page = 1;
  onPage = 100;
  maxPage = 0;
  prevPage = 1;
  downloadedFilter = "";

  openedFilter = false;

  currentTitles = [];
  salaryPremiumsTableCols: { [key: string]: Col } = {};
  salaryPremiumsColsParam: Record<string, Col> = {};

  premiumList: SalaryPremiumsListItem[] = [];

  searchValue = "";
  foundCounter = 0;
  selectedFilter = "";
  filterParams: { [key: string]: string[] | string | number } = {};
  queryParams: { [key: string]: string } = {};

  // объект, в котором хранится не сохраненный фильтр
  filtersChanged: { [key: string]: Partial<FilterType> } = {};

  // справочник статусов
  premiumStatusSelects: SelectModule = {};

  // поля для работы со выпадающим списком Объект в фильтре
  pageBuilding = 1;
  onPageBuilding = 30;
  maxPageBuilding = 0;
  prevPageBuilding = 1;
  searchValueBuilding = "";
  isLoadingForBuildingList = false;

  // список в виде пары id - title
  // нужен для вывода title в компоненте FilterMainCols и FilterMainWindow
  dictForFilter: Record<string, string> = {};
  filters: Record<string, FilterType> = {};
  dateRangeFields: Record<string, string[]> = {};
  rootStore: RootStore;

  // предустановленные фильтры хардкодим на фронте
  generalFilters: Record<string, FilterType> = {
    filter_default: {
      cols: [],
      default: 1,
      filter: {},
      general: 1,
      id: "filter_default",
      order: "time_create",
      ordered: "DESC",
      title: "Весь список"
    }
  };

  getSalaryPremiumsList = () => {
    this.isLoading = true;

    !Object.keys(this.premiumStatusSelects).length && this.getStatusList();
    this.getPremiumList();
  };

  getNewFilterParams = (filter: FilterType) => {
    this.filterParams = {};
    Object.entries(filter.filter).forEach(([key, value]) => {
      if (
        (typeof value === "string" && value) ||
        (Array.isArray(value) && value.length) ||
        typeof value === "number"
      ) {
        this.filterParams[`filter[${key}]`] = value;
      } else if (typeof value === "object" && !Array.isArray(value)) {
        this.filterParams[`filter[${key}]`] = Object.values(value);
      }
    });
  };

  getPremiumListWithFilter = (filter: FilterType) => {
    this.filterParams = {};
    this.setPage(1);
    if (!filter.default) {
      this.getNewFilterParams(filter);
    } else {
      this.filterParams = {};
    }
    this.setDownloadedFilter(filter["id"]);
    this.getPremiumList();
  };

  getFilters = async (action?: string, id?: string) => {
    try {
      const data: {
        code: number;
        savedFilter: { [key: string]: { filterdata: FilterType } };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "filters",
        method: "getFilter",
        params: { module: "salary", prefix: "premium" }
      });
      runInAction(() => {
        // проверяем на наличие новых фильтров, если сохраненные в сторе фильтры равны количеству приходящих с бэка плюс 1
        // (один мы прописываем хардкодом на фронте) или же передан action на добавление/редактирование/удаление
        // и код двухсотый, то обновляем поле this.filters
        if (
          data["code"] === 200 &&
          (Object.keys(this.filters).length !==
            Object.keys(data["savedFilter"]).length + 1 ||
            action)
        ) {
          this.filters = {};
          this.filters = cloneDeep(this.generalFilters);
          Object.entries(data["savedFilter"]).forEach(([key, filter]) => {
            this.filters[key] = filter["filterdata"];
            this.filters[key]["id"] = key;
            this.filters[key]["filter"] = filter["filterdata"]?.["filter"]
              ? filter["filterdata"]?.["filter"]
              : {};

            Object.entries(this.dateRangeFields).forEach(
              ([keyRange, valueRange]) => {
                if (
                  valueRange.every(
                    (field) => field in this.filters[key]["filter"]
                  )
                ) {
                  this.filters[key]["filter"][keyRange] = "";
                }
              }
            );
          });

          Object.values(this.filters).forEach((filter) => {
            if (action) {
              switch (action) {
                case "add":
                  this.setSelectedFilter("new");
                  break;
                case "delete":
                  if (filter.default) {
                    this.setSelectedFilter(filter.id);
                  }
                  break;
                case "edit":
                  if (id === filter.id) {
                    this.setSelectedFilter(filter.id);
                  }
                  break;
              }
            }
          });
          Object.values(this.filters).forEach((filter) => {
            if (
              !this.downloadedFilter &&
              filter.default &&
              !Object.keys(this.filterParams).length
            ) {
              this.setDownloadedFilter(filter.id);
            }
          });
          this.filtersChanged = cloneDeep(this.filters);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  setFilters = async (action: string, id: string, values: FilterType) => {
    const newParams: { [key: string]: string | number } | { cols?: string[] } =
      {};
    runInAction(() => {
      this.isLoadingForFilters = true;
    });

    Object.entries(values).forEach(([key, value]) => {
      if (!key.includes("isIntervalField")) {
        switch (key) {
          case "filter":
            value
              ? Object.entries(value).forEach(
                  ([keyField, valueField]: [
                    string,
                    string | number | { [key: string]: string }
                  ]) => {
                    switch (typeof valueField) {
                      case "object":
                        newParams[`filter[${keyField}]`] =
                          Object.values(valueField);
                        break;
                      default:
                        if (valueField || valueField === 0)
                          newParams[`filter[${keyField}]`] = valueField;
                        break;
                    }
                  }
                )
              : "";
            break;
          case "id":
            break;
          case "cols":
            break;
          default:
            newParams[key] = values[key];
            break;
        }
      }
    });

    const newSelectedFilter = this.filters?.[id]?.default
      ? Object.values(this.filters).filter((filter) => filter.general)[0]["id"]
      : Object.values(this.filters).filter((filter) => filter.default)[0]?.[
          "id"
        ] || Object.keys(this.filters).filter((filter) => filter !== id)[0];

    switch (action) {
      case "add":
        newParams["saveFilter"] = values.title;

        break;
      case "edit":
        newParams["updateFilter"] = id;
        this.setSelectedFilter(id);
        this.setDownloadedFilter(id);
        this.getNewFilterParams(values);
        break;
      case "delete":
        newParams["removeFilter"] = id;

        this.setSelectedFilter(newSelectedFilter);
        this.setDownloadedFilter(newSelectedFilter);

        if (this.selectedFilter) {
          this.getNewFilterParams(this.filters[this.selectedFilter]);
        }

        break;
    }

    try {
      const data: ApiResponse<string> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "filters",
        method: "setFilter",
        params: { module: "salary", prefix: "premium", ...newParams }
      });

      runInAction(() => {
        if (data["result"]) {
          this.getPremiumList(action, id);
        } else {
          this.isLoadingForFilters = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  setFiltersChanged = (
    currentFilter: string,
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => {
    if (
      this.filtersChanged[currentFilter] &&
      Object.keys(this.filtersChanged[currentFilter]).length
    ) {
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
      this.filtersChanged[currentFilter][key] = value;
    } else {
      this.filtersChanged[currentFilter] = {
        id: "new",
        title: "",
        default: 0,
        general: 0,
        filter: {},
        cols: []
      };
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
    }
    this.filtersChanged[currentFilter][key] = value;
  };

  getPremiumList = async (action?: string, id?: string) => {
    this.page = 1;
    this.errorMessage = "";
    this.isLoading = true;

    try {
      const data: ApiResponse<{ [key: string]: SalaryPremiumsListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getPremium",
          on_page: this.onPage,
          page: this.page,
          params: {
            fast_search: this.searchValue,
            ...this.filterParams
          }
        });

      runInAction(() => {
        if (Object.values(data["cols"]).length) {
          this.salaryPremiumsTableCols = data["cols"];
          Object.entries(data["cols"]).forEach(([key, value]) => {
            if (value.useAsFilter === "on") {
              this.salaryPremiumsColsParam[key] = value;
            }
            if ("range" in value) {
              this.dateRangeFields[key] = Object.keys(value["range"]);
            }
          });
          this.getBuildingList();
          this.getStatusList();
        }
        this.getFilters(action, id);

        if (Object.values(data["result"]).length) {
          this.premiumList = Object.values(data["result"]);
        } else {
          this.premiumList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        if (Object.values(data["show_fields"]).length) {
          this.currentTitles = Object.values(data["show_fields"]);
        } else {
          this.currentTitles = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.page = this.prevPage;
        this.maxPage = data["nav"]["max_page"];
        this.foundCounter = data["nav"]["count"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.isLoadingForFilters = false;
      });
    }
  };

  getMorePremiumList = async () => {
    this.errorMessage = "";
    this.isLoading = true;
    try {
      const data: ApiResponse<{ [key: string]: SalaryPremiumsListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getPremium",
          on_page: this.onPage,
          page: this.page,
          params: {
            fast_search: this.searchValue,
            ...this.filterParams
          }
        });

      runInAction(() => {
        if (Object.values(data.result).length) {
          this.premiumList.push(...Object.values(data.result));
          this.rootStore.menuStore.isScrollBottom = false;
        } else {
          this.premiumList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getStatusList = async () => {
    try {
      const data: ApiResponse<{ [key: string]: SelectModule }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getSelects"
        });

      runInAction(() => {
        if (Object.values(data.result).length) {
          this.premiumStatusSelects = data["result"]["status_premium"];
          if ("status" in this.salaryPremiumsColsParam) {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            Object.values(this.premiumStatusSelects).forEach((value) => {
              directory[value.id] = {
                newname: value.id,
                title: value.title
              };
            });
            this.salaryPremiumsColsParam["status"] = {
              ...this.salaryPremiumsColsParam["status"],
              directory: directory
            };
          }
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // метод для создания списков в выпадающем окне фильтров
  dataPreparation = (data: { id: string; title: string }[]) => {
    return data.map((oneOfObject) => {
      const tempOneOfObject = {} as { title: string; newname: string };
      Object.entries(oneOfObject).forEach(([key, value]) => {
        switch (key) {
          case "id":
            tempOneOfObject["newname"] = value;
            break;

          default:
            tempOneOfObject[key] = value;
        }
      });
      return tempOneOfObject;
    });
  };

  // метод для получения объектов в выпадающем окне фильтра
  getBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("object" in this.salaryPremiumsColsParam) {
            this.salaryPremiumsColsParam["object"] = {
              ...this.salaryPremiumsColsParam["object"],
              directory: this.dataPreparation(Object.values(data["result"]))
            };
          }
        } else {
          this.salaryPremiumsColsParam["object"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.maxPageBuilding = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.salaryPremiumsColsParam["object"]["directory"] = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingForBuildingList = false;
      });
    }
  };

  //метод для получения объектов в выпадающем окне фильтра при скролле списка (пагинация)
  getMoreBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("object" in this.salaryPremiumsColsParam) {
            const directory = (
              this.salaryPremiumsColsParam["object"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.dataPreparation(
                Object.values(data["result"]).filter(
                  (object) => !(object["id"] in this.dictForFilter)
                )
              )
            );

            this.salaryPremiumsColsParam["object"] = {
              ...this.salaryPremiumsColsParam["object"],
              directory: directory
            };
          }
        } else {
          this.salaryPremiumsColsParam["object"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.prevPageBuilding = this.pageBuilding;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoadingForBuildingList = false));
    }
  };

  // метод для получения объектов по их id из сохраненных фильтров и последующее их добавление в справочник
  getBuildingDictForSavedFilter = async (valueFromSavedFilter?: string[]) => {
    const formDataFromSavedFilter = {
      "filter[where][id][value]": valueFromSavedFilter,
      "filter[where][id][logic]": "OR",
      "filter[where][id][comparison]": "IN"
    };
    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        params: formDataFromSavedFilter,
        select: ["id", "title"]
      });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).forEach((value) => {
            this.dictForFilter[value["id"]] = value["title"];
          });
          if ("object" in this.salaryPremiumsColsParam) {
            const directory = (
              this.salaryPremiumsColsParam["object"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparation(Object.values(data["result"])));
            this.salaryPremiumsColsParam["object"] = {
              ...this.salaryPremiumsColsParam["object"],
              directory: directory
            };
          }
        } else {
          this.dictForFilter = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.dictForFilter = {};
      });
    }
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setSearchValue = (value: string) => {
    this.searchValue = value;
  };

  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  setFilterParamsFromQuery = (value: { [key: string]: string }) => {
    this.filterParams = value;
  };

  setQueryParams = (value: { [key: string]: string }) => {
    this.queryParams = value;
  };

  setSearchValueBuilding = (value: string) => {
    this.setPageBuilding(1);
    this.prevPageBuilding = 1;
    this.searchValueBuilding = value;
  };

  setPageBuilding = (value: number) => {
    if (!this.isLoadingForBuildingList) {
      this.pageBuilding = value;
    }
  };

  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  setDictForArray = (value: Record<string, string>) => {
    this.dictForFilter = value;
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
