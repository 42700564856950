import { classNames } from "shared/utils/helpers/classNames";
import styles from "./chips.module.scss";
import { ButtonHTMLAttributes, ReactNode } from "react";
import StatusIcon from "../StatusIcon";
import iconCloseForBtn from "shared/assets/images/mainIcons/iconsClose/iconCloseForBtn.svg";

export enum ChipsVariation {
  MAIN = "chips",
  COLS = "colsChips",
  COLSDEFAULT = "colsChipsDefault",
  NONACTIVE = "nonActive",
  BLACKLIST = "blackList",
  FILTER = "filterChips"
}

interface ChipsProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Стили, заданные в родителе для кнопки
   */
  className?: string;
  /**
   * Вид кнопки, передаем из enum ChipsVariation
   */
  variation?: ChipsVariation;
  /**
   * Функция, отрабатывающая при клике если есть иконка крестик для удаления
   */
  onDelete?: () => void;
  title?: string;
  /**
   * Для случаев сложносочиненного title как в редактировании сотрудника
   */
  children?: ReactNode;
  /**
   * Если на кнопке есть иконка статуса
   */
  icon?: string;
  /**
   * Цвет для иконки статуса
   */
  iconColor?: string;
}

export const Chips = ({
  className,
  color,
  variation,
  onDelete,
  title,
  children,
  icon,
  iconColor,
  ...props
}: ChipsProps) => {
  const optional: string[] = [className, styles[color], styles[variation]];

  return (
    <button type="button" className={classNames("", {}, optional)} {...props}>
      {icon && <StatusIcon icon={icon} color={iconColor} />}
      {children ? children : <p>{title}</p>}
      {onDelete && <img src={iconCloseForBtn} onClick={onDelete} />}
    </button>
  );
};
