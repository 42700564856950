import styles from "./awardsHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { fileUrl } from "stores/utils/consts";
import { classNames } from "shared/utils/helpers/classNames";
import Avatar from "shared/assets/images/mainIcons/iconAvatar/AvatarWithoutFill.svg";

const AwardsHeader = () => {
  const { staffOneAwardsStore, menuStore } = useStores();

  return (
    <div
      className={classNames(styles.container, {
        [styles.menuOpened]: menuStore.isMenuShown,
        [styles.menuClosed]: !menuStore.isMenuShown
      })}
    >
      <div className={styles.mainInfo}>
        <div
          className={classNames(styles.photo, {
            [styles.avatar]: !staffOneAwardsStore.staffInfo["photo"]
          })}
        >
          <img
            src={
              staffOneAwardsStore.staffInfo["photo"]
                ? `${fileUrl}${staffOneAwardsStore.staffInfo["photo"]}`
                : Avatar
            }
          />
        </div>

        <div className={styles.textInfo}>
          <div className={styles.TNBlock}>
            <p className={styles.title}>ТН</p>
            <p className={styles.value}>
              {staffOneAwardsStore.staffInfo["uid"]}
            </p>
          </div>
          <div className={styles.fioBlock}>
            <p className={styles.fio}>
              {staffOneAwardsStore.staffInfo["surname"]}{" "}
              {staffOneAwardsStore.staffInfo["name"]}{" "}
              {staffOneAwardsStore.staffInfo["patronymic"] || ""}
            </p>

            {staffOneAwardsStore.actualPositionTitle ? (
              <p className={styles.position}>
                {staffOneAwardsStore.actualPositionTitle}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AwardsHeader);
