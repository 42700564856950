import styles from "./staffOneEditFormEmail.module.scss";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useFormikContext } from "formik";

import addIcon from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";

import { Input } from "shared/ui/Inputs/Input";
import Checkbox from "shared/ui/Inputs/Checkbox";
import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import { ViewField } from "shared/ui/ViewField";

type StaffOneEditFormEmailProps = {
  values: { email?: string | null; email_confirm?: number | null };
  initialValues: { email?: string | null; email_confirm?: number | null };
  dirty: boolean;
  isValid: boolean;
  disabled: boolean;
};

const StaffOneEditFormEmail = ({
  values,
  initialValues,
  dirty,
  isValid,
  disabled
}: StaffOneEditFormEmailProps) => {
  const { staffOneEditStore } = useStores();

  const [isAddEmail, setIsAddEmail] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const [isEmail, setIsEmail] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    if (initialValues["email"] !== undefined) setIsEmail(true);
    if (initialValues["email_confirm"] !== undefined) setIsConfirm(true);
  }, []);

  useEffect(() => {
    // при handleReset() обнуляются значения в полях, но не меняется состояние формы добавления email'a
    // поэтому если нет изменений в форме и состояние формы добавления email'a "открыта"
    if (!dirty && isAddEmail) {
      // изменяем состояние формы на "закрыто" и кнопка "Добавить +" будет отображаться
      setIsAddEmail(false);
      setIsEditingEmail(false);
    }
  }, [dirty]);

  const { setFieldValue, setFieldTouched, handleChange, handleBlur } =
    useFormikContext();

  const editEmail = ({ action }: { action: string }) => {
    setIsEditingEmail(false);
    setIsAddEmail(false);
    const contacts: { email?: string | null; email_confirm?: number | null } =
      {};

    switch (action) {
      case "submit":
        if (isEmail) contacts["email"] = values["email"];
        if (isConfirm) contacts["email_confirm"] = values["email_confirm"] || 0;
        staffOneEditStore.updateField(
          staffOneEditStore.selectedOneForEdit["id"],
          contacts,
          "email"
        );
        break;
      case "edit":
        setIsEditingEmail(true);
        break;
      case "delete":
        if (isEmail) contacts["email"] = "";
        if (isConfirm) contacts["email_confirm"] = 0;
        staffOneEditStore.updateField(
          staffOneEditStore.selectedOneForEdit["id"],
          contacts,
          "email"
        );
        break;
      case "cancel":
        if (isAddEmail) {
          isEmail && setFieldValue("email", null);
          isConfirm && setFieldValue("email_confirm", null);
          setIsAddEmail(false);
        } else {
          isEmail && setFieldValue("email", initialValues["email"]);
          isConfirm &&
            setFieldValue("email_confirm", initialValues["email_confirm"]);
          setFieldTouched("email", false);
          setFieldTouched("email_confirm", false);
        }
        break;
      default:
        break;
    }
  };

  return !staffOneEditStore.isLoadingEmail ? (
    <div className={`${styles.form} ${disabled ? styles.disabled : ""}`}>
      <div className={styles.subtitle}>Электронная почта</div>
      <div className={styles.containerForm}>
        {values["email"] || isEditingEmail || isAddEmail ? (
          <ul
            className={
              isEditingEmail
                ? `${styles.confirmRowEmail} ${styles.confirmRowEmail_selected}`
                : styles.confirmRowEmail
            }
          >
            {isAddEmail ? null : isConfirm ? (
              <li className={styles.confirmRowEmail__confirm}>
                <label htmlFor="email_confirm">
                  {staffOneEditStore.staffTableCols["email_confirm"]["title"]}
                </label>
                <Checkbox
                  name="custom.email_confirm"
                  id="email_confirm"
                  value={values["email_confirm"]}
                  disabled
                />
              </li>
            ) : (
              ""
            )}
            {isEmail ? (
              <>
                <li className={styles.confirmRowEmail__order}>
                  <ViewField
                    title="№"
                    value="1"
                    className={{ container: styles.confirmRowEmailBorder }}
                  />
                </li>
                <li className={styles.confirmRowEmail__email}>
                  <Input
                    name="email"
                    label={staffOneEditStore.staffTableCols["email"]["title"]}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldTouched("email");
                    }}
                    onBlur={handleBlur}
                    disabled={!isEditingEmail}
                  />
                </li>
                <li className={styles.confirmRowEmail__buttons}>
                  {isAddEmail ? (
                    <ButtonsGroupForEdit
                      btns={[
                        {
                          action: "submit",
                          type: "button",
                          icon: "bigcheck",
                          color: "bw-gray5",
                          disabled: !isValid || !dirty
                        },
                        {
                          action: "cancel",
                          type: "button",
                          icon: "iconclose",
                          color: "bw-gray5",
                          disabled: false
                        }
                      ]}
                      onClick={(action) => {
                        editEmail({ action });
                      }}
                    />
                  ) : (
                    <ButtonsGroupForEdit
                      btns={
                        isEditingEmail
                          ? [
                              {
                                action: "submit",
                                type: "button",
                                icon: "bigcheck",
                                color:
                                  !isValid || !dirty
                                    ? "bw-gray3"
                                    : "blue-lazure",
                                disabled: !isValid || !dirty
                              },
                              {
                                action: "cancel",
                                type: "button",
                                icon: "iconclose",
                                color: "blue-lazure",
                                disabled: false
                              },
                              {
                                action: "delete",
                                type: "button",
                                icon: "iconbasket",
                                color: "bw-gray5",
                                disabled: true
                              }
                            ]
                          : [
                              {
                                action: "edit",
                                type: "button",
                                icon: "iconedit",
                                color: "bw-gray5",
                                disabled: false
                              },
                              {
                                action: "delete",
                                type: "button",
                                icon: "iconbasket",
                                color: "bw-gray5",
                                disabled: false
                              }
                            ]
                      }
                      onClick={(action) => {
                        editEmail({ action });
                      }}
                    />
                  )}
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        ) : (
          <button
            id={`StaffOneEditEmail_addEmailFormButton`}
            type="button"
            className={styles.buttonAddNumber}
            onClick={() => {
              setIsAddEmail(true);
              setIsEditingEmail(true);
            }}
          >
            <p className={styles.buttonAddNumber__text}>Добавить</p>
            <img src={addIcon} className={styles.buttonAddNumber__icon} />
          </button>
        )}
      </div>
    </div>
  ) : (
    <p className={styles.loading}>Пожалуйста, подождите...</p>
  );
};

export default observer(StaffOneEditFormEmail);
