import { observer } from "mobx-react-lite";
import styles from "./aregisterAllReportNewReportTableRow.module.scss";
import { useStores } from "stores/index";
import { PresaveStaff } from "stores/AregisterModule/types/PresaveStaff";
import { NewReportStaffValues } from "stores/AregisterModule/types/NewReportStaffValues";
import { classNames } from "shared/utils/helpers/classNames";
import AregisterAllReportNewReportTableWorkHoursCell from "../AregisterAllReportNewReportTableWorkHoursCell";
import AregisterAllReportNewReportTableCell from "../AregisterAllReportNewReportTableCell";
import iconDrag from "shared/assets/images/mainIcons/iconDragSelected.svg";
import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import { ReactComponent as IconInfo } from "./info.svg";
import Checkbox from "shared/ui/Inputs/Checkbox";
import { useFormikContext } from "formik";
import Tooltip from "shared/ui/Tooltip";

export type AregisterAllReportNewReportTableRowProps = {
  staff: PresaveStaff;
  staffIdx: number;
  company: string;
  companyIdx: number;
  handleArrowKeys: (e: { keyCode: number }) => void;
  localRowNumber: number;
  columnNumber: number;
  highlightedColumn?: number;
  setHighlightedColumn?: (arg: number) => void;
  setIsOpenStaffList: (isOpenStaffList: boolean) => void;
  isHoveredCell: boolean;
  isEditingCell: boolean;
  setIsEditingCell: (isEditingCell: boolean) => void;
  focusedRowCell: number;
  setFocusedRowCell: (focusedRowCell: number) => void;
  focusedColCell: number;
  setFocusedColCell: (focusedColCell: number) => void;
  editMassHoursPresaveId: string;
  className: string;
  searchInputValue: string;
  rowSelected: boolean;
};

const AregisterAllReportNewReportTableRow = ({
  staff,
  staffIdx,
  company,
  companyIdx,
  handleArrowKeys,
  localRowNumber,
  columnNumber,
  highlightedColumn,
  setHighlightedColumn,
  setIsOpenStaffList,
  isHoveredCell,
  isEditingCell,
  setIsEditingCell,
  focusedRowCell,
  setFocusedRowCell,
  focusedColCell,
  setFocusedColCell,
  editMassHoursPresaveId,
  className,
  searchInputValue,
  rowSelected
}: AregisterAllReportNewReportTableRowProps) => {
  const { aregisterReportStore, menuStore } = useStores();
  const { setFieldValue, setFieldTouched, handleReset, values } =
    useFormikContext<NewReportStaffValues>();

  // собираем массив дополнительных мастеров у работника, если они есть
  const extrasList: string[] = [];
  if ("extra_masters" in staff) {
    Object.values(staff.extra_masters).forEach((extra) => {
      extrasList.push(`${extra.fio} `);
    });
  }

  // выводим фио мастеров в всплывающей подсказке
  const tooltipText = {
    text: (
      <>
        <div className={styles.tooltipTitle}>Также работал для:</div>
        <span>{extrasList}</span>
      </>
    )
  };

  const fillStaffHoursMass = (e: React.ChangeEvent<Element>) => {
    let updatedValue: string[];
    if (values.filledHoursStaffIds?.includes(`${staff.uid}_${company}`)) {
      updatedValue = values.filledHoursStaffIds.filter(
        (value: string) => value !== `${staff.uid}_${company}`
      );
      setFieldValue("selectAll", e.target["checked"] ? 1 : 0);
    } else {
      updatedValue = [...values.filledHoursStaffIds, `${staff.uid}_${company}`];
    }
    if (updatedValue.length) {
      setFieldValue("filledHoursStaffIds", updatedValue);
      setFieldTouched("filledHoursStaffIds");
      if (updatedValue.length === aregisterReportStore.companiesQuantity) {
        setFieldValue("selectAll", e.target["checked"] ? 1 : 0);
      }
    } else {
      handleReset();
    }
  };

  const handleDeletePresaveStaff = () => {
    aregisterReportStore.deletePresaveStaff(
      aregisterReportStore.selectedOptions.project_id,
      aregisterReportStore.selectedOptions.date_start,
      aregisterReportStore.selectedOptions.date_end,
      aregisterReportStore.selectedOptions.masterId,
      staff.uid
    );
  };

  return (
    <tr className={className}>
      <>
        {!companyIdx && (
          <AregisterAllReportNewReportTableCell
            className={styles.number}
            rowSpan={Object.keys(staff.company_ws).length}
          >
            <span className="d-flex align-items-center">
              {String(staffIdx + 1) + "."}
              <img src={iconDrag} alt="dragRow" />
            </span>
          </AregisterAllReportNewReportTableCell>
        )}
        <AregisterAllReportNewReportTableCell
          className={classNames(styles.staff, {
            [styles.rowSelected]: values.filledHoursStaffIds?.includes(
              `${staff.uid}_${company}`
            ),
            [styles.staff__shadow]: menuStore.scroll["scrollLeft"]
          })}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div>
              {!companyIdx && (
                <div className={styles.staff__fio}>{staff.fio}</div>
              )}
              <div className={styles.staff__row}>
                <div
                  className={styles.staff__tN}
                  dangerouslySetInnerHTML={{
                    __html: searchInputValue
                      ? `ТН${String(staff.tn).replace(
                          searchInputValue,
                          "<mark>$&</mark>"
                        )}`
                      : `ТН${staff.tn}`
                  }}
                ></div>
                <div
                  className={
                    styles[
                      `staff__company_${
                        Object.values(aregisterReportStore.companiesList).length
                          ? aregisterReportStore.companiesList[company]?.color
                          : "primary"
                      }`
                    ]
                  }
                >
                  {aregisterReportStore.companiesList[company]["title"]}
                </div>
              </div>
            </div>
            {editMassHoursPresaveId ? (
              <span className={styles.checkbox}>
                <Checkbox
                  name="filledHoursStaffIds"
                  value={`${staff.uid}_${company}`}
                  onChange={fillStaffHoursMass}
                />
              </span>
            ) : (
              !companyIdx && (
                <div className="d-flex align-items-center">
                  {"extra_masters" in staff &&
                  Object.keys(staff.extra_masters).length !== 0 ? (
                    <Tooltip {...tooltipText}>
                      <IconInfo />
                    </Tooltip>
                  ) : (
                    ""
                  )}

                  <button
                    type="button"
                    className={styles.staff__basket}
                    onClick={handleDeletePresaveStaff}
                    id={`AregisterAllReportNewReportTableRow_deleteStaff_${staff.uid}`}
                  >
                    <IconBasket
                      alt="delete_staff"
                      className={styles.staff__basket_img}
                    />
                  </button>
                </div>
              )
            )}
          </div>
        </AregisterAllReportNewReportTableCell>

        {aregisterReportStore.filteredPresaveList.length &&
          aregisterReportStore.filteredPresaveList.map(
            (presave, presaveIndex) => {
              const hourId = `${presave.id}_${staff.uid}_${company}`;
              const hour = aregisterReportStore.presaveStaffHours[hourId];

              const aregister =
                aregisterReportStore.filteredAregisterList[
                  presave["aregister"]
                ] !== undefined &&
                aregisterReportStore.filteredAregisterList?.[
                  presave["aregister"]
                ];

              const closed =
                aregisterReportStore.aregisterStatus?.[aregister.status]
                  ?.title === "Закрыта";
              const canceled =
                aregisterReportStore.aregisterStatus?.[aregister.status]
                  ?.title === "Аннулирована";

              const handleChangePresaveHours = () => {
                aregisterReportStore.changePresaveHours(
                  presave.id,
                  staff.uid,
                  company,
                  hour
                );
              };

              const handleEditingCell = () => {
                setFocusedRowCell(localRowNumber);
                setFocusedColCell(presaveIndex);
                setIsEditingCell(true);
                setIsOpenStaffList(false);
              };

              const handleHoursChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                setFieldValue(`work_hours_${hourId}`, event.target.value);
                setFieldTouched(`work_hours_${hourId}`);
                aregisterReportStore.getWorkHoursSumChanged(
                  hourId,
                  Number(event.target.value)
                );
              };

              return (
                <AregisterAllReportNewReportTableWorkHoursCell
                  key={`staff_fio_${presave.id}`}
                  value={values[`work_hours_${hourId}`] || ""}
                  name={`work_hours_${hourId}`}
                  id={`work_hour_${hourId}`}
                  canceled={canceled}
                  className={classNames(styles.workHours, {
                    [styles.hiddenColumn]:
                      columnNumber !== null && columnNumber !== presaveIndex,
                    [styles.workHours__focused]:
                      (focusedRowCell === localRowNumber &&
                        focusedColCell === presaveIndex) ||
                      values.filledHoursStaffIds?.includes(
                        `${staff.uid}_${company}`
                      ),
                    [styles.workHours__nonActive]: canceled || closed,
                    [styles.workHours__white]: !canceled && !closed,
                    [styles.workHours__hover]: isHoveredCell
                  })}
                  column={presaveIndex}
                  highlightedColumn={highlightedColumn}
                  setHighlightedColumn={setHighlightedColumn}
                  onChange={handleHoursChange}
                  onClick={handleEditingCell}
                  onBlur={handleChangePresaveHours}
                  onKeyDown={!isEditingCell ? handleArrowKeys : undefined}
                  hour={hour}
                  disabled={canceled || closed}
                  autoFocus={
                    isEditingCell &&
                    focusedRowCell === localRowNumber &&
                    focusedColCell === presaveIndex
                  }
                />
              );
            }
          )}

        {!companyIdx && (
          <AregisterAllReportNewReportTableCell
            className={classNames(styles.total, {
              [styles.total__shadow]: menuStore.scroll["scrollLeft"],
              [styles.total__rowSelected]: rowSelected
            })}
          >
            {staff.work_hours_sum !== undefined && (
              <span
                className={classNames(styles.total__num, {
                  [styles.total__num_red]: staff.work_hours_sum > 50,
                  [styles.total__num_green]: staff.work_hours_sum === 50
                })}
              >
                {`${staff.work_hours_sum}/50`}
              </span>
            )}
          </AregisterAllReportNewReportTableCell>
        )}
      </>
    </tr>
  );
};

export default observer(AregisterAllReportNewReportTableRow);
