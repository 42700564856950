import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import styles from "./staffOneSalaryPremium.module.scss";
import CircleCheck from "shared/assets/images/iconStatus/CircleCheck.svg";
import Coins from "shared/assets/images/iconStatus/Coins.svg";
import { useEffect } from "react";
import LoadedComponent from "widgets/LoadedComponent";
import { fileUrl } from "stores/utils/consts";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";
import { isEmpty } from "lodash";
import PremiumListTabs from "./PremiumListTabs";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";
import { ReactComponent as IconFinance } from "shared/assets/images/mainIcons/iconFinance.svg";

//hardcode
const icons = {
  "8cde3376531f47b26b07c209eb3ee911b4d14df0": { icon: Coins }, //статус Начислено (Подтверждено)
  "8c24bcb9faf90639fa0cbb7ce53e2c3e44d32a78": {
    icon: CircleCheck,
    color: "teal"
  } //статус Рассчитано
};

const StaffOneSalaryPremium = () => {
  const { staffOneSalaryStore } = useStores();
  const { id } = useParams();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    !(id in staffOneSalaryStore.premium) && staffOneSalaryStore.getPremium(id);
  }, [id]);

  const premiumList = getValues(staffOneSalaryStore.premium[id])
    .filter((premium) =>
      staffOneSalaryStore.selectedStatusForPremium[id] !== "all"
        ? staffOneSalaryStore.selectedStatusForPremium[id] === premium.status
        : premium
    )
    .filter((premium) =>
      staffOneSalaryStore.selectedCompanyForPremium[id] !== "all"
        ? staffOneSalaryStore.selectedCompanyForPremium[id] ===
          premium?.object?.company_id
        : premium
    )
    .sort(
      (a, b) =>
        new Date(b["last_update"]).valueOf() -
        new Date(a["last_update"]).valueOf()
    );

  return (
    <LoadedComponent
      isLoading={staffOneSalaryStore.isLoadingForPremium[id]}
      withoutText
    >
      <>
        {!isEmpty(staffOneSalaryStore.premium[id]) &&
        staffOneSalaryStore.currentTitlesForPremium.length &&
        !isEmpty(staffOneSalaryStore.colsForPremium) ? (
          <>
            <PremiumListTabs />
            {premiumList?.length ? (
              <Table>
                <thead>
                  <tr>
                    {staffOneSalaryStore.currentTitlesForPremium.map(
                      (title) => (
                        <th key={title} className={styles.title}>
                          {staffOneSalaryStore.colsForPremium[title]?.title}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody className={styles.tableBody}>
                  {premiumList.map((premium, index) => {
                    const premiumLink = `${fileUrl}/crm/salary/?pid=${premium.id}`;
                    const handleOpenPremium = () =>
                      setTimeout(() => {
                        window.open(premiumLink, "_blank");
                      }, 100);
                    return (
                      <tr
                        key={premium.id}
                        className={classNames("", {
                          [styles.hoverRow]: !isHovered,
                          [styles.greyRow]: index % 2
                        })}
                      >
                        {staffOneSalaryStore.currentTitlesForPremium.map(
                          (title) => {
                            switch (title) {
                              case "object":
                                return (
                                  <td
                                    key={title}
                                    className={styles.building}
                                    data-field="notHover"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                  >
                                    <a
                                      className={styles.buildingTitle}
                                      href={`${fileUrl}/crm/building/?id=${premium.object.id}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {premium[title]?.title}
                                    </a>
                                    <div
                                      className={
                                        styles[
                                          `company_${
                                            staffOneSalaryStore.companiesDict[
                                              premium[title]?.company_id
                                            ]?.color
                                          }`
                                        ]
                                      }
                                    >
                                      {premium[title]?.company_title}
                                    </div>
                                  </td>
                                );
                              case "uid":
                                return (
                                  <td
                                    key={title}
                                    onClick={handleOpenPremium}
                                    className={classNames(
                                      styles.uidContainer,
                                      {},
                                      [styles.tooltip]
                                    )}
                                    data-tooltip="Перейти к Премии"
                                  >
                                    <div className={styles.uidCell}>
                                      <div className={styles.uidIcon}>
                                        <IconFinance />
                                      </div>
                                      <p>{premium[title]}</p>
                                    </div>
                                  </td>
                                );
                              case "status":
                                return (
                                  <td
                                    key={title}
                                    onClick={handleOpenPremium}
                                    className={styles.tooltip}
                                    data-tooltip="Перейти к Премии"
                                  >
                                    <div className={styles.statusCell}>
                                      <img
                                        src={icons[premium[title]]["icon"]}
                                        className={`status_${
                                          icons[premium[title]]?.["color"]
                                        }`}
                                      />
                                      <p>
                                        {
                                          staffOneSalaryStore
                                            .premiumStatusSelects[
                                            premium[title]
                                          ]?.["title"]
                                        }
                                      </p>
                                    </div>
                                  </td>
                                );
                              case "sum_premium":
                                return (
                                  <td
                                    key={title}
                                    onClick={handleOpenPremium}
                                    className={styles.tooltip}
                                    data-tooltip="Перейти к Премии"
                                  >
                                    {getEntries(premium.staff).map(
                                      ([key, value]) => {
                                        return (
                                          <div
                                            key={key}
                                            className={styles.sumPremiumItem}
                                          >
                                            <div>
                                              {value["fixed"] ? "FIX" : "ЧЧ"}
                                            </div>
                                            <div>
                                              {numberWithSpaces(
                                                value["sum_premium"]
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </td>
                                );

                              case "time_create":
                              case "last_update":
                                return (
                                  <td
                                    key={title}
                                    onClick={handleOpenPremium}
                                    className={styles.tooltip}
                                    data-tooltip="Перейти к Премии"
                                  >
                                    {getFormattedDate(premium[title]).slice(
                                      0,
                                      10
                                    )}
                                  </td>
                                );
                              default:
                                return (
                                  <td
                                    key={title}
                                    onClick={handleOpenPremium}
                                    className={styles.tooltip}
                                    data-tooltip="Перейти к Премии"
                                  >
                                    {getFormattedDate(premium[title])}
                                  </td>
                                );
                            }
                          }
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className={styles.withoutData}>Список премий пуст</div>
            )}
          </>
        ) : (
          <div className={styles.withoutData}>Список премий пуст</div>
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneSalaryPremium);
