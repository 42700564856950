import { classNames } from "shared/utils/helpers/classNames";
import { Chips, ChipsVariation } from "shared/ui/Chips";
import styles from "./buttonsTabs.module.scss";

type ButtonsTabs = {
  tabs: { title: string; id: string }[];
  changeActiveTab: (id: string) => void;
  className?: Partial<{
    container: string;
    allTabs: string;
    tab: string;
  }>;
  title?: string;
};

type WithSelectedTab = ButtonsTabs & {
  selectedTab: string;
  customSelectedTab?: never;
};

type WithCustomSelectedTab = ButtonsTabs & {
  selectedTab?: never;
  customSelectedTab: (tabId: string) => string;
};

type ButtonsTabsProps = WithSelectedTab | WithCustomSelectedTab;

const ButtonsTabs = ({
  selectedTab,
  customSelectedTab,
  tabs,
  changeActiveTab,
  title,
  className
}: ButtonsTabsProps) => {
  return (
    <div
      className={classNames(styles.container, {}, [className?.container || ""])}
    >
      {title ? <p>{title}</p> : ""}
      <div className={classNames(styles.tabs, {}, [className?.allTabs || ""])}>
        {tabs.map((item) => {
          const handleClick = () => {
            changeActiveTab(item.id);
          };

          return item.id !== undefined ? (
            <Chips
              variation={ChipsVariation.FILTER}
              key={item["id"]}
              onClick={handleClick}
              id={`ButtonsTabs_${item["id"]}`}
              className={classNames(
                styles.button,
                {
                  [styles.buttonSelected]:
                    (customSelectedTab
                      ? customSelectedTab(item["id"])
                      : selectedTab) === item["id"]
                },
                [className?.tab || ""]
              )}
              title={item["title"]}
            />
          ) : null;
        })}
      </div>
    </div>
  );
};

export default ButtonsTabs;
