import styles from "./buildingOneStaffWindowTabs.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";

import { Chips, ChipsVariation } from "shared/ui/Chips";

type BuildingOneStaffWindowTabsProps = {
  staff_id: string;
};

const BuildingOneStaffWindowTabs = ({
  staff_id
}: BuildingOneStaffWindowTabsProps) => {
  const { buildingOneStaffStore } = useStores();

  const { id } = useParams();

  return (
    <div className={styles.tabs}>
      {buildingOneStaffStore.getEventTypeOptions(id).map((status) => {
        const handleSetStatus = () =>
          buildingOneStaffStore.setSelectedStatus(status.id, staff_id);
        return (
          <Chips
            variation={ChipsVariation.FILTER}
            key={status.id}
            className={`${styles.button} ${
              buildingOneStaffStore.selectedStatus === status.id
                ? styles.selectedGrayBtn
                : ""
            }`}
            onClick={handleSetStatus}
            id={`BuildingOneStaffWindowTabs_statusForm_${status.id}`}
            title={status.title}
            icon={status.custom?.icon}
            iconColor={
              buildingOneStaffStore.selectedStatus !== status.id
                ? status.custom.color_api
                : "default"
            }
          />
        );
      })}
    </div>
  );
};
export default observer(BuildingOneStaffWindowTabs);
