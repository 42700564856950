import styles from "./buildingListFilterButtons.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";

const BuildingListFilterButtons = () => {
  const { menuStore, buildingListStore } = useStores();

  return (
    <div
      className={`${styles.filterButtons} ${
        !menuStore.isMenuShown ? styles.filterButtons_withClosedMenu : ""
      }`}
    >
      {buildingListStore.selectsCols.type ? (
        <div>
          <ButtonsTabs
            tabs={
              [
                { id: "all", title: "Все типы" },
                ...Object.values(buildingListStore.selectsCols.type)
              ] as {
                id: string;
                title: string;
              }[]
            }
            selectedTab={buildingListStore.selectedType}
            changeActiveTab={(id) =>
              buildingListStore.selectedType !== id &&
              buildingListStore.setSelectedType(id)
            }
          />
        </div>
      ) : null}
      {buildingListStore.selectsCols.status ? (
        <div>
          <ButtonsTabs
            tabs={
              [
                { id: "all", title: "Все статусы" },
                ...Object.values(buildingListStore.selectsCols.status)
              ] as {
                id: string;
                title: string;
              }[]
            }
            selectedTab={buildingListStore.selectedStatus}
            changeActiveTab={(id) =>
              buildingListStore.selectedStatus !== id &&
              buildingListStore.setSelectedStatus(id)
            }
          />
        </div>
      ) : null}
    </div>
  );
};

export default observer(BuildingListFilterButtons);
