import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { Col } from "stores/StaffModule/types/Col";
import { StaffType } from "../types/StaffType";
import { Building } from "stores/StaffModule/types/Building";

import { format, getDay, parse, setDay } from "date-fns";
import { Errors } from "stores/utils/types/ErrorsType";
import { Selects } from "../types/Selects";
import { Param } from "stores/utils/types/Param";
import { ApiResponse } from "stores/utils/types/ApiResponse";

import { dateFormats } from "stores/utils/consts";
import { without } from "lodash";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

export type WorkshiftType = {
  status: string;
  building: string; // id объекта
  date_report: string; // дата смены форматированная
  id: string;
  comment: string | null;
  company: string;
  history: {
    [key: string]: { [key: string]: { [key: string]: string } };
  };

  prev?: { [key: string]: string };
  next?: { [key: string]: string };
};

export default class BuildingOneWorkshiftStore {
  error = false;
  errorMessage: { [key: string]: Errors["message"] } = {};

  access_close: { [key: string]: boolean } = {};
  closeWorkshiftMsg: { [key: string]: Errors } = {};
  closeWorkshiftStatusMsg: { [key: string]: Errors } = {};

  editingWarnings: { [field_name: string]: string[] } = {};

  isLoading = false;
  isLoadingForTable = false;
  edited: { [key: string]: string } = {};

  blocksHeight: { [key: string]: Partial<{ header: number; count: number }> } =
    {};

  selectedOne: WorkshiftType | Record<string, never> = {};
  openedAllWorkshift: { [key: string]: WorkshiftType | Record<string, never> } =
    {};

  // колонки для карточки смены
  workshiftCols: { [key: string]: Col } = {};
  // все нужные списки
  selects: Partial<Selects> = {};

  // колонки таблицы сотрудников в смене
  workshiftTableCols: Partial<Record<keyof StaffType, Col>> = {};
  // параметры таблицы сотрудников в смене
  workshiftTableParams: Partial<Record<keyof StaffType, Param>> = {};

  rootStore: RootStore;

  // список сотрудников в смене
  staffList: {
    [key: string]: { [key: string]: { [key: string]: StaffType } };
  } = {};
  updateStaffList: {
    [key: string]: { [key: string]: { [key: string]: StaffType } };
  } = {};
  massEditStaffRow: Partial<StaffType> = {};
  filters: { [key: string]: { [key: string]: number } } = {};
  selectedFilters: { [key: string]: string } = {};
  openedMode: { [building_id: string]: "show" | "edit" } = {};

  staffListOrder: {
    [workshift_id: string]: [name: string, order: "asc" | "desc"];
  } = {};
  searchValue: { [key: string]: string } = {};
  buildingTitle = "";

  foundedStaffList: {
    [workshift_id: string]: { [group: string]: { [key: string]: StaffType } };
  } = {};

  // колнки, которые нужно исключить при отрисовке таблицы
  exceptions = [
    "id",
    "workshift",
    "contract",
    "promile",
    "over_price",
    "company"
  ];

  // главный метод получения инфо по смене
  setSelectedWorkshiftForInfo = (id: string, module?: string) => {
    runInAction(() => {
      this.error = false;
      this.isLoading = true;
      this.selectedOne = {};
    });

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/building", "Объекты");
    }

    if (!this.rootStore.menuStore.allWindows[`/workshift/id=${id}`]) {
      delete this.openedAllWorkshift[id];
    }

    if (
      Object.keys(this.openedAllWorkshift).length &&
      this.openedAllWorkshift[id]
    ) {
      this.selectedOne = this.openedAllWorkshift[id];
      this.updateWindowWorkshift(id, module);
      runInAction(() => {
        this.isLoading = false;
      });
    } else {
      Promise.all([
        !Object.values(this.selects).length && this.getSelects(),
        this.getСurrentWorkshiftData(id, module),
        this.getStaffForWorkshift(id),
        !Object.values(this.workshiftTableParams).length &&
          this.getWorkshiftTableParams(),
        !Object.values(this.workshiftCols).length && this.getWorkshiftCols(),
        !Object.values(this.workshiftTableCols).length &&
          this.getWorkshiftTableCols()
      ]).then(() => {
        runInAction(() => {
          this.openedMode[id] = "show";
          this.staffListOrder[id] = ["fio", "asc"];
          this.searchValue[id] = "";

          this.isLoading = false;
        });
      });
    }
  };

  // получение текущей смены
  getСurrentWorkshiftData = async (id: string, module?: string) => {
    try {
      const data: ApiResponse<boolean> & { record: WorkshiftType } =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "workshift",
          method: "getWorkshift",
          params: {
            workshift_id: id
          }
        });

      runInAction(() => {
        if (data.code === 200 && data.result) {
          if (this.openedAllWorkshift[id]?.prev) {
            this.openedAllWorkshift[id] = {
              prev: this.openedAllWorkshift[id].prev,
              next: this.openedAllWorkshift[id].next,
              ...data.record
            };
          } else {
            this.openedAllWorkshift[id] = data.record;

            Promise.all([
              this.getPrevAndNextWorkshiftData(
                data.record.building,
                data.record.date_report,
                id
              ),
              this.getBuildingTitle(data.record.building)
            ]).then(() =>
              runInAction(
                () => (this.selectedOne = this.openedAllWorkshift[id])
              )
            );

            runInAction(() => {
              if (
                !this.rootStore.menuStore.allWindows[
                  `/building/id=${data.record.building}`
                ]
              ) {
                this.rootStore.buildingOneStore.setSelectedBuildingForInfo(
                  data.record.building
                );
                this.rootStore.menuStore.addTabWindow(
                  `/building/id=${data.record.building}`,
                  this.rootStore.buildingOneStore.selectedOne.title
                );
                this.rootStore.menuStore.updateTabWindow({
                  mainPath: `/building/id=${data.record.building}`,
                  path: `/building/id=${data.record.building}/timesheet`
                });
              }

              if (
                !this.rootStore.menuStore.allWindows[
                  `/workshift/id=${data.record.id}`
                ]
              ) {
                this.rootStore.menuStore.addTabWindow(
                  `/workshift/id=${data.record.id}`,
                  "Загрузка..."
                );
              }

              this.updateWindowWorkshift(data.record.id, module);

              this.rootStore.menuStore.updateTabWindow({
                mainPath: `/workshift/id=${data.record.id}`,
                title: `Смена ${getFormattedDate(data.record.date_report)}`
              });
            });
          }
        } else {
          this.openedAllWorkshift[id] = {};
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/workshift/id=${id}`,
            title: "Ничего не найдено"
          });
          this.error = true;
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // получение предыдущей и следующей смен
  getPrevAndNextWorkshiftData = async (
    id: string,
    date: string | Date,
    workshift_id: string
  ) => {
    try {
      let params: { [key: string]: string } = {};
      if (date) {
        date = parse(date as string, dateFormats.date.format, new Date());
        const prevDate = format(setDay(date, getDay(date) - 1), "yyyy-MM-dd");
        const nextDate = format(setDay(date, getDay(date) + 1), "yyyy-MM-dd");

        params = {
          col: "building",
          value: id,
          "filter[order][]": "date_report",
          "filter[where][date_report][value][start]": prevDate,
          "filter[where][date_report][value][end]": nextDate,
          "filter[where][date_report][comparison]": "BETWEEN",
          "filter[where][date_report][logic]": "AND"
        };
      }

      const data: ApiResponse<
        { [key: string]: Omit<WorkshiftType, "history"> } | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "workshift",
        currentClass: "workshift",
        method: "getByCol",
        params: { ...params }
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.openedAllWorkshift[workshift_id] = {
            prev:
              Object.values(data.result).find(
                (workshift) =>
                  parse(workshift.date_report, "yyyy-MM-dd", new Date()) < date
              ) || {},
            next:
              Object.values(data.result).find(
                (workshift) =>
                  parse(workshift.date_report, "yyyy-MM-dd", new Date()) > date
              ) || {},
            ...this.openedAllWorkshift[workshift_id]
          } as WorkshiftType;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // получение названия объекта
  getBuildingTitle = async (id: string) => {
    try {
      const data: ApiResponse<Building | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building",
          method: "getByColOne",
          params: {
            col: "id",
            value: id
          }
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.buildingTitle = data["result"]?.["title"];
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // получение колонок для смны
  getWorkshiftCols = async () => {
    try {
      const data: ApiResponse<
        | {
            [key: string]: Col;
          }
        | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "workshift",
        currentClass: "workshift",
        method: "getTableCols"
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.workshiftCols = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // получение колонок таблицы сотрудников в смене
  getWorkshiftTableCols = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Col } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "workshift",
          currentClass: "workshift_staff",
          method: "getTableCols"
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.workshiftTableCols = data["result"];
          this.workshiftTableCols["tn"] = { title: "ТН" };
          this.workshiftTableCols["name"] = { title: "ФИО" };
          this.getWorkshiftTableParams();
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // получение параметров таблицы сотрудников в смене
  getWorkshiftTableParams = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Param } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "workshift",
          currentClass: "workshift_staff",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.workshiftTableParams = data["result"];
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // получение списка сотрудников в смене
  getStaffForWorkshift = async (id: string) => {
    runInAction(() => {
      this.errorMessage = {};
    });
    try {
      const data: ApiResponse<boolean> & {
        records: { [key: string]: StaffType };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "workshift",
        method: "getWorkshiftStaff",
        params: { workshift_id: id }
      });

      runInAction(() => {
        if (data.code === 200 && Object.values(data["records"]).length) {
          this.staffList[id] = {};

          // Для сотрудников без группы явно указываем отсутствие группы
          const staffListWithGroups = Object.values(data["records"]).map(
            (staff) => {
              !Object.values(this.massEditStaffRow).length &&
                Object.keys(staff).forEach((key) => {
                  !("isMassEdit" in this.massEditStaffRow)
                    ? (this.massEditStaffRow.isMassEdit = true)
                    : "";
                  if (key === "uid") {
                    this.massEditStaffRow[key] = "mass_edit";
                  } else this.massEditStaffRow[key] = null;
                });
              return staff.group || staff.group === "Без группы"
                ? staff.group
                : "Без группы";
            }
          );

          const result: string[] = [];
          // Получаем список всех групп сотрудников
          staffListWithGroups.forEach((group) => {
            if (!result.includes(group)) {
              result.push(group);
            }
          });

          this.filters[id] = {};
          // Создаем список фильтров с количеством сотрудников внутри
          result.forEach((group) => {
            // Записываем в фильтр по id смены и названию группы количество  сотрудников
            this.filters[id][group] = staffListWithGroups.filter(
              (item) => item === group
            ).length;

            // В список сотрудников записываем сотрудников по id  смены и названию группы
            Object.values(data["records"])
              .filter((item) =>
                group === "Без группы" ? !item.group : item.group === group
              )
              .forEach((staff) => {
                if (!this.staffList[id][group]) {
                  this.staffList[id][group] = {
                    ["mass_edit"]: this.massEditStaffRow as StaffType
                  };
                }
                this.staffList[id][group][staff.uid] = staff;
              });

            // небольшой нюанс - поле uid в ответе это не id сотрудника в целом, это id сотрудника в смене
            // поэтому перезаписываем это поле на ws_staff_id
            this.staffList[id][group] &&
              Object.values(this.staffList[id][group]).forEach((staff) => {
                Object.entries(data.records).forEach(([key, value]) => {
                  if (staff.staff_info) {
                    staff["tn"] = staff.staff_info.tn;
                    staff["fio"] = staff.staff_info.fio;
                    if (staff.uid === value.uid) {
                      staff.ws_staff_id = key;
                    }
                  }
                });
              });
          });

          // получаем сохраненный фильтр из localStorage для конкретной смены
          // это точно нужно - одна смена это не всегда даже 1 день, может быть пол дня, таких смен может быть открыто сотни и сотни
          const savedSelectedGroupFilter = localStorage.getItem(
            `selectedGroupFilter_${id}`
          );
          // записываем сохраненный фильтр
          this.selectedFilters[id] =
            // если фильтр сохранен в localStorage
            savedSelectedGroupFilter &&
            // и существует в списке сотрудников (в уже созданных нами фильтрах)
            Object.keys(this.filters[id]).includes(savedSelectedGroupFilter)
              ? // записываем значение фильтра из localStorage
                savedSelectedGroupFilter
              : // иначе записываем самый первый фильтр в списке
                // а нужно записывать самый первый по алфавиту
                Object.entries(this.filters[id])[0][0];

          this.editStatus(
            id,
            Object.values(this.selects.status).find(
              (type) => type.custom.done === "on"
            ).id,
            this.selectedOne.comment,
            true
          );
        } else {
          this.errorMessage[id] = data["message"];
          this.isLoading = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  /*
    получение списков:
      - status (статус смены),
      - company (список компаний),
      - objectstatus (состояния в смене),
      - payment_type (список типов оплат),
      - contract (список всех договоров), 
      - customer (список всех заказчиков)
  
  */
  getSelects = async () => {
    try {
      const data: ApiResponse<boolean> & { records: Selects } =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "workshift",
          method: "getSelects"
        });

      runInAction(() => {
        if (data["result"]) {
          this.selects = data.records;
          const customer: Selects["customer"] = {};
          Object.entries(this.selects.customer).forEach(([key, value]) => {
            customer[key] = {
              id: key,
              title: value as string
            };
            this.selects.customer = customer;
          });

          const objectstatus: Selects["objectstatus"] = {};
          Object.values(this.selects.objectstatus).forEach((value) => {
            objectstatus[value.title] = {
              ...value,
              title: value.comment as string,
              title_api: value.title
            };
            this.selects.objectstatus = objectstatus;
          });
        } else this.selects = {};
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // обновление окна смены
  updateWindowWorkshift = (id: string, module?: string) => {
    this.rootStore.menuStore.updateWindow({
      mainPath: `/workshift/id=${id}`,
      path: `/workshift/id=${id}${module ? module : ""}`
    });
  };

  // редактирование сотрудника в смене
  editWorkshiftStaff = async (
    id: string,
    ws_staff_id: string,
    title: string,
    value: string | number,
    staff_id: string
  ) => {
    runInAction(() => {
      this.errorMessage = {};
      this.editingWarnings[title] = [];
    });

    const formData = {
      workshift_id: id,
      ws_staff_id,
      col: title,
      value: value ?? "NULL"
    };

    if (title === "group" && value === "Без группы") {
      formData[title] = "NULL";
    }

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "workshift",
        method: "editWorkshiftStaff",
        body: formData
      });

      runInAction(() => {
        if (data["result"]) {
          const building_id = this.rootStore.menuStore.tabId;
          this.setEdited(title);

          if (title === "group") {
            this.changeFiltersList(
              value as string,
              this.staffList[building_id][this.selectedFilters[building_id]][
                staff_id
              ][title],
              id,
              staff_id
            );
          } else
            this.staffList[id][this.selectedFilters[id]][staff_id][title] =
              value;
        } else {
          this.editingWarnings[title].push(ws_staff_id);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // массовое редактирование сотрудников в смене
  editWorkshiftStaffMass = async (
    id: string,
    staffList: { [key: string]: StaffType },
    title: string,
    value: string | number,
    isPaymentType?: boolean
  ) => {
    runInAction(() => {
      this.errorMessage = {};
      this.editingWarnings[title] = [];
    });

    const formData = {
      workshift_id: id,
      col: title,
      value: value ?? "NULL"
    };

    staffList &&
      Object.values(staffList).forEach((item, i) => {
        if (
          isPaymentType &&
          item.available_payment_types &&
          !Object.values(item.available_payment_types).includes(value as string)
        ) {
          !this.editingWarnings[title]
            ? (this.editingWarnings[title] = [])
            : "";
          this.editingWarnings[title].push(item.ws_staff_id);
          return;
        }
        item.ws_staff_id && !formData[item.ws_staff_id]
          ? (formData[`ws_staff_id[${i}]`] = item.ws_staff_id)
          : "";
      });

    try {
      if (title === "group") {
        Object.values(staffList).forEach((staff) => {
          if (!staff.isMassEdit) {
            this.changeFiltersList(value as string, staff.group, id, staff.uid);
          }
        });
      }

      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "workshift",
        method: "editWorkshiftStaffMass",
        body: formData
      });

      runInAction(() => {
        if (data["result"]) {
          this.setEdited(title);

          Object.entries(data["result"]).forEach(([ws_staff_id, result]) => {
            if (!result) {
              !this.editingWarnings[title]
                ? (this.editingWarnings[title] = [])
                : "";
              this.editingWarnings[title].push(ws_staff_id);
            }
          });
          this.getStaffForWorkshift(id);
        } else {
          this.errorMessage[id] = data["message"];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // редактирование статуса смены из карточки
  editStatus = async (
    workshift_id: string,
    status_id: string,
    comment: string,
    only_message?: boolean
  ) => {
    if (!only_message) {
      this.isLoading = true;
    } else {
      this.checkObjectStatus(workshift_id);
    }

    const formData = {
      workshift_id,
      status_id,
      comment: comment || ""
    };

    if (only_message) {
      formData["only_message"] = true;
    }

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "workshift",
        method: "editStatus",
        body: formData
      });

      if (!only_message) {
        if (data["result"]) {
          await this.getСurrentWorkshiftData(workshift_id);
        } else {
          runInAction(
            () => (this.errorMessage[workshift_id] = data["message"])
          );
        }
      } else {
        runInAction(() => {
          this.closeWorkshiftMsg[workshift_id] = data;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  // редактирование комментария смены из карточки
  editWorkshift = async (workshift_id: string, comment?: string) => {
    this.isLoading = true;

    const formData = {
      workshift_id,
      "data[comment]": comment || ""
    };
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "workshift",
        method: "editWorkshift",
        body: formData
      });

      if (data["result"]) {
        await this.getСurrentWorkshiftData(workshift_id);
        this.setSelectedWorkshiftForInfo(workshift_id);
      } else {
        runInAction(() => {
          this.error = true;
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  // удаление смены из карточки
  deleteWorkshift = async (workshift_id: string) => {
    this.isLoading = true;

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "workshift",
        method: "deleteWorkshift",
        body: { workshift_id: workshift_id }
      });

      if (data["result"]) {
        await this.getСurrentWorkshiftData(workshift_id);
      } else {
        this.errorMessage[workshift_id] = data["message"];
        this.isLoading = false;
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  // функция изменения выбранной группы сотрудников для отображения таблице
  setSelectedStaffGroupFilter = (value: string, id: string) => {
    // идикатор "загрузки" здесь нужен, чтобы обновить страницу без предупреждений в консоли
    // т.к. в моменте данные не успевают обновиться и в Select прилетает не тот массив,
    // из-за чего ItemsScrollBoard пытается обратить к несуществующему индексу переданного массива
    this.isLoadingForTable = true;
    this.selectedFilters[id] = value;
    localStorage.setItem(`selectedGroupFilter_${id}`, value);
    setTimeout(() => runInAction(() => (this.isLoadingForTable = false)), 20);
  };

  // При добавлении новой группы - добавляем её в фильтры вместе с счетчиком
  changeFiltersList = (
    newFilter: string,
    oldFilter: string,
    id: string,
    staff_id: string
  ) => {
    this.isLoadingForTable = true;

    this.staffList[id][oldFilter][staff_id]["group"] = newFilter;
    // для нового значения фильтра прибавяем +1, либо создаем новый фильтр со значением 1
    if (this.filters[id][newFilter || "Без группы"]) {
      this.filters[id][newFilter || "Без группы"] =
        this.filters[id][newFilter || "Без группы"] + 1;
    } else {
      this.filters[id][newFilter || "Без группы"] = 1;
      this.staffList[id][newFilter] = {
        mass_edit: this.massEditStaffRow as StaffType
      };
    }
    // для старого значения фильтра либо вычитаем 1, либо удаляем совсем
    this.filters[id][oldFilter] !== 1
      ? (this.filters[id][oldFilter] = this.filters[id][oldFilter] - 1)
      : delete this.filters[id][oldFilter];

    // далее проверяем наличие сотрудников по старому фильтру
    if (this.staffList[id][oldFilter]) {
      !this.staffList[id][newFilter]
        ? (this.staffList[id][newFilter] = {})
        : "";
      // в новый фильтр записываем сотрудника
      this.staffList[id][newFilter][staff_id] =
        this.staffList[id][oldFilter][staff_id];
      // и удаляем сотрудника из старого фильтра
      delete this.staffList[id][oldFilter][staff_id];
    }

    // и если для старого фильтра нет сотрудников
    if (!this.filters[id][oldFilter]) {
      // выбираем новый фильтр как выделенный
      this.setSelectedStaffGroupFilter(newFilter, id);
    } else {
      setTimeout(() => {
        runInAction(() => {
          this.isLoadingForTable = false;
        });
      }, 200);
    }
  };

  // функция изменения значения быстрого поиска
  setSearchValue = (id: string, value: string) => {
    this.isLoadingForTable = true;
    this.searchValue[id] = value;

    if (value.length) {
      const foundedStaffList: { [key: string]: { [key: string]: StaffType } } =
        {};
      const foundedGroups: string[] = [];

      Object.entries(this.staffList[id]).forEach(([group, staffList]) => {
        !foundedStaffList[group] ? (foundedStaffList[group] = {}) : "";

        Object.values(staffList).forEach((staff) => {
          if (
            staff.staff_info?.fio
              .toLowerCase()
              .includes(value?.toLowerCase()) ||
            `${staff.staff_info?.tn}`.includes(value?.toLowerCase()) ||
            !staff.staff_info
          ) {
            foundedStaffList[group][staff.uid] = staff;
            if (staff.uid !== "mass_edit" && !foundedGroups.includes(group)) {
              foundedGroups.push(group);
            }
          }
        });
      });

      this.foundedStaffList[id] = foundedStaffList;

      if (
        foundedGroups.length &&
        !foundedGroups.includes(this.selectedFilters[id])
      ) {
        this.setSelectedStaffGroupFilter(foundedGroups[0], id);
      } else {
        this.isLoadingForTable = false;
      }
    } else {
      this.foundedStaffList[id] = {};
      this.isLoadingForTable = false;
    }
  };

  setOpenedMode = (id: string, value: "edit" | "show") => {
    this.openedMode[id] = value;
  };

  setStaffListOrder = (name: string) => {
    this.staffListOrder[this.rootStore.menuStore.tabId] = [
      name,
      this.staffListOrder[this.rootStore.menuStore.tabId].includes(name) &&
      this.staffListOrder[this.rootStore.menuStore.tabId].includes("asc")
        ? "desc"
        : "asc"
    ];
  };

  setHeight = (id: string, name: "header" | "count", value: number) => {
    if (!this.blocksHeight[id]) {
      this.blocksHeight[id] = {};
    }

    this.blocksHeight[id][name] = value;
    this.blocksHeight[id].count = 40;
  };

  setEdited = (value: string) => {
    this.edited[this.rootStore.menuStore.tabId] = value;
  };

  checkObjectStatus = (id: string) => {
    const error: Errors = {
      result: false,
      message: {
        head: null,
        color: "default",
        body: {}
      }
    };

    const objectstatusError = {
      head: "Статус не позволяет закрыть смену",
      list: {
        type: "link",
        dir: "staff",
        body: {}
      }
    };

    const workHourError = {
      head: "Не указаны часы",
      list: {
        type: "link",
        dir: "staff",
        body: {}
      }
    };

    let hasObjectstatusError: number;
    let hasWorkHourError: number;

    if (this.staffList[id]) {
      Object.values(this.staffList[id]).forEach((staff) => {
        Object.values(staff).forEach((oneOfStaff) => {
          if (
            oneOfStaff.objectstatus &&
            this.selects.objectstatus[oneOfStaff.objectstatus]?.custom
              ?.access_close !== "on"
          ) {
            if (
              !Object.values(error.message.body).length ||
              hasObjectstatusError === undefined
            ) {
              hasObjectstatusError = Object.values(error.message.body).length;

              error.message.body[hasObjectstatusError] = objectstatusError;
            }
            error.message.body[hasObjectstatusError].list.body[
              oneOfStaff.uid
            ] = `ТН${oneOfStaff.staff_info.tn} ${oneOfStaff.staff_info.fio}`;
          }

          if (
            oneOfStaff.objectstatus &&
            this.selects.objectstatus[oneOfStaff.objectstatus]?.custom
              ?.check_hours === "on" &&
            !oneOfStaff.work_hours
          ) {
            if (
              !Object.values(error.message.body).length ||
              hasWorkHourError === undefined
            ) {
              hasWorkHourError = Object.values(error.message.body).length;

              error.message.body[hasWorkHourError] = workHourError;
            }

            error.message.body[hasWorkHourError].list.body[
              oneOfStaff.uid
            ] = `ТН${oneOfStaff.staff_info.tn} ${oneOfStaff.staff_info.fio}`;
          }
        });
      });
    }

    if (hasObjectstatusError || hasWorkHourError) {
      this.closeWorkshiftStatusMsg[id] = error;
    } else {
      this.closeWorkshiftStatusMsg[id] = {};
    }
  };

  deleteEditingWarning = (title: string, ws_staff_id: string) => {
    if (this.editingWarnings[title])
      this.editingWarnings[title] = without(
        this.editingWarnings[title],
        ws_staff_id
      );
  };

  sendData = (
    option: number | string,
    name: string,
    ws_staff_id: string,
    staff_id: string,
    isMassEdit?: boolean,
    isPaymentType?: boolean
  ) => {
    const id = this.rootStore.menuStore.tabId;
    if (isMassEdit)
      this.editWorkshiftStaffMass(
        id,
        this.searchValue[id]?.length
          ? this.foundedStaffList[id]?.[this.selectedFilters[id]]
          : this.staffList[id]?.[this.selectedFilters[id]],
        name,
        option,
        isPaymentType
      );
    else this.editWorkshiftStaff(id, ws_staff_id, name, option, staff_id);
  };

  getOpenedModeForField = (name: string, anyCondForEdit?: boolean) => {
    const id = this.rootStore.menuStore.tabId;

    // Режим отображения всей таблицы
    const isShow = this.openedMode[id] === "show";
    const isEdit = this.openedMode[id] === "edit";

    // Является ли статус смены - Открыта
    const isOpenedWorkshift =
      !this.selects.status?.[this.selectedOne.status]?.custom?.done;

    // Редактируемое ли поле
    const isEditable = Boolean(
      isOpenedWorkshift &&
        (this.workshiftTableParams[name]?.access_edit ||
          this.workshiftTableParams[name]?.editable === "on")
    );

    // Если режим отображения - просмотр
    // Либо если режим отображения - редактирование, но поле не редактируемое
    // Возвращаем "show"
    if (isShow || (isEdit && !isEditable)) {
      return "show";
    }
    // Если же режим отображения - редактирование
    // Поле редатируемое и выполенно (если передано) другое условие редактирования
    // Возвращаем "edit"
    else if (isEdit && isEditable && (anyCondForEdit ?? true)) {
      return "edit";
    }
  };

  // функция сортировки таблицы
  getTableSort = () => {
    const id = this.selectedOne.id;
    const values = Object.values(
      this.foundedStaffList?.[id]?.[this.selectedFilters?.[id]] || {}
    ).length
      ? Object.values(this.foundedStaffList[id][this.selectedFilters[id]])
      : Object.values(this.staffList[id][this.selectedFilters[id]]);
    const [name, order] = this.staffListOrder[id];

    return values.sort((rowA, rowB) => {
      if (rowA?.isMassEdit || rowB?.isMassEdit) return;

      const collator = new Intl.Collator();

      const newA = typeof rowA[name] === "string" ? rowA[name][0] : rowA[name];
      const newB = typeof rowB[name] === "string" ? rowB[name][0] : rowB[name];

      if (order === "asc") {
        if (typeof newA === "number" && typeof newB === "number") {
          return newA > newB ? 1 : -1;
        } else {
          return collator.compare(newA as string, newB as string);
        }
      } else {
        if (typeof newA === "number" && typeof newB === "number") {
          return newA > newB ? -1 : 1;
        } else {
          return collator.compare(newB as string, newA as string);
        }
      }
    });
  };

  isShowCol = (name: keyof StaffType) => {
    const isShowCol =
      this.workshiftTableParams[name]?.access_show ||
      this.workshiftTableParams[name]?.show_default === "on";
    const isAccessToShow = !this.exceptions.includes(name);

    return isShowCol && isAccessToShow;
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
