import styles from "./tableWithStickyFilter.module.scss";
import { useEffect, useRef, useState } from "react";
import { useStores } from "stores/index";
import { observer } from "mobx-react-lite";

import FilterInfoBlock from "features/TableWithStickyFilter/FilterInfoBlock";
import Filter from "features/TableWithStickyFilter/Filter";

import { FilterProps } from "features/TableWithStickyFilter/Filter";
import { FilterParams } from "features/TableWithStickyFilter/FilterInfoBlock";

const TableWithStickyFilter = ({
  filters,
  selectedFilter,
  downloadedFilter,
  setSelectedFilter,
  setDownloadedFilter,
  setFilters,
  setQueryParams,

  isLoading,
  isLoadingForFilters,
  searchValue,
  setSearchValue,

  getData,

  cols,
  params,
  allCols,
  currentCols,
  getDataWithFilter,
  selectMulti,
  isSearchable,
  setFiltersChanged,
  listOrdered,
  queryParams,

  isExport,
  createDataFile,
  dataFileName,
  itemName,
  children,
  moreButton,
  foundCounter,
  withoutFilterInfoBar,
  filterParams,
  theadTop,
  searchInputTooltip,
  withoutAdvancedSearch,
  withoutSavedFilter,
  withoutColsSetting,
  withoutQueryParams,
  defaultFields,
  dateRangeFields,
  customFilterMain,
  selectsWithLoading,
  pageSelectsInfo,
  customConditionDisplayForInfoBlock,
  fixedWidthSearchInput
}: FilterProps & {
  children: JSX.Element;
  itemName?: string;
  foundCounter?: number;
  filterParams?: FilterParams;
  withoutFilterInfoBar?: boolean;
  moreButton?: JSX.Element;
  theadTop?: number;
  customConditionDisplayForInfoBlock?: boolean;
  fixedWidthSearchInput?: boolean;
}) => {
  const { menuStore, filterStore } = useStores();
  const [isScaleChanged, setIsScaleChanged] = useState(false);

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    setIsScaleChanged(true);
    setTimeout(() => {
      setIsScaleChanged(false);
    }, 500);
    menuStore.setMarginLeft(0);
  }, [window.innerWidth]);

  return (
    <div
      className={`${styles.container} ${
        menuStore.scroll?.scrollTop > theadTop ? styles.container_shadow : ""
      }`}
    >
      <div className={styles.filter}>
        <Filter
          isLoading={isLoading}
          filters={filters}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          getData={getData}
          downloadedFilter={downloadedFilter}
          setDownloadedFilter={setDownloadedFilter}
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          setQueryParams={setQueryParams}
          getDataWithFilter={getDataWithFilter}
          isSearchable={isSearchable}
          isExport={isExport}
          createDataFile={createDataFile}
          dataFileName={dataFileName}
          listOrdered={listOrdered}
          queryParams={queryParams}
          setFilters={setFilters}
          cols={cols}
          params={params}
          allCols={allCols}
          currentCols={currentCols}
          setFiltersChanged={setFiltersChanged}
          selectMulti={selectMulti}
          isLoadingForFilters={isLoadingForFilters}
          isScaleChanged={isScaleChanged}
          searchInputTooltip={searchInputTooltip}
          withoutAdvancedSearch={withoutAdvancedSearch}
          withoutSavedFilter={withoutSavedFilter}
          withoutColsSetting={withoutColsSetting}
          withoutQueryParams={withoutQueryParams}
          defaultFields={defaultFields}
          dateRangeFields={dateRangeFields}
          customFilterMain={customFilterMain}
          selectsWithLoading={selectsWithLoading}
          pageSelectsInfo={pageSelectsInfo}
          fixedWidthSearchInput={fixedWidthSearchInput}
        />
        {moreButton ? moreButton : ""}
      </div>

      <div
        ref={ref}
        className={`${styles.infoBlock} ${
          ref.current?.offsetTop - menuStore.scroll.scrollTop <
            63 +
              (filterStore.savedFiltersHeight !== 0 &&
              filterStore.savedFiltersHeight <= 40
                ? filterStore.savedFiltersHeight + 12
                : filterStore.savedFiltersHeight || 0) &&
          !Math.sign(ref.current?.offsetTop - menuStore.scroll.scrollTop)
            ? styles.infoBlock_fixed
            : ""
        } ${filterStore.isOpenedSavedFilters ? styles.firefox : ""} ${
          filterStore.isOpenAdvancedSearch ? styles.firefox_withMargin : ""
        } ${
          menuStore.isMenuShown
            ? styles.infoBlock_withOpenedMenu
            : styles.infoBlock_withClosedMenu
        }`}
        style={{
          top:
            // высоты блока сохранённых фильтров, если высота меньше или равна 40, но не равна 0
            // нужно прибавить 12px отступа, т.к. при первом рендеринге страницы с открытыми сохр.фильтрами
            // в высоте блока сохр.фильтров не учитывает отступы
            (filterStore.savedFiltersHeight !== 0 &&
            filterStore.savedFiltersHeight <= 40
              ? filterStore.savedFiltersHeight + 12
              : filterStore.savedFiltersHeight || 0) + 64
        }}
      >
        {!customConditionDisplayForInfoBlock ? (
          <FilterInfoBlock
            filters={filters}
            downloadedFilter={downloadedFilter}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            params={params}
            getDataWithFilter={getDataWithFilter}
            foundCounter={foundCounter}
            filterParams={filterParams}
            item={itemName}
            isScaleChanged={isScaleChanged}
            withoutFilterInfoBar={withoutFilterInfoBar}
            getData={getData}
            dateRangeFields={dateRangeFields}
          />
        ) : (
          ""
        )}
      </div>

      {/* Здесь будет таблица */}
      {children}
    </div>
  );
};

export default observer(TableWithStickyFilter);
