import styles from "./itemsScrollBoard.module.scss";
import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { positionValues, Scrollbars } from "react-custom-scrollbars-2";
import SearchInput from "shared/ui/Inputs/SearchInput/index";

import { ReactComponent as IconSort } from "shared/assets/images/mainIcons/iconAZ.svg";
import LoadedComponent from "widgets/LoadedComponent";
import { IScrollBars } from "stores/utils/types/ScrollBars";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import StatusIcon from "shared/ui/StatusIcon";

const sortList = (list: OptionWithTitle[]) => {
  list.sort((a, b) => {
    typeof a.title === "number" ? (a.title = `${a.title}`) : "";
    typeof b.title === "number" ? (b.title = `${b.title}`) : "";
    const nameA = a.title ? a.title.toLowerCase() : "",
      nameB = b.title ? b.title.toLowerCase() : "";
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return list;
};

type ItemsScrollBoardProps = {
  options:
    | {
        [key: string]: OptionWithTitle;
      }
    | OptionWithTitle[];
  values?: string | string[] | number;
  valueName?: string;
  searchPlaceholder?: string;
  notSearchable?: boolean;
  withEmptyValue?: boolean;
  addItem?: (option: OptionWithTitle) => void;
  isItemBtnMode?: boolean;
  isSearchWithPagination?: boolean;
  page?: number;
  prevPage?: number;
  maxPage?: number;
  setPage?: (value: number) => void;
  getList?: () => void;
  setSearchValue?: (value: string) => void;
  searchValue?: string;
  isLoading?: boolean;
  notSortable?: boolean;
};

const ItemsScrollBoard = ({
  options,
  values,
  valueName,
  notSearchable,
  withEmptyValue,
  searchPlaceholder,
  addItem,
  isItemBtnMode,
  isSearchWithPagination,
  page,
  prevPage,
  maxPage,
  setPage,
  getList,
  setSearchValue,
  searchValue,
  isLoading,
  notSortable
}: ItemsScrollBoardProps) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [foundedItems, setFoundedItems] = useState<OptionWithTitle[]>([]);
  const [otherItems, setOtherItems] = useState<OptionWithTitle[]>([]);
  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const scrollbar = useRef<IScrollBars>();
  const inputRef = useRef<HTMLInputElement>();

  const valueField = valueName === undefined ? "newname" : valueName;

  const handleScroll = (e: positionValues) => {
    if (e.top > 0.87) {
      setIsScrollBottom(!isScrollBottom);
    } else {
      setIsScrollBottom(false);
    }
  };

  useEffect(() => {
    if (
      isSearchWithPagination &&
      isScrollBottom &&
      maxPage >= page &&
      page === prevPage
    ) {
      setPage(page + 1);
    }
  }, [isScrollBottom]);

  useEffect(() => {
    if (!isSearchWithPagination && options) {
      let modifiedOptions: {
        [key: string]: OptionWithTitle;
      } = {};

      if (!Array.isArray(options)) {
        if ((values || values === 0) && valueField && !Array.isArray(values)) {
          Object.values(options).map((option) => {
            if (
              !`${values}`.includes(option[valueField] as string) &&
              values !== option[valueField]
            ) {
              modifiedOptions[option[valueField] as string] = option;
            }
          });
        } else if (Array.isArray(values) && values.length) {
          const keysArray = Object.keys(options).filter(
            (key) => !values.includes(key)
          );
          keysArray.forEach((key) => (modifiedOptions[key] = options[key]));
        } else {
          modifiedOptions = options;
        }
      } else {
        modifiedOptions = null;
      }

      const newOptions: OptionWithTitle[] = modifiedOptions
        ? Object.values(modifiedOptions)
        : (options as OptionWithTitle[]);

      if (searchInputValue) {
        const founded: OptionWithTitle[] = [];
        const other: OptionWithTitle[] = [];

        newOptions.forEach((col) => {
          if (
            col["title"].toLowerCase().includes(searchInputValue.toLowerCase())
          ) {
            founded.push(col);
          } else {
            other.push(col);
          }
        });

        setFoundedItems(notSortable ? founded : sortList(founded));
        setOtherItems(notSortable ? other : sortList(other));
      } else {
        setOtherItems(
          withEmptyValue
            ? notSortable
              ? [{ [valueField]: null, title: "-" }, ...newOptions]
              : [{ [valueField]: null, title: "-" }, ...sortList(newOptions)]
            : notSortable
            ? newOptions
            : sortList(newOptions)
        );
        setFoundedItems([]);
      }
    }
  }, [values, options, searchInputValue]);

  const sortItems = () => {
    const newArray = [...otherItems];
    newArray.reverse();
    setOtherItems(newArray);
  };

  const deleteItemFromFoundedItems = (value: OptionWithTitle) => {
    if (addItem) {
      addItem(value);
    }

    for (let i = 0; i < foundedItems.length; ++i) {
      if (foundedItems[i][valueField] === value[valueField]) {
        const newArray = [...foundedItems];
        newArray.splice(i, 1);
        setFoundedItems(newArray);
      }
    }
  };

  const handleFindData = () => {
    setSearchValue(searchInputValue);
    getList();
  };

  return (
    <div
      className={`${styles.scrollboard} ${
        !notSearchable ? styles.scrollboard_withoutPadding : ""
      }`}
      data-list="true"
    >
      {!notSearchable ? (
        <div
          className={`${styles.search} ${
            !isSearchWithPagination ? styles.search_withSort : ""
          }`}
          data-list="true"
        >
          <SearchInput
            inputRef={inputRef}
            onChange={(e) => {
              setSearchInputValue(e.target.value.toLowerCase());
            }}
            value={searchInputValue}
            handleFindData={isSearchWithPagination && handleFindData}
            clearSearch={() => {
              setSearchInputValue("");
              if (searchValue && isSearchWithPagination) {
                setSearchValue("");
                getList();
              }
            }}
            placeholder={searchPlaceholder}
            fromList
          />
          {!isSearchWithPagination && !notSortable ? (
            <div className={styles.iconSort} data-list="true">
              <IconSort
                onClick={() => sortItems()}
                id="itemsscrollboard_sort"
                data-list="true"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : null}

      <Scrollbars
        ref={scrollbar}
        style={{ width: "100%" }}
        autoHeight
        autoHeightMax={"100%"}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        onScrollFrame={(e) => handleScroll(e)}
        data-list="true"
      >
        {!isSearchWithPagination ? (
          <div className={!isItemBtnMode ? styles.board : ""} data-list="true">
            <div
              className={
                isItemBtnMode
                  ? `${styles.itemList} ${
                      foundedItems.length ? styles.separatingBorder : ""
                    }`
                  : ""
              }
              data-list="true"
            >
              {foundedItems.map((item, i) => {
                const nextI = i + 1 !== foundedItems.length ? i + 1 : false;
                const fited = nextI
                  ? foundedItems[nextI]["title"].charAt(0) ===
                    foundedItems[i]["title"].charAt(0)
                  : "";

                return (
                  <div
                    key={item[valueField] as string}
                    onClick={() => deleteItemFromFoundedItems(item)}
                    id={`itemsscrollboard_delete_${item[valueField]}`}
                    className={
                      !isItemBtnMode
                        ? `${styles.board__item} ${
                            !fited ? styles.board__item_grayBorder : ""
                          } ${!nextI ? styles.board__item_blueBorder : ""}`
                        : styles.item
                    }
                    data-list="true"
                  >
                    <p
                      className={!isItemBtnMode ? styles.board__item__p : ""}
                      data-list="true"
                    >
                      {item["title"]}
                    </p>
                    {isItemBtnMode ? (
                      <StatusIcon
                        icon="iconaddwithoutcircle"
                        color="bw-gray5"
                        data-list="true"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className={isItemBtnMode ? styles.itemList : ""}
              data-list="true"
            >
              {otherItems
                ? otherItems.map((item, i) => {
                    const nextI = i + 1 !== otherItems.length ? i + 1 : "";
                    const fited = nextI
                      ? otherItems[nextI]["title"].charAt(0) ===
                        otherItems[i]["title"].charAt(0)
                      : "";
                    return (
                      <div
                        key={item[valueField] as string}
                        onClick={() => {
                          if (addItem) {
                            addItem(item);
                          }
                        }}
                        id={`itemsscrollboard_add_${item[valueField]}`}
                        className={
                          !isItemBtnMode
                            ? `${styles.board__item} ${
                                !notSearchable
                                  ? styles.board__item_withoutPadding
                                  : ""
                              } ${
                                !fited && !notSearchable
                                  ? styles.board__item_grayBorder
                                  : ""
                              }`
                            : styles.item
                        }
                        data-list="true"
                      >
                        <p data-list="true">{item["title"]}</p>
                        {isItemBtnMode ? (
                          <StatusIcon
                            icon="iconaddwithoutcircle"
                            color="bw-gray5"
                            data-list="true"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        ) : (
          <div className={!isItemBtnMode ? styles.board : ""} data-list="true">
            <div
              className={isItemBtnMode ? styles.itemList : ""}
              data-list="true"
            >
              {(options as OptionWithTitle[])
                ?.filter(
                  (item) =>
                    !(values as string[])?.includes(item[valueField] as string)
                )
                .map((item) => {
                  return (
                    <div
                      key={item[valueField] as string}
                      onClick={() => {
                        if (addItem) {
                          addItem(item);
                        }
                      }}
                      id={`itemsscrollboard_add_${item[valueField]}`}
                      className={
                        !isItemBtnMode
                          ? `${styles.board__item} ${styles.board__item_withoutPadding} ${styles.board__item_grayBorder}`
                          : styles.item
                      }
                      data-list="true"
                    >
                      <p data-list="true">{item["title"]}</p>
                      {isItemBtnMode ? (
                        <StatusIcon
                          icon="iconaddwithoutcircle"
                          color="bw-gray5"
                          data-list="true"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              {page !== 1 ? (
                <>
                  {isLoading ? (
                    <p className={styles.loadingText} data-list="true">
                      Пожалуйста, подождите
                    </p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <LoadedComponent isLoading={isLoading} withoutText />
              )}
            </div>
          </div>
        )}
      </Scrollbars>
    </div>
  );
};

export default observer(ItemsScrollBoard);
